var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.store.doors)?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Doors "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.store.doors,"headers":_vm.headers,"show-expand":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.batchNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/batches/" + (item.batchId))}},[_vm._v(" "+_vm._s(item.batchNumber)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('DoorStatus',{attrs:{"status":item.status,"show-text":false}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","color":"primary","to":("/doors/" + (item.id))}},[_vm._v(" Door Builder "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-wrench")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"success darken-2","to":("/orders/" + (item.orderId) + "/doors/" + (item.id) + "/copy")}},[_vm._v(" Copy "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"ml-2  d-none d-xl-flex",attrs:{"small":"","color":"primary","to":("/orders/" + (item.orderId) + "/doors/" + (item.id) + "/bom")}},[_vm._v(" Bill of Materials ")]),_c('v-btn',{staticClass:"ml-2  d-none d-xl-flex",attrs:{"small":"","color":"primary","to":("/orders/" + (item.orderId) + "/doors/" + (item.id))}},[_vm._v(" View Door Set ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Intended Fire Rating")]),_c('th',[_vm._v("Surveyor")]),_c('th',[_vm._v("Door Style")]),_c('th',[_vm._v("Fitter")]),_c('th',[_vm._v("Revision")]),_c('th',{style:(("width: " + _vm.buttonWidth))}),_c('th',{style:(("width: " + _vm.buttonWidth))}),_c('th',{style:(("width: " + _vm.buttonWidth))})])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"dont-wrap"},[_vm._v(_vm._s(item.intendedFireRating))]),_c('td',{staticClass:"dont-wrap"},[(item.surveyor)?_c('div',[_vm._v(" "+_vm._s(item.surveyor.name)+" ")]):_c('div',[_vm._v("-")])]),_c('td',{staticClass:"dont-wrap"},[_vm._v(_vm._s(item.doorStyle))]),_c('td',{staticClass:"dont-wrap"},[_vm._v(" "+_vm._s(item.fitter ? item.fitter.name : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.revisionNo)+" ")]),_c('td',[_c('v-btn',{staticClass:"mb-2 d-xl-none",attrs:{"block":"","small":"","color":"primary","to":("/orders/" + (item.orderId) + "/doors/" + (item.id) + "/bom")}},[_vm._v(" Bill Of Materials ")])],1),_c('td',[_c('v-btn',{staticClass:"mb-2 d-xl-none",attrs:{"block":"","small":"","color":"primary","to":("/orders/" + (item.orderId) + "/doors/" + (item.id))}},[_vm._v(" View Door Set ")])],1),(_vm.store.canEdit(_vm.$auth.name))?_c('td',[_c('v-btn',{staticClass:"mb-2",attrs:{"block":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeDoor(item)}}},[_vm._v(" Delete Door ")])],1):_vm._e()])])])])]}}],null,false,2858553802)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }