<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <EditForm ref="form" @on:save="save" @on:delete="remove" />
    <InstallerView ref="installerView" :installers="installers" />
    <v-card class="mb-4" :loading="loading">
      <v-card-title>
        BM Trada Companies
        <v-btn color="primary" class="ml-4" small @click="add">
          Add Company
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!bmTradaCompanies.length">
        No Companies
      </v-card-text>
    </v-card>
    <v-container class="pa-0" fluid>
      <CardView
        v-for="company in bmTradaCompanies"
        :key="company.id"
        ref="cards"
        :company="company"
        @on:edit="open(company)"
        @on:view-installers="viewInstallers(company)"
        @on:delete="remove"
      />
    </v-container>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import EditForm from "@/components/forms/bm-trada-company/edit-form";
import CardView from "@/components/forms/bm-trada-company/card-view";
import InstallerView from "@/components/forms/bm-trada-company/installer-view";

export default {
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "BM Trada Companies"
        }
      ]
    };
  },
  components: {
    CardView,
    EditForm,
    InstallerView
  },
  computed: {
    bmTradaCompanies: get("bmTradaCompanies/companies"),
    installers: get("bmTradaCompanies/installers")
  },
  async created() {
    try {
      await this.loadBmTradaCompanies();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadBmTradaCompanies: call("bmTradaCompanies/loadBmTradaCompanies"),
    loadBmTradaInstallers: call("bmTradaCompanies/loadBmTradaInstallers"),
    saveBmTradaCompany: call("bmTradaCompanies/saveBmTradaCompany"),
    removeBmTradaCompany: call("bmTradaCompanies/removeBmTradaCompany"),
    add() {
      this.$refs.form.open();
    },
    open(company) {
      this.$refs.form.open(company);
    },
    async remove(data) {
      await this.removeBmTradaCompany(data);
      this.$refs.form.close();
    },
    async save(data) {
      this.$refs.form.setLoading(true);
      try {
        await this.saveBmTradaCompany(data);
      } finally {
        this.$refs.form.setLoading(false);
        this.$refs.form.close();
      }
    },
    async viewInstallers(company) {
      const card = this.$refs.cards.find(c => c.company.id === company.id);
      card.setLoading(true);
      try {
        await this.loadBmTradaInstallers(company);
        this.$refs.installerView.open();
      } finally {
        card.setLoading(false);
      }
    }
  }
};
</script>
