<template>
  <div class="door-sections">
    <div class="door-list" v-if="doors">
      <v-card>
        <v-card-subtitle class="py-2">Door List</v-card-subtitle>
        <div :style="listStyle">
          <DoorList :doors="doors" />
        </div>
      </v-card>
    </div>
    <div class="center-section">
      <v-card :loading="saving" class="other-item">
        <v-card-subtitle class="pt-1 pb-0" v-if="order">
          {{ order.reference }}
        </v-card-subtitle>
        <v-card-title class="pt-0 pb-1">
          <span>{{ doorName }}</span>
          <div class="ml-4">
            <v-chip
              v-if="order && order.isAuthorized"
              small
              label
              color="indigo"
              dark
              outlined
            >
              Order Authorized
              <v-icon small right>mdi-lock</v-icon>
            </v-chip>
            <v-chip
              v-if="allowEdit"
              class="ml-1"
              color="orange darken-4"
              label
              outlined
              small
            >
              Allowed to Edit Door
              <v-icon right small>mdi-account-edit-outline</v-icon>
            </v-chip>
            <v-chip v-else color="error" class="ml-1" label small>
              Cannot edit this Door
              <v-icon right small>mdi-alert-circle-outline</v-icon>
            </v-chip>
            <v-chip
              v-if="!bomAutoUpdate"
              class="ml-1"
              color="orange darken-4"
              label
              text-color="white"
              small
            >
              Auto Update BOM is Disabled
              <v-icon right small>mdi-alert</v-icon>
            </v-chip>

            <v-chip
              v-if="door.status === 'SurveySubmitted'"
              class="ml-1"
              color="green"
              dark
              label
              small
            >
              Submitted
              <v-icon right small>mdi-check</v-icon>
            </v-chip>
          </div>
          <v-spacer></v-spacer>
          <span>£{{ price | number("0,0.00") }}</span>
        </v-card-title>

        <v-container v-if="order">
          <v-row justify="start">
            <v-col class="buttons-spacing">
              <v-btn color="primary" :to="`/order/${order.id}`" small>
                Go to Order
              </v-btn>
              <v-btn
                color="primary"
                :to="`/delivery-dockets/${door.deliveryDocketId}`"
                small
                v-if="door.deliveryDocketId"
              >
                View on Docket
              </v-btn>
              <v-btn
                color="primary"
                :to="`/batches/${door.batchId}`"
                small
                v-if="door.batchId"
              >
                View on Batch
              </v-btn>
              <v-btn
                color="primary"
                small
                :to="`/orders/${order.id}/doors/${door.id}/bom`"
              >
                Bill of Materials
              </v-btn>
              <v-btn
                small
                :to="`/orders/${order.id}/doors/${door.id}`"
                color="primary"
              >
                View Door Set
              </v-btn>
            </v-col>
            <v-col cols="4" align="right" class="mt-1">
              <v-btn
                small
                color="primary"
                v-if="!door.locked"
                @click="lockDoor(door.id)"
              >
                <v-icon small left>mdi-lock</v-icon>Lock</v-btn
              >
              <v-btn
                small
                color="primary"
                v-if="door.locked"
                :disabled="door.batchId != null"
                @click="unlockDoor(door.id)"
              >
                <v-icon small left>mdi-lock-open-variant</v-icon>Unlock</v-btn
              >
              <BomSwitch :door="door" class="ml-1"></BomSwitch>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div class="main-door-builder mt-1">
        <v-card tile>
          <v-tabs center-active grow show-arrows>
            <v-tab
              v-for="page in pages"
              :key="page.name"
              :to="{ name: page.name, params: { id: $route.params.id } }"
              exact
            >
              {{ page.text }}
            </v-tab>
          </v-tabs>
        </v-card>
        <div :style="builderStyle">
          <v-card :disabled="cardDisabled" :loading="saving" tile>
            <v-card-text>
              <v-skeleton-loader v-if="loading" type="card">
              </v-skeleton-loader>
              <div v-else>
                <router-view
                  class="pa-0 ma-0"
                  :key="$route.fullPath"
                  @on:door-prop-updated="doorPropUpdated"
                ></router-view>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <v-card tile>
          <v-card-actions>
            <v-btn color="primary" v-show="showNext" small @click="next">
              Next
            </v-btn>
            <v-btn v-show="showPrevious" small @click="previous">
              Previous
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <div class="survey-and-charges">
      <v-card>
        <v-card-text :style="rightStyle">
          <div class="survey-sheet">
            <SurveySheet :door="door" />
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="charges">
            <ButtonSwitch
              label="Auto Update Prices"
              v-model="door.autoUpdatePrices"
              @input="switchAutoUpdate"
            />
            <DoorCharges
              :isOrder="true"
              :line="door"
              @on:saveDoorCharge="saveDoorCharge"
              @on:removeDoorCharge="removeDoorCharge"
              :saving="saving"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import { vueWindowSizeMixin } from "vue-window-size";
import { getDoorName } from "@gradys/vue-base/components/door/door-naming";
import DoorCharges from "@/components/quotes/door-charges";
import DoorList from "@gradys/vue-base/components/door/door-list";
import SurveySheet from "@gradys/vue-base/components/files/survey-sheet";
import ButtonSwitch from "@gradys/vue-base/components/controls/button-switch";
import BomSwitch from "@/components/review/bom/bom-toggle-auto-update.vue";

export default {
  mixins: [vueWindowSizeMixin],
  components: {
    DoorList,
    SurveySheet,
    DoorCharges,
    ButtonSwitch,
    BomSwitch
  },
  data() {
    return {
      loading: true,
      debounce: null,
      saving: false,
      savingDoor: false,
      pages: [
        {
          text: "Attach Files",
          name: "attach-files"
        },
        {
          text: "Opening Dimms",
          name: "opening-dimm"
        },
        {
          text: "Frame Design",
          name: "frame-design"
        },
        {
          text: "Door Design",
          name: "door-design"
        },
        {
          text: "Frame Details",
          name: "frame-details"
        },
        {
          text: "Packer/Architrave",
          name: "packer"
        },
        {
          text: "Review",
          name: "review"
        },
        {
          text: "Production",
          name: "production"
        },
        {
          text: "Fitter",
          name: "fitter"
        }
      ]
    };
  },
  created() {
    return this.load();
  },
  watch: {
    async $route(route) {
      if (route.params.id != this.door.id) {
        await this.loadDoor(route.params.id);
      }
    }
  },
  computed: {
    bomAutoUpdate: get("doors/bomAutoUpdate"),
    order: get("orders/order"),
    door: get("doors/door"),
    doors: get("orders/doors"),
    doorName() {
      return getDoorName(this.door);
    },
    showNext() {
      return this.$route.name !== "fitter";
    },
    showPrevious() {
      return this.$route.name !== "attach-files";
    },
    allowEdit() {
      if (!this.order) return false;
      if (this.order.currentEditor !== this.$auth.name) return false;

      return this.door.locked === false;
    },
    cardDisabled() {
      if (!this.order) return true;

      const ignoreRoutes = ["attach-files", "production", "fitter"];

      if (ignoreRoutes.includes(this.$route.name)) return false;

      return !this.allowEdit;
    },
    price() {
      if (!this.door || !this.door.charges) return 0;

      return this.door.charges.map(d => d.price).reduce((a, b) => a + b, 0);
    },
    listStyle() {
      const height = this.windowHeight - 165;
      return {
        "overflow-y": "auto",
        height: `${height}px`
      };
    },
    builderStyle() {
      const height = this.windowHeight - 365;
      return {
        "overflow-y": "auto",
        height: `${height}px`
      };
    },
    rightStyle() {
      const height = this.windowHeight - 128;
      return {
        "overflow-y": "auto",
        height: `${height}px`
      };
    }
  },
  methods: {
    updateDoor: call("doors/updateDoor"),
    updateDoorAsync: call("doors/updateDoorAsync"),
    fetchDoor: call("doors/fetchDoor"),
    fetchOrder: call("orders/fetchOrder"),
    fetchDoors: call("orders/fetchDoors"),
    unlockDoor: call("doors/unlockDoor"),
    lockDoor: call("doors/lockDoor"),
    loadCharges: call("doors/fetchDoorCharges"),
    saveCharge: call("doors/saveDoorCharge"),
    removeCharge: call("doors/removeDoorCharge"),
    async doorPropUpdated({ prop, value }) {
      try {
        this.saving = true;
        await this.updateDoorAsync({
          prop,
          value
        });
        this.debounceCharges();
      } finally {
        this.saving = false;
      }
    },
    debounceCharges() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.loadCharges(this.door.id);
      }, 3000);
    },
    async load() {
      this.loading = true;

      try {
        await this.loadDoor(this.$route.params.id);
        await this.fetchOrder(this.door.orderId);
        await this.fetchDoors(this.door.orderId);
      } finally {
        this.loading = false;
      }
    },
    async loadDoor(doorId) {
      try {
        this.saving = true;
        await this.fetchDoor(doorId);
        await this.loadCharges(doorId);
      } finally {
        this.saving = false;
      }
    },
    unlock() {
      return this.unlockDoor(this.door.id);
    },
    next() {
      const currentPageIndex = this.pages.findIndex(
        p => p.name === this.$route.name
      );
      const nextPage = this.pages[currentPageIndex + 1];

      this.$router.push({
        name: nextPage.name,
        params: {
          id: this.$route.params.id
        }
      });
    },
    previous() {
      const currentPageIndex = this.pages.findIndex(
        p => p.name === this.$route.name
      );
      const nextPage = this.pages[currentPageIndex - 1];

      this.$router.push({
        name: nextPage.name,
        params: {
          id: this.$route.params.id
        }
      });
    },
    async saveDoorCharge(charge) {
      this.saving = true;
      await this.saveCharge(charge);
      this.saving = false;
    },
    async removeDoorCharge(chargeId) {
      this.saving = true;
      await this.removeCharge(chargeId);
      this.saving = false;
    },
    switchAutoUpdate() {
      return this.doorPropUpdated({
        prop: "autoUpdatePrices",
        value: this.door.autoUpdatePrices
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.door-sections {
  display: grid;
  grid-template-columns: auto minmax(900px, 1fr) auto;

  .door-list {
    margin-right: 0.5rem;
    max-width: 275px;
  }

  @media (max-width: 1500px) {
    grid-template-columns: auto minmax(900px, 1fr);
    .survey-and-charges {
      display: none;
    }
  }

  .survey-and-charges {
    margin-left: 0.5rem;
    max-width: 450px;
  }

  .buttons-spacing > * {
    margin-right: 4px;
    margin-top: 4px;
  }
}
</style>
