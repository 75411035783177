<template>
  <v-container>
    <div v-if="order.id">
      <DoorBreadcrumbs :order="order" :door="door" pageName="Door Set" />
      <div class="mb-4">
        <v-btn color="primary" class="mr-4" @click="print">Print</v-btn>
        <v-btn :to="`/doors/${door.id}`" class="mr-2" color="primary">
          Door Builder
        </v-btn>
        <v-btn
          color="primary"
          class="mr-2"
          :to="`/orders/${order.id}/doors/${door.id}/bom`"
        >
          Bill of Materials
        </v-btn>
      </div>
    </div>
    <DoorSetLoading class="mt-4" v-if="loading" />
    <div v-else>
      <DoorSet
        class="section-to-print top-page"
        :door="door"
        :threshold-image-name="thresholdImageName"
        :new-clear-opening="newClearOpening"
        :order-ref="order.reference"
        :closers="closers"
      />
      <div class="section-to-print sheet padding-10mm">
        <strong>Notes</strong>
        <div>{{ door.notes }}</div>
        <strong>Images</strong>
        <v-container class="pa-0">
          <v-row dense>
            <v-col>
              <div class="mb-4">Front</div>
              <img class="door_image" :src="frontImageUrl" />
            </v-col>
            <v-col>
              <div class="mb-4">Back</div>
              <img class="door_image" :src="backImageUrl" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
import { get, call } from "vuex-pathify";
import { storage } from "@/plugins/firebase";
import DoorSet from "@gradys/vue-base/components/review/door-set";
import DoorSetLoading from "@/components/review/door-set-loading";
import DoorBreadcrumbs from "@gradys/vue-base/components/door/door-breadcrumbs";

export default {
  components: {
    DoorSet,
    DoorSetLoading,
    DoorBreadcrumbs
  },
  data() {
    return {
      frontImageUrl: "",
      backImageUrl: "",
      error: "",
      loading: true
    };
  },
  computed: {
    closers: get("lookups/closers"),
    order: get("orders/order"),
    door: get("doors/door"),
    newClearOpening: get("doors/newClearOpening"),
    thresholdImageName: get("doors/thresholdImageName")
  },
  created() {
    return this.load();
  },
  mounted() {
    this.mountImage(this.door.frontImageUrl, "frontImageUrl");
    this.mountImage(this.door.backImageUrl, "backImageUrl");
  },
  watch: {
    $route() {
      return this.load();
    }
  },
  methods: {
    fetchDoor: call("doors/fetchDoor"),
    fetchOrder: call("orders/fetchOrder"),
    async load() {
      try {
        this.loading = true;
        const { orderId, doorId } = this.$route.params;
        await Promise.all([this.fetchOrder(orderId), this.fetchDoor(doorId)]);
      } finally {
        this.loading = false;
      }
    },
    mountImage(imgUrl, prop) {
      if (!imgUrl) return;

      const ref = storage.ref(imgUrl);

      ref
        .getDownloadURL()
        .then(url => {
          this[prop] = `${url}.jpg`;
        })
        .catch(e => {
          this.error = e.code;
        });
    },
    print() {
      window.print();
    }
  }
};
</script>

<style scoped>
.door_image {
  max-width: 100%;
  max-height: 300px;
}
</style>
