<template>
  <v-container class="pl-2">
    <v-row>
      <v-col cols="6" class="pa-0">
        <BomImage
          :door="door"
          :image="headImage"
          :showButton="true"
          :showTitle="true"
          ><HeadImage :door="door" v-on="$listeners"
        /></BomImage>
        <BomImage
          :door="door"
          :image="thresholdImage"
          :showButton="true"
          :showTitle="true"
        >
          <ThresholdSelect
            :door="door"
            :thresholds="thresholds"
            :threshold-image-name="thresholdImageName"
            v-on="$listeners"
          />
        </BomImage>
      </v-col>
      <v-col cols="6" class="py-0">
        <BomImage
          :door="door"
          :image="jambImage"
          :showButton="true"
          :showTitle="true"
        >
          <JambImage :door="door" v-on="$listeners" />
        </BomImage>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="3">Door Height</v-col>
      <v-col cols="3">Door Width</v-col>
      <v-col cols="3">New Clear Opening</v-col>
    </v-row>
    <v-row>
      <v-col cols="3">{{ frameDimms.doorHeight }}</v-col>
      <v-col cols="3">{{ frameDimms.doorWidth }}</v-col>
      <v-col cols="3">
        {{ newClearOpening }}
        <div
          v-for="warning in openingWarnings"
          :key="warning.key"
          class="warning--text"
        >
          {{ warning.error }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get } from "vuex-pathify";
import { thresholdsOptions } from "@gradys/vue-base/store/lookups/lookup-values";
import { mixin } from "@gradys/vue-base/components/forms/mixins";

import ThresholdSelect from "@gradys/vue-base/components/forms/threshold-select";
import JambImage from "@gradys/vue-base/components/forms/jamb-image";
import HeadImage from "@gradys/vue-base/components/forms/head-image";
import BomImage from "@/components/images/bom-image.vue";

export default {
  mixins: [mixin],
  components: {
    ThresholdSelect,
    JambImage,
    HeadImage,
    BomImage
  },
  computed: {
    door: get("doors/door"),
    frameDimms: get("doors/frameDimms"),
    thresholdImageName: get("doors/thresholdImageName"),
    newClearOpening: get("doors/newClearOpening"),
    openingWarnings: get("doors/openingWarnings"),
    headImage: get("doors/headImage"),
    jambImage: get("doors/jambImage"),
    thresholdImage: get("doors/thresholdImage"),
    thresholds() {
      return thresholdsOptions;
    }
  }
};
</script>
