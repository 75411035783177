<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 350px">File type</th>
          <th>File Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, i) in allFiles" :key="i">
          <td class="caption font-weight-medium">{{ file.type }}</td>
          <td v-if="hasFile(file)" class="caption">
            {{ file.fileName || file.fileUrl }}
          </td>
          <td v-else class="py-2">
            <FileUploadButton :file-type="file.type" @on:file="fileUploaded" />
          </td>
          <td>
            <div v-if="hasFile(file)" class="py-1">
              <v-btn
                x-small
                class="my-1"
                color="primary"
                @click="download(file)"
              >
                Download
              </v-btn>
              <v-btn
                x-small
                class="my-1 ml-2"
                color="error"
                @click="removeFile(file)"
              >
                Remove File
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { generateFileMap, downloadFile } from "./file-upload-util";
import FileUploadButton from "./file-upload-button";

export default {
  components: {
    FileUploadButton
  },
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    allFiles() {
      return generateFileMap(this.files);
    }
  },
  methods: {
    hasFile(file) {
      return file.fileName || file.fileUrl;
    },
    fileUploaded(file) {
      this.$emit("on:file", file);
    },
    removeFile(file) {
      this.$emit("on:file-removed", file);
    },
    download(file) {
      downloadFile(file);
    }
  }
};
</script>
