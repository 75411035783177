<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="surveyor.name"
            label="Name"
            :rules="nameRules"
            required
          />
          <v-text-field
            v-model="surveyor.email"
            hint="Must match the email they login with"
            persistent-hint
            validate-on-blur
            label="Email"
            :rules="emailRules"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">Save</v-btn>
        <v-btn text color="red" @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-show="canDelete"
          color="error"
          class="text-right"
          @click="remove"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      surveyor: {},
      nameRules: [v => !!v || "Name is required"],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    };
  },
  computed: {
    formTitle() {
      return this.surveyor.id ? "Edit Surveyor" : "Add Surveyor";
    },
    canDelete() {
      return this.surveyor.id;
    }
  },
  methods: {
    open(surveyor) {
      this.dialog = true;
      this.surveyor = { ...surveyor };

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    remove() {
      const ans = confirm("Are you sure you want to delete this Surveyor");
      if (ans) {
        this.$emit("on:delete", this.surveyor);
      }
    },
    save() {
      if (this.$refs.form.validate()) this.$emit("on:save", this.surveyor);
    }
  }
};
</script>
