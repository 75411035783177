<template>
  <tr :class="rowClass">
    <td>{{ charge.description || "Not Set" }}</td>
    <td class="text-right">
      {{ charge.price | number("0,0.00") }}
    </td>
    <td class="text-right">
      <v-btn icon color="warning darken-1" @click="edit">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </td>
    <td>
      <v-btn icon color="red" @click="remove" v-if="allowRemove">
        <v-icon>mdi-close-box-outline</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    charge: {
      type: Object,
      required: true
    }
  },
  computed: {
    allowRemove() {
      return this.isManual;
    },
    rowClass() {
      if (this.isEdited) return "orange lighten-4";
      if (this.isManual) return "green lighten-4";
      return "";
    },
    isCalculated() {
      return this.checkStatus("calculated");
    },
    isEdited() {
      return this.checkStatus("edited");
    },
    isManual() {
      return this.checkStatus("manual");
    }
  },
  methods: {
    edit() {
      this.$emit("on:edit-clicked", this.charge);
    },
    remove() {
      this.$emit("on:remove-clicked", this.charge);
    },
    // TODO : Move to a utils class
    checkStatus(str) {
      return (
        str.localeCompare(this.charge.status, undefined, {
          sensitivity: "base"
        }) === 0
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
