<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        Add Delivery Address
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-select
            v-model="clientContractId"
            :items="contracts"
            item-value="id"
            item-text="name"
            clearable
            label="Contract"
          >
          </v-select>
          <v-textarea
            v-model="address"
            label="Delivery Address"
            :rules="addressRules"
            required
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">
          Save
        </v-btn>
        <v-btn text color="red" @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    contracts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      address: "",
      clientContractId: null,
      id: 0,
      addressRules: [v => !!v || "Address is required"],
      dialog: false,
      loading: false
    };
  },
  methods: {
    open(address) {
      if (address) {
        this.address = address.address;
        this.clientContractId = address.clientContractId;
        this.id = address.id;
      } else {
        this.id = 0;
        setTimeout(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
            this.$refs.form.reset();
          }
        }, 10);
      }

      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    save() {
      if (this.$refs.form.validate()) {
        const { address, clientContractId, id } = this;
        this.$emit("on:save", {
          id,
          address,
          clientContractId
        });
      }
    }
  }
};
</script>
