<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="pt-2 pb-4 px-0">
    </v-breadcrumbs>
    <div v-if="loading">
      <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
    </div>
    <div v-else>
      <DoorEditor v-if="allowEdit" />
      <div v-else>
        <h2>This order is not open for editing</h2>
      </div>
    </div>
  </div>
</template>
<script>
import { get, call } from "vuex-pathify";

import DoorEditor from "@/components/order/door-editor";

export default {
  components: {
    DoorEditor
  },
  data() {
    return {
      dialog: false,
      loading: true
    };
  },
  computed: {
    order: get("orders/order"),
    doors: get("orders/doors"),
    allowEdit() {
      if (!this.order) return false;

      return this.order.currentEditor === this.$auth.name;
    },
    breadcrumbs() {
      if (!this.order) return [];

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: `View Order ${this.order.reference}`,
          to: `/order/${this.order.id}`,
          exact: true
        },
        {
          text: "Edit Order"
        }
      ];
    }
  },
  methods: {
    fetchOrder: call("orders/fetchOrder"),
    fetchDoors: call("orders/fetchDoors"),
    updateDoors: call("orders/updateMultipleDoors")
  },

  async created() {
    try {
      const { id } = this.$route.params;
      await Promise.all([this.fetchOrder(id), this.fetchDoors(id)]);
    } finally {
      this.loading = false;
    }
  }
};
</script>
