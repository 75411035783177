<template>
  <div class="mt-4 sheet section-to-print">
    <NarrowRow>
      <v-col cols="6">
        <BomImage
          :door="door"
          :image="jambImage"
          :showButton="false"
          :showTitle="true"
          :key="jambImage.imageURL"
        >
          <JambImage :show-select="false" :door="door" />
        </BomImage>
        <v-simple-table class="narrow-table">
          <template v-slot:default>
            <tbody>
              <tr>
                <th>New Clear Opening</th>
                <td>{{ billOfMaterials.clearOpening }}</td>
              </tr>
              <tr>
                <th>Intended Fire Rating</th>
                <td>{{ door.intendedFireRating }}</td>
              </tr>
              <tr>
                <th>Frame Material</th>
                <td>{{ door.frameMaterial }}</td>
              </tr>
              <tr>
                <th>Sash Glazing</th>
                <td>{{ door.sashMaterial || "-" }}</td>
              </tr>
              <tr>
                <th>Door Thickness</th>
                <td>{{ door.doorThickness }}</td>
              </tr>
              <tr>
                <th>Door Handing</th>
                <td>{{ door.doorHanding }}</td>
              </tr>
              <tr>
                <th>Door Opens</th>
                <td>{{ door.opens }}</td>
              </tr>
              <tr>
                <th>Rain Deflector</th>
                <td>{{ door.rainDeflector }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <div class="pa-4">
          <BomImage
            :door="door"
            :image="headImage"
            :showButton="false"
            :showTitle="true"
            :key="headImage.imageURL"
          >
            <HeadImage :show-select="false" :door="door" />
          </BomImage>

          <BomImage
            :door="door"
            :image="thresholdImage"
            :showButton="false"
            :showTitle="true"
          >
            <ThresholdSelect
              :door="door"
              :show-select="false"
              :threshold-image-name="thresholdImageName"
              :key="thresholdImageName.imageURL"
          /></BomImage>
        </div>
      </v-col>
    </NarrowRow>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import JambImage from "@gradys/vue-base/components/forms/jamb-image.vue";
import ThresholdSelect from "@gradys/vue-base/components/forms/threshold-select.vue";
import HeadImage from "@gradys/vue-base/components/forms/head-image.vue";
import BomImage from "@/components/images/bom-image.vue";

export default {
  components: {
    JambImage,
    ThresholdSelect,
    HeadImage,
    BomImage
  },
  props: {
    door: {
      type: Object,
      default() {
        return {};
      }
    },
    billOfMaterials: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    thresholdImageName: get("doors/thresholdImageName"),
    headImage: get("doors/headImage"),
    jambImage: get("doors/jambImage"),
    thresholdImage: get("doors/thresholdImage")
  }
};
</script>
