<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <v-card :loading="loading">
      <AddQuoteForm ref="form" @on:save="saveQuote" />
      <v-card-title>
        Quotes
        <v-btn color="primary" dark class="ml-12" small @click="addQuote">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          Create New Quote
        </v-btn>
      </v-card-title>
      <v-data-table
        class="click-table"
        :headers="headers"
        :hide-default-header="false"
        :items="quotes"
        :items-per-page="-1"
        :height="tableHeight"
        no-data-text="No Quotes"
        fixed-header
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.reference }}</td>
            <td>{{ row.item.clientName }}</td>
            <td>{{ row.item.noDoors }}</td>
            <td>{{ row.item.totalCharge | number("0,0.00") }}</td>
            <td><OrderStatus :status="row.item.status" /></td>
            <td>{{ row.item.created | euDate }}</td>
            <td>
              <v-btn class="primary" small @click="showQuote(row.item)"
                >View Quote</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AddQuoteForm from "@/components/quotes/add-quote-form.vue";
import OrderStatus from "@/components/order/order-status";

import { get, call } from "vuex-pathify";
export default {
  computed: {
    quotes: get("quotes/quotes"),
    tableHeight() {
      return this.windowHeight - 300;
    }
  },
  components: { AddQuoteForm, OrderStatus },
  async created() {
    await this.loadQuotes();
    this.loading = false;
  },
  data() {
    return {
      reference: "",
      status: "",
      saving: true,
      loading: true,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Quotes",
          to: "/quotes",
          exact: true
        }
      ],
      headers: [
        {
          text: "Quote Ref",
          value: "reference"
        },
        {
          text: "Client",
          value: "clientName"
        },
        {
          text: "No. Of Doors",
          value: "noDoors"
        },
        {
          text: "Cost",
          value: "totalCharge"
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Date Created",
          value: "date"
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false
        }
      ]
    };
  },

  methods: {
    loadQuotes: call("quotes/loadQuotes"),
    newQuote: call("quotes/saveQuote"),
    async showQuote(item) {
      this.$router.push(`quotes/${item.id}`);
    },
    addQuote() {
      this.$refs.form.open();
    },
    async saveQuote({ reference, clientId }) {
      try {
        this.saving = true;
        const { id } = await this.newQuote({
          reference: reference,
          clientId: clientId
        });

        this.$router.push(`/quotes/${id}`);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
