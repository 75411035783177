<script>
export default {
  props: {
    costSummary: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<template>
  <v-card outlined>
    <v-card-text>
      <div class="subtitle-1 mb-2">Cost Breakdown</div>
      <table class="details-table full-width">
        <tr>
          <th>Type</th>
          <th>Weight</th>
          <th>Cost</th>
          <th>Price</th>
        </tr>
        <tr
          v-for="price in costSummary.doorPrices"
          :key="price.intendedFireRating"
        >
          <td>{{ price.intendedFireRating || "-" }}</td>
          <td>{{ price.weight | number("0,0.0") }}</td>
          <td>{{ price.cost | number("0,0.00") }}</td>
          <td>{{ price.doorPrice | number("0,0.00") }}</td>
        </tr>
        <tr>
          <td class="pt-4"></td>
        </tr>
        <tr>
          <th>Subtotal</th>
          <td>{{ costSummary.subtotal.weight | number("0,0.0") }} kg</td>
          <td>£{{ costSummary.subtotal.cost | number("0,0.00") }}</td>
          <td>£{{ costSummary.subtotal.doorPrice | number("0,0.00") }}</td>
        </tr>
        <tr>
          <td class="pt-8"></td>
        </tr>
        <tr>
          <td></td>
          <th colspan="2" class="text-right pr-4">Additional Costs</th>
          <td>£{{ costSummary.additionalCosts | number("0,0.00") }}</td>
        </tr>
        <tr>
          <td></td>
          <th colspan="2" class="text-right pr-4">Total</th>
          <td>£{{ costSummary.total | number("0,0.00") }}</td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>
