<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="pt-2 pb-4 px-0">
    </v-breadcrumbs>
    <div v-if="loading">
      <v-skeleton-loader
        :loading="loading"
        type="card-heading, list-item@5"
        class="mb-4"
      ></v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        type="table-tbody"
      ></v-skeleton-loader>
    </div>
    <v-card v-else>
      <v-card-title>
        {{ installer.name }}
        <v-btn class="ml-12" small color="error" @click="remove">
          Remove Installer
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Installer Details</v-card-subtitle>
      <v-card-text>
        <v-form ref="form">
          <v-container class="pa-0" fluid>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" class="pr-md-8">
                <CommonForm
                  :installer="installer"
                  :companies="bmTradaCompanies"
                  @on:name-changed="e => updateProp('name', e)"
                  @on:company-changed="e => updateProp('bmTradaCompanyId', e)"
                  @on:cert-no-changed="e => updateProp('certNo', e)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pl-md-8">
                <v-textarea
                  :value="installer.address"
                  label="Installer Address"
                  @change="e => updateProp('address', e)"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <div class="subtitle-1">Signature</div>
                <div v-if="installer.signatureUrl">
                  <v-img
                    :src="installer.signatureUrl"
                    max-height="100"
                    max-width="400"
                    contain
                  />
                </div>
                <div class="py-2">
                  <FileUploadButton
                    upload-text="Upload Signature"
                    :upload-location="`signatures/installers/${installer.id}`"
                    @on:file="signatureUploaded"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <div class="subtitle-1">Installer Cert</div>
                <div v-if="installer.certUrl">
                  <span class="subtitle-2">
                    {{ installer.certName }}
                  </span>
                  <v-btn
                    class="ml-8"
                    :loading="downloading"
                    small
                    color="primary"
                    @click="downloadCert"
                  >
                    Download Cert
                  </v-btn>
                  <v-btn class="ml-2" color="error" small @click="removeCert">
                    Remove Cert
                  </v-btn>
                </div>
                <div v-else class="py-2">
                  <FileUploadButton
                    upload-text="Upload Cert"
                    :upload-location="`certs/installer-certs/${installer.id}`"
                    @on:file="certUploaded"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <InstallerFitters
      v-show="!loading"
      ref="fitterComponent"
      :fitters="fitters"
      :sub-contractors="subContractors"
      class="mt-4"
      @on:save="saveFitter"
      @on:delete="removeFitter"
    />
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import CommonForm from "@/components/forms/installers/common-form-elements";
import FileUploadButton from "@/components/files/file-upload-button";
import InstallerFitters from "@/components/forms/fitters/installer-fitters";
import { downloadFile } from "@/components/files/file-upload-util";
import { storage } from "@/plugins/firebase";

export default {
  components: {
    FileUploadButton,
    CommonForm,
    InstallerFitters
  },
  data() {
    return {
      nameRules: [v => !!v || "Name is required"],
      loading: true,
      downloading: false
    };
  },
  computed: {
    subContractors: get("subContractors/subContractors"),
    bmTradaCompanies: get("bmTradaCompanies/companies"),
    installer: get("installers/selectedInstaller"),
    fitters: get("fitters/fitters"),
    breadcrumbs() {
      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Installers",
          to: "/fitters/installers",
          exact: true
        },
        {
          text: this.installerName
        }
      ];
    },
    installerName() {
      if (!this.installer) return "";

      return this.installer.name;
    }
  },
  async created() {
    try {
      await Promise.all([
        this.loadBmTradaCompanies(),
        this.loadInstaller(this.$route.params.id),
        this.loadFitters(this.$route.params.id),
        this.loadSubcontractors()
      ]);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadSubcontractors: call("subContractors/loadSubcontractors"),
    loadBmTradaCompanies: call("bmTradaCompanies/loadBmTradaCompanies"),
    loadInstaller: call("installers/loadInstaller"),
    removeInstaller: call("installers/removeInstaller"),
    saveInstaller: call("installers/saveInstaller"),
    loadFitters: call("fitters/loadFitters"),
    // Name clash
    saveFitterStore: call("fitters/saveFitter"),
    removeFitterStore: call("fitters/removeFitter"),
    async remove() {
      const ans = confirm("Are you sure you want to delete this Installer");

      if (!ans) return;

      await this.removeInstaller(this.installer);
      this.$router.replace({
        path: "/fitters/installers"
      });
    },
    async updateProp(prop, value) {
      if (!this.$refs.form.validate()) return;
      const data = { ...this.installer };
      data[prop] = value;

      await this.saveInstaller(data);
    },
    async saveFitter(data) {
      data.installerId = this.installer.id;
      this.$refs.fitterComponent.setLoading(true);
      await this.saveFitterStore(data);
      await this.reloadFitters();
      this.$refs.fitterComponent.closeDialog();
      this.$refs.fitterComponent.setLoading(false);
    },
    async removeFitter(fitter) {
      await this.removeFitterStore(fitter);
      await this.reloadFitters();
      this.$refs.fitterComponent.closeDialog();
    },
    reloadFitters() {
      return this.loadFitters(this.installer.id);
    },
    async certUploaded(file) {
      const data = { ...this.installer };
      data.certUrl = file.url;
      data.certName = file.file.name;
      await this.saveInstaller(data);
    },
    signatureUploaded(file) {
      const ref = storage.ref(file.url);
      ref.getDownloadURL().then(url => {
        this.updateProp("signatureUrl", url);
      });
    },
    async downloadCert() {
      this.downloading = true;
      await downloadFile({
        fileUrl: this.installer.certUrl,
        fileName: this.installer.certName
      });
      this.downloading = false;
    },
    async removeCert() {
      const data = { ...this.installer };
      data.certUrl = "";
      data.certName = "";
      await this.saveInstaller(data);
    }
  }
};
</script>
