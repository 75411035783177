export function getIcon(file) {
  if (this.isImage(file)) return 'mdi-file-image-outline'
  if (this.isPdf(file)) return 'mdi-file-pdf'
  if (this.isVideo(file)) return 'mdi-video'

  return 'mdi-file'
}

export function isImage(file) {
  return (/\.(gif|jpg|jpeg|tiff|png)$/i).test(file.fileName)
}

export function isVideo(file) {
  return (/\.(mp4)$/i).test(file.fileName)
}

export function isPdf(file) {
  return (/\.(pdf)$/i).test(file.fileName) || (/\.(pdf)$/i).test(file.fileUrl)
}