<template>
  <v-card>
    <v-card-title>Select Frame Type</v-card-title>
    <v-card-subtitle class="red--text pt-2">
      <v-icon color="error" class="mr-2">mdi-alert</v-icon> Changing a frame type will reset all your dimensions
    </v-card-subtitle>
    <v-card-text
      class="d-flex justify-space-between flex-wrap"
      :style="cardStyle"
    >
      <div
        class="frame-item text-center"
        v-for="frame in frameTypes"
        :key="frame.value"
        @click="frameClicked(frame.value)"
      >
        <div class="title">{{ frame.text }}</div>
        <img
          :src="require(`@/assets/frame-images/${frame.value}.svg`)"
          :alt="frame.text"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { frameTypes } from "@gradys/vue-base/store/lookups/lookup-values";
export default {
  props: {
    limitHeight: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    cardStyle() {
      if (this.limitHeight) {
        return {
          height: "450px"
        }
      }

      return {};
    }    
  },
  data() {
    return {
      frameTypes
    };
  },
  methods: {
    frameClicked(value) {
      this.$emit("on:frame-clicked", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.frame-item {
  width: 150px;
  padding-bottom: 16px;
  border: solid 2px rgb(223, 221, 221);
  margin-bottom: 4px;

  &:hover {
    cursor: pointer;
    border: solid 2px #1870e2d8;
  }
  img {
    max-width: 100px;
    max-height: 140px;
  }
}
</style>
