<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-card-title>
          Add Cost
        </v-card-title>
        <v-card-text>
          <BaseText label="Description" v-model="charge.description" />
          <BaseText label="Price" v-model.number="charge.price" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :loading="saving" @click="save">
            Save
          </v-btn>
          <v-btn text color="error" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-simple-table dense class="mb-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Description</th>
            <th class="text-right">Price</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <DoorChargeRow
            v-for="charge in line.charges"
            :key="charge.id"
            :charge="charge"
            @on:edit-clicked="edit"
            @on:remove-clicked="remove"
          />
          <tr v-if="!line.charges || !line.charges.length">
            <td colspan="4">No Charges</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn small color="primary" @click="addCharge">Add Charge</v-btn>
    <v-divider class="my-2"></v-divider>
    <table class="normal-table" v-if="!isOrder">
      <tr>
        <th style="width: 150px">Item Price</th>
        <td>{{ line.itemPrice | number("0,0.00") }}</td>
      </tr>
      <tr>
        <th>Quantity</th>
        <td>
          <BaseText
            v-model.number="line.quantity"
            @input="updateQuantity"
            :loading="saving"
          />
        </td>
      </tr>
      <tr>
        <th>Total</th>
        <td>{{ line.total | number("0,0.00") }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import DoorChargeRow from "@/components/quotes/door-charge-row";

export default {
  components: {
    DoorChargeRow
  },
  data() {
    return {
      dialog: false,
      charge: {}
    };
  },
  props: {
    line: {
      type: Object,
      default() {
        return {};
      }
    },
    isOrder: {
      type: Boolean,
      default() {
        return false;
      }
    },
    saving: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    addCharge() {
      this.charge = {
        doorId: this.line.doorId,
        description: "",
        price: 0
      };
      this.dialog = true;
    },
    edit(charge) {
      this.charge = { ...charge };
      this.dialog = true;
    },
    remove(charge) {
      this.$emit("on:removeDoorCharge", charge.id);
    },
    updateQuantity() {
      this.$emit("on:updateQuoteLine", this.line);
    },
    save() {
      try {
        const doorId = this.isOrder ? this.line.id : this.line.doorId;
        this.charge.doorId = doorId;
      } finally {
        this.$emit("on:saveDoorCharge", this.charge);
        this.dialog = false;
      }
    }
  }
};
</script>
