<template>
  <div v-if="door && door.id">
    <DoorSet
      class="section-to-print"
      :door="door"
      :threshold-image-name="thresholdImageName"
      :new-clear-opening="newClearOpening"
      :order-ref="door.orderReference"
      :closers="closers"
    />
    <v-container class="pa-0" v-if="showNotesSection">
      <div class="section-to-print sheet padding-10mm">
        <strong>Notes</strong>
        <div>{{ door.notes }}</div>
        <div class="mt-4" v-if="showImagesSection">
          <strong>Images</strong>
          <v-row dense>
            <v-col>
              <div class="mb-4">Front</div>
              <img class="door_image" :src="frontImageUrl" />
            </v-col>
            <v-col>
              <div class="mb-4">Back</div>
              <img class="door_image" :src="backImageUrl" />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
// TODO : Refactor this with combination of "view-door-set.vue"
// NOTE : Need to get out for Monday Feb 1st 2021

import { get, call } from "vuex-pathify";
import { storage } from "@/plugins/firebase";
import DoorSet from "@gradys/vue-base/components/review/door-set";

export default {
  components: {
    DoorSet
  },
  computed: {
    door: get("doors/door"),
    closers: get("lookups/closers"),
    newClearOpening: get("doors/newClearOpening"),
    thresholdImageName: get("doors/thresholdImageName"),
    showNotesSection() {
      return [this.door.notes, this.frontImageUrl, this.backImageUrl].some(
        b => b
      );
    },
    showImagesSection() {
      return [this.door.frontImageUrl, this.door.backImageUrl].some(b => b);
    }
  },
  data() {
    return {
      frontImageUrl: "",
      backImageUrl: ""
    };
  },
  async created() {
    await this.fetchDoor(this.$route.params.id);
    await Promise.all([
      this.mountImage(this.door.frontImageUrl, "frontImageUrl"),
      this.mountImage(this.door.backImageUrl, "backImageUrl")
    ]);
  },
  methods: {
    fetchDoor: call("doors/fetchDoor"),
    async mountImage(imgUrl, prop) {
      if (!imgUrl) return;

      const ref = storage.ref(imgUrl);
      const url = await ref.getDownloadURL();
      this[prop] = `${url}.jpg`;
    }
  }
};
</script>

<style scoped>
.door_image {
  max-width: 100%;
  max-height: 300px;
}
</style>
