<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <InvoiceForm ref="addForm" :invoice="invoice" />
    <div :loading="loading" class="section-to-print">
      <v-card>
        <v-row>
          <v-col>
            <v-card-title> {{ subContractor.name }} </v-card-title>
            <v-card-text>
              <div style="max-width: 300px">
                <NarrowRow>
                  <v-col>Address:</v-col>
                  <v-col>{{ subContractor.address }}</v-col>
                </NarrowRow>
                <NarrowRow>
                  <v-col>Invoice Reference:</v-col>
                  <v-col>{{ invoice.invoiceReference }}</v-col>
                </NarrowRow>
                <NarrowRow>
                  <v-col>Grady Reference:</v-col>
                  <v-col>{{ invoice.gradyReference }}</v-col>
                </NarrowRow>
              </div>
            </v-card-text>
          </v-col>
          <v-col></v-col>
          <v-col>
            <v-card-text>
              {{ fileName }}
              <div v-if="invoice.invoiceUrl">
                <v-btn class="ml-8" small color="primary" @click="download">
                  Download
                </v-btn>
                <v-btn class="ml-2" color="error" small @click="removeFile">
                  Remove
                </v-btn>
              </div>
              <div class="py-2">
                <FileUploadButton
                  v-if="!invoice.invoiceUrl"
                  upload-text="Upload File"
                  :upload-location="`invoices/${invoice.id}`"
                  @on:file="invoiceDocumentUpload"
                />
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn small color="primary" @click="editInvoice(invoice)"
            >Edit Invoice Details</v-btn
          >
          <v-btn small color="error" @click="removeInvoice(invoice)"
            >Delete Invoice</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-card class="mt-10">
        <v-card-title> Invoice </v-card-title>
        <v-card-text>
          <div style="max-width: 300px">
            <NarrowRow>
              <v-col>Net Due</v-col>
              <v-col> £ {{ invoice.net | number("0,0.00") }}</v-col>
            </NarrowRow>
            <NarrowRow>
              <v-col>Total Approved</v-col>
              <v-col> £ {{ invoice.totalAllocated | number("0,0.00") }}</v-col>
            </NarrowRow>
            <NarrowRow>
              <v-col>Total Unallocated</v-col>
              <v-col>
                £ {{ invoice.totalUnallocated | number("0,0.00") }}</v-col
              >
            </NarrowRow>
          </div>

          <v-btn small color="primary mt-6" @click="toggleLock()">
            <v-icon small left>{{ icon }}</v-icon
            >{{ text }}</v-btn
          >
        </v-card-text>
        <v-data-table
          disable-pagination
          :headers="headers"
          :items="invoice.invoiceLines"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.amountApproved`]="{ item }">
            <div v-if="item.amountApproved">
              £ {{ item.amountApproved | number("0,0.00") }}
            </div>
          </template>
          <template v-slot:[`item.fitterPayApprovedDate`]="{ item }">
            <div v-if="item.fitterPayApprovedDate">
              {{ item.fitterPayApprovedDate | euDate }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              :disabled="invoice.locked"
              small
              color="error"
              @click="removeInvoiceLine(item.doorId)"
            >
              Remove
            </v-btn>
          </template>
        </v-data-table>

        <v-btn class="ma-5" small @click="print" v-if="!printing"
          >Print as document</v-btn
        >
      </v-card>
    </div>
  </div>
</template>

<script>
import { downloadFile } from "@/components/files/file-upload-util";
import { storage } from "@/plugins/firebase";
import { get, call } from "vuex-pathify";
import InvoiceForm from "@/components/forms/sub-contractors/invoice-form.vue";
import FileUploadButton from "@/components/files/file-upload-button";
export default {
  components: { InvoiceForm, FileUploadButton },
  data() {
    return {
      loading: true,
      printing: false,
      headers: [
        { text: "Numeral", value: "numerals" },
        { text: "Address", value: "address" },
        { text: "OrderReference", value: "orderReference" },
        { text: "Approved By", value: "fitterPayApprovedBy" },
        { text: "Surveyor", value: "surveyor" },
        { text: "Date", value: "fitterPayApprovedDate" },
        { text: "Amount Approved", value: "amountApproved" },
        {
          text: "",
          value: "actions",
          width: 210,
          align: "end",
          sortable: false
        }
      ]
    };
  },
  computed: {
    invoice: get("subContractors/subContractorInvoice"),
    subContractors: get("subContractors/subContractors"),

    icon() {
      if (this.invoice.locked) {
        return "mdi-lock-open-variant";
      }
      return "mdi-lock";
    },
    text() {
      if (this.invoice.locked) {
        return "Unlock Invoice";
      }
      return "Lock Invoice";
    },
    fileName() {
      if (!this.invoice.invoiceUrl) {
        return "No Invoice Document Currently Uploaded";
      }
      return `${this.invoice.gradyReference} -  ${this.invoice.invoiceReference}.pdf`;
    },
    subContractor() {
      if (!this.subContractors.length) return { name: "", address: "" };
      return this.subContractors.find(
        x => x.id === parseInt(this.$route.params.id)
      );
    },

    breadcrumbs() {
      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Subcontractors",
          to: "/fitters/sub-contractors",
          exact: true
        },
        {
          text: this.subContractor.name,
          to: "/fitters/sub-contractors/" + this.subContractor.id,
          exact: true
        },
        {
          text: this.invoice.invoiceReference
        }
      ];
    }
  },
  async created() {
    await this.loadSubcontractorInvoice({
      id: this.$route.params.invoiceId,
      subContractorId: this.$route.params.id
    });
    await this.loadSubcontractors();
    await this.loadOrders();
  },
  methods: {
    loadSubcontractorInvoice: call("subContractors/loadSubcontractorInvoice"),
    loadSubcontractors: call("subContractors/loadSubcontractors"),
    unlockInvoice: call("subContractors/unlockInvoice"),
    lockInvoice: call("subContractors/lockInvoice"),
    removeInvoiceLineFn: call("doors/removeInvoiceLine"),
    loadOrders: call("orders/fetchOrders"),
    removeSubcontractorInvoice: call(
      "subContractors/removeSubcontractorInvoice"
    ),
    saveSubcontractorInvoice: call("subContractors/saveSubcontractorInvoice"),

    print() {
      this.printing = true;
      window.print();
      this.printing = false;
    },
    async invoiceDocumentUpload(file) {
      const ref = storage.ref(file.url);
      this.invoice.invoiceUrl = ref.fullPath;
      await this.saveSubcontractorInvoice(this.invoice);
    },

    async removeInvoice(invoice) {
      if (!confirm("Are you sure you want to delete this invoice?")) return;
      await this.removeSubcontractorInvoice(invoice);
      this.$router.push(`/fitters/sub-contractors/${invoice.subContractorId}`);
    },
    editInvoice() {
      this.$refs.addForm.open();
    },

    async removeFile() {
      if (!confirm("Are you sure you want to delete this invoice document?"))
        return;

      //this allows for a soft delete of the document
      this.invoice.invoiceUrl = "";
      await this.saveSubcontractorInvoice(this.invoice);
    },

    async toggleLock() {
      this.loading = true;
      try {
        if (this.invoice.locked) await this.unlockInvoice(this.invoice.id);
        else await this.lockInvoice(this.invoice.id);

        await this.loadSubcontractorInvoice(this.invoice);
      } finally {
        this.loading = false;
      }
    },

    async removeInvoiceLine(doorId) {
      if (!confirm("Are you sure you want to delete this entry?")) return;
      this.loading = true;
      try {
        await this.removeInvoiceLineFn(doorId);
        await this.loadSubcontractorInvoice(this.invoice);
      } finally {
        this.loading = false;
      }
    },

    async download() {
      this.downloading = true;
      await downloadFile({
        fileUrl: this.invoice.invoiceUrl,
        fileName: `Invoice:${this.invoice.gradyReference}-${this.invoice.invoiceReference}`
      });
      this.downloading = false;
    }
  }
};
</script>
