<template>
  <v-text-field
    :value="value"
    :label="label"
    :loading="loading"
    :placeholder="placeholder"
    :type="type"
    :disabled="disabled"
    :error="error"
    :error-messages="formattedErrors"
    @change="e => $emit('input', e)"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    value: { type: [Number, String], default: "" },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: [Array, String], default: "" },
    loading: { type: Boolean, default: false }
  },
  computed: {
    formattedErrors() {
      if (Array.isArray(this.errorMessages)) {
        return this.errorMessages.map(e => e.error);
      }

      return this.errorMessages;
    },
    type() {
      if (typeof this.value === "number") return "number";

      return "text";
    }
  }
};
</script>
