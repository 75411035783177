<template>
  <g v-if="showFingerHandle">
<svg :x="x" y="195" width="20" height="42" viewBox="0 0 250 520" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="249" height="519" rx="9.5" fill="#96928E" stroke="black"/>
<path d="M155.5 165.5C155.5 163.854 155.367 162.238 155.112 160.664C169.801 151.013 179.5 134.389 179.5 115.5C179.5 85.6766 155.323 61.5 125.5 61.5C95.6766 61.5 71.5 85.6766 71.5 115.5C71.5 134.389 81.1986 151.013 95.8878 160.664C95.6326 162.238 95.5 163.854 95.5 165.5V230.5C95.5 247.069 108.931 260.5 125.5 260.5C142.069 260.5 155.5 247.069 155.5 230.5V165.5Z" :fill="detailColour" stroke="black"/>
<line x1="2" y1="282.5" x2="248" y2="282.5" stroke="black"/>
<line x1="2" y1="288.5" x2="248" y2="288.5" stroke="black"/>
<line x1="2" y1="456.5" x2="248" y2="456.5" stroke="black"/>
<line x1="2" y1="462.5" x2="248" y2="462.5" stroke="black"/>
</svg>




  </g>
</template>


<script>

import { get } from 'vuex-pathify';
export default {
  computed: {
    door: get("doors/door"),
    showFingerHandle: get("doors/showFingerHandle"),
    isLeftHanded: get("doors/isLeftHanded"),
    x() {
      return this.isLeftHanded ? 169 : 9
    },
    detailColour() {      
      switch(this.door.externalColour) {
        case "Red": return "#781f19"
        case "Blue": return "#0e294b"
        case "Green": return "#31372b"
        case "Black": return "#1d1d1d"
        case "White": return "#eee"
        default: return "#ccc"
      }
    }
  },
}
</script>
