<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <div v-if="loading">
      <v-skeleton-loader
        :loading="loading"
        type="card-heading, list-item@5"
        class="mb-4"
      ></v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        type="table-tbody"
      ></v-skeleton-loader>
    </div>
    <v-card v-else>
      <v-card-title class="pb-0">
        {{ forEdit ? "Edit Contract" : "Add Contract" }}
      </v-card-title>
      <v-card-text class="pt-0">
        <v-container class="pa-0">
          <v-row dense>
            <v-col sm="12" md="4">
              <BaseText label="Contract Name" v-model="contract.name" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <div v-if="forEdit">
        <v-card-title>
          Contract Documents
        </v-card-title>
        <v-simple-table v-if="contractsDocuments.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th>File Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in contractsDocuments" :key="file.url">
                <td>{{ file.fileName }}</td>
                <td style="width: 50px">
                  <v-btn small color="primary" @click="viewFile(file)">
                    View File
                  </v-btn>
                </td>
                <td style="width: 50px">
                  <v-btn small color="error" @click="removeFile(file)">
                    Remove
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>No Items</v-card-text>
        <v-card-text>
          <FileUploadButton
            upload-text="Add Contract Document"
            :upload-location="`contract-documents/${this.client.id}/`"
            @on:file="documentUploaded"
          />
        </v-card-text>
        <v-divider></v-divider>
      </div>
      <v-card-actions>
        <v-btn color="primary" :loading="saving" @click="saveAndNavigate">
          Save
        </v-btn>
        <v-btn color="error" :to="`/clients/${this.client.id}`">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";

import FileUploadButton from "@/components/files/file-upload-button";

export default {
  components: {
    FileUploadButton
  },
  data() {
    return {
      contract: {
        name: "",
        documents: []
      },
      forEdit: false,
      loading: true,
      saving: false,
      removing: false
    };
  },
  computed: {
    client: get("clients/selectedClient"),
    contractsDocuments: get("clients/contractDocuments"),
    breadcrumbs() {
      if (!this.client || !this.client.id) return [];

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Clients",
          to: "/Clients",
          exact: true
        },
        {
          text: this.client.name,
          to: `/Clients/${this.client.id}`,
          exact: true
        },
        {
          text: this.forEdit ? "Edit Contract" : "Add Contract"
        }
      ];
    }
  },
  async created() {
    try {
      await this.loadClient(this.$route.params.id);

      if (this.$route.params.contractId) {
        this.forEdit = true;
        const contractId = parseInt(this.$route.params.contractId);
        await this.loadContractDocuments(this.$route.params.contractId);

        this.contract = this.client.contracts.find(c => c.id === contractId);
      } else {
        this.forEdit = false;
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadClient: call("clients/loadClient"),
    saveContract: call("clients/saveContract"),
    loadContractDocuments: call("clients/loadContractDocuments"),
    saveContractDocument: call("clients/saveContractDocument"),
    removeContractDocument: call("clients/removeContractDocument"),
    async documentUploaded(file) {
      const data = {
        url: file.url,
        fileName: file.file.name,
        clientContractId: this.contract.id
      };

      await this.saveContractDocument(data);
    },
    async saveAndNavigate() {
      await this.saveContract(this.contract);
      this.$router.push(`/clients/${this.client.id}`);
    },
    async removeFile(file) {
      if (!confirm("Are you sure you want to delete this Contract Document?"))
        return;
      await this.removeContractDocument(file);
    },
    viewFile(file) {
      const index = this.contractsDocuments.indexOf(file);

      this.$router.push({ path: index.toString(), append: true });
    }
  }
};
</script>
