<template>
  <v-select
    :value="value"
    :items="items"
    :label="label"
    :disabled="disabled"
    :class="{ 'hide-error-template': hideErrorTemplate }"
    @change="e => $emit('input', e)"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: { type: [Number, String], default: "" },
    disabled: { type: Boolean, default: false },
    hideErrorTemplate: { type: Boolean, default: false },
    items: {
      type: Array,
      default() {
        return ["Yes", "No"];
      }
    }
  }
};
</script>

<style>
.hide-error-template .v-text-field__details {
  display: none;
}
</style>
