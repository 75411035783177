<template>
  <div v-if="order">
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="pt-2 pb-4 px-0">
    </v-breadcrumbs>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-card :loading="loading">
          <v-card-title v-if="!loading"> {{ order.reference }} </v-card-title>
          <v-skeleton-loader
            v-if="loading"
            :loading="loading"
            type="table-tbody,table-heading"
          ></v-skeleton-loader>
          <v-simple-table v-else>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th style="width: 200px">Reference</th>
                  <td style="width: 300px">{{ order.reference }}</td>
                </tr>
                <tr>
                  <th>Client</th>
                  <td>{{ order.clientName || "-" }}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td><OrderStatus :status="order.status" /></td>
                </tr>
                <tr>
                  <th>No. Of Doors</th>
                  <td>{{ orderPrices.noDoors }}</td>
                </tr>
                <tr>
                  <th>Door Costs</th>
                  <td>{{ orderPrices.doorChargeTotal | number("0,0.00") }}</td>
                </tr>
                <tr>
                  <th>Additional Costs</th>
                  <td>{{ orderPrices.quoteChargeTotal | number("0,0.00") }}</td>
                </tr>
                <tr>
                  <th>Total Cost</th>
                  <td>{{ orderPrices.totalCharge | number("0,0.00") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col>
        <v-skeleton-loader
          v-if="loading"
          :loading="loading"
          type="table-tbody"
        ></v-skeleton-loader>
        <AdditionalCosts
          v-else
          :saving="saving.order"
          :charges="orderPrices.orderCharges"
          :total="orderPrices.orderChargeTotal"
          :phases="phases"
          @on:saveCharge="saveOrderCharge"
          @on:removeCharge="removeOrderCharge"
        />
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="table-heading"
    ></v-skeleton-loader>
    <v-card
      v-else
      class="mb-2"
      v-for="(door, i) in orderPrices.doors"
      :key="door.id"
      :loading="saving.door"
    >
      <v-card-title>
        Door #{{ i + 1 }}
        <span class="pl-12">{{ door.total | number("0,0.00") }}</span>
        <!-- Do we want to have a delete function in the order price? -->
        <!-- <v-spacer></v-spacer>
        <v-btn color="error" small @click="removeDoor(door.id)"> Remove </v-btn> -->
      </v-card-title>
      <v-card-text class="pb-2">
        <v-container class="pa-0" fluid>
          <v-row dense>
            <v-col cols="5" class="pr-12">
              <DoorCharges
                :isOrder="true"
                @on:saveDoorCharge="saveDoorCharge"
                @on:removeDoorCharge="removeDoorCharge"
                :saving="saving.door"
                :line="door"
              />
            </v-col>
            <v-col cols="3">
              <img
                v-if="door.frameImageUrl"
                :src="door.frameImageUrl"
                class="pt-4"
                alt=""
                style="height: 400px"
              />
              <span v-else>
                <v-icon class="mr-2" color="blue">
                  mdi-alert-circle-outline
                </v-icon>
                No Frame Image Available
              </span>
            </v-col>
            <v-col cols="4">
              <table class="normal-table">
                <tr>
                  <th style="width: 150px">Frame Type</th>
                  <td>{{ door.frameType || "-" }}</td>
                </tr>
                <tr>
                  <th>Door Style</th>
                  <td>{{ door.doorStyle || "-" }}</td>
                </tr>
                <tr>
                  <th>Intended Fire Rating</th>
                  <td>{{ door.intendedFireRating || "-" }}</td>
                </tr>
                <tr>
                  <th>Frame Width</th>
                  <td>{{ door.width }}</td>
                </tr>
                <tr>
                  <th>Frame Height</th>
                  <td>{{ door.height }}</td>
                </tr>
                <tr>
                  <th>Door Thickness</th>
                  <td>{{ door.doorThickness }}</td>
                </tr>
                <tr>
                  <th>Threshold</th>
                  <td>{{ door.threshold }}</td>
                </tr>
                <tr>
                  <th>Image Last Updated</th>
                  <td>
                    {{ door.imageUpdated | euDateTime }}
                  </td>
                </tr>
              </table>

              <v-btn
                class="mt-4"
                small
                color="primary"
                :to="`/doors/${door.id}`"
              >
                Configure in Door Builder
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import AdditionalCosts from "@/components/order/order-charges";
import DoorCharges from "@/components/quotes/door-charges";
import OrderStatus from "@/components/order/order-status";
export default {
  components: {
    AdditionalCosts,
    DoorCharges,
    OrderStatus
  },
  data() {
    return {
      loading: true,
      saving: {
        door: false,
        order: false
      }
    };
  },
  async created() {
    await this.loadOrder(this.$route.params.id);
    await this.loadOrderPrices(this.$route.params.id);
    await this.loadPhases(this.$route.params.id);
    this.loading = false;
  },
  computed: {
    order: get("orders/order"),
    orderPrices: get("orders/orderPrices"),
    phases: get("phases/phases"),
    breadcrumbs() {
      if (!this.order) return [];
      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: this.order.reference,
          to: `/order/${this.order.id}`,
          exact: true
        },
        {
          text: "Set Prices"
        }
      ];
    }
  },
  methods: {
    loadOrder: call("orders/fetchOrder"),
    loadOrderPrices: call("orders/loadOrderPrices"),
    saveNewOrderCharge: call("orders/saveOrderCharge"),
    removeNewOrderCharge: call("orders/removeOrderCharge"),
    saveNewDoorCharge: call("doors/saveDoorCharge"),
    removeNewDoorCharge: call("doors/removeDoorCharge"),
    loadPhases: call("phases/loadPhases"),

    async saveDoorCharge(charge) {
      return this.saveDoor(async () => {
        await this.saveNewDoorCharge(charge);
        await this.loadOrderPrices(this.order.id);
      });
    },
    async removeDoorCharge(charge) {
      return this.saveDoor(async () => {
        await this.removeNewDoorCharge(charge);
        await this.loadOrderPrices(this.order.id);
      });
    },
    saveOrderCharge(charge) {
      return this.saveOrder(() => this.saveNewOrderCharge(charge));
    },
    removeOrderCharge(charge) {
      return this.saveOrder(() => this.removeNewOrderCharge(charge));
    },
    async saveOrder(fn) {
      try {
        this.saving.order = true;
        await fn();
      } finally {
        this.saving.order = false;
      }
    },
    async saveDoor(fn) {
      try {
        this.saving.door = true;
        await fn();
      } finally {
        this.saving.door = false;
      }
    }
  }
};
</script>
