<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        Installers
      </v-card-title>
      <v-card-text v-if="!installers.length">
        No Installers
      </v-card-text>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>BMTrada Certificate No.</th>
              <th>Active Fitters</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="installer in installers" :key="installer.id">
              <td>{{ installer.name }}</td>
              <td>{{ installer.certNo }}</td>
              <td>{{ installer.activeFitters }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-actions>
        <v-btn text color="red" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    };
  },
  props: {
    installers: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
