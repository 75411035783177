<template>
  <NarrowRow>
    <v-col cols="12" sm="6">
      <DoorDesignForm
        :door="door"
        :leafColours="leafColours"
        :internalColours="internalColours"
        :openOptions="openOptions"
        :closers="closers"
        :suiteColours="suiteColours"
        :lockOptions="lockOptions"
        :signageOptions="signageOptions"
        :kickPlates="kickPlates"
        :doorDesignErrors="doorDesignErrors"
        v-on="$listeners"
      />
    </v-col>
    <v-col cols="auto" class="ml-12">
      <BomImage :door="door" :image="frontImage" :showButton="true">
        <Panel :height="417" :width="202">
          <Door :door="door" />
        </Panel>
      </BomImage>
    </v-col>
  </NarrowRow>
</template>

<script>
import { get } from "vuex-pathify";
import { mixin } from "@gradys/vue-base/components/forms/mixins";
import BomImage from "@/components/images/bom-image.vue";
import DoorDesignForm from "@gradys/vue-base/components/forms/door-design-form";
import Door from "@gradys/vue-base/components/doors/door-frame-base";
import Panel from "@gradys/vue-base/components/frame/panel";

export default {
  components: { DoorDesignForm, Door, Panel, BomImage },
  mixins: [mixin],
  computed: {
    door: get("doors/door"),
    doorDesignErrors: get("doors/doorDesignErrors"),
    doorStyles: get("lookups/doorStyles"),
    leafColours: get("lookups/leafColours"),
    internalColours: get("lookups/internalColours"),
    openOptions: get("lookups/openOptions"),
    closers: get("lookups/closers"),
    suiteColours: get("lookups/suiteColours"),
    lockOptions: get("lookups/lockOptions"),
    signageOptions: get("lookups/signageOptions"),
    kickPlates: get("lookups/kickPlates"),
    frontImage: get("doors/frontImage")
  }
};
</script>
