<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        Add Client
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="name"
            label="Name"
            :rules="nameRules"
            required
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">
          Add Client
        </v-btn>
        <v-btn text color="red" @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      nameRules: [v => !!v || "Name is required"],
      dialog: false,
      loading: false
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.name = "";

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
          this.$refs.form.reset();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    save() {
      if (this.$refs.form.validate())
        this.$emit("on:save", { name: this.name });
    }
  }
};
</script>
