import { render, staticRenderFns } from "./frame-with-dimm-lines.vue?vue&type=template&id=64a7c779&scoped=true&"
import script from "./frame-with-dimm-lines.vue?vue&type=script&lang=js&"
export * from "./frame-with-dimm-lines.vue?vue&type=script&lang=js&"
import style0 from "./frame-with-dimm-lines.vue?vue&type=style&index=0&id=64a7c779&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a7c779",
  null
  
)

export default component.exports