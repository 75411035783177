<template>
  <g v-if="showDPullHandle">
    
<svg :x="x" y="181" width="12" height="60" viewBox="0 0 28 431" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_220_318)">
<path d="M82 561.5C82 583.868 63.8675 602 41.5 602C19.1325 602 1 583.868 1 561.5C1 539.132 19.1325 521 41.5 521C63.8675 521 82 539.132 82 561.5Z" fill="#8D8A85"/>
<path d="M56 521C56 528.732 49.732 535 42 535C34.268 535 28 528.732 28 521C28 513.268 34.268 507 42 507C49.732 507 56 513.268 56 521Z" fill="#8D8A85"/>
</g>
<path d="M82.5 561.5C82.5 544.084 71.6411 529.204 56.3246 523.262C56.4401 522.525 56.5 521.77 56.5 521C56.5 512.992 50.0081 506.5 42 506.5C33.9919 506.5 27.5 512.992 27.5 521C27.5 521.647 27.5423 522.283 27.6243 522.907C11.8093 528.594 0.5 543.727 0.5 561.5C0.5 584.144 18.8563 602.5 41.5 602.5C64.1437 602.5 82.5 584.144 82.5 561.5Z" stroke="black" stroke-opacity="0.5"/>
<g filter="url(#filter1_i_220_318)">
<rect x="28" width="28" height="431" rx="14" fill="#8D8A85"/>
<rect x="28" width="28" height="431" rx="14" fill="url(#paint0_linear_220_318)" fill-opacity="0.2"/>
</g>
<circle cx="41.5" cy="562.5" r="27" fill="#86837E" stroke="black"/>
<circle cx="41.5" cy="562.5" r="23" fill="#8D8A85" stroke="black"/>
<circle cx="41.5" cy="517.5" r="3" fill="#C4C4C4" stroke="black"/>
<defs>
<filter id="filter0_i_220_318" x="0" y="506" width="83" height="101" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_220_318"/>
</filter>
<filter id="filter1_i_220_318" x="28" y="-1" width="28" height="432" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="3" operator="erode" in="SourceAlpha" result="effect1_innerShadow_220_318"/>
<feOffset dy="-1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_220_318"/>
</filter>
<linearGradient id="paint0_linear_220_318" x1="42" y1="0" x2="42" y2="431" gradientUnits="userSpaceOnUse">
<stop offset="0.0186499" stop-color="white" stop-opacity="0"/>
<stop offset="0.11779" stop-color="white"/>
</linearGradient>
</defs>
</svg>

  </g>
</template>

<script>

import { get } from 'vuex-pathify';
export default {
  computed: {
    door: get("doors/door"),
    showDPullHandle: get("doors/showDPullHandle"),
    isLeftHanded: get("doors/isLeftHanded"),
    x() {
      return this.isLeftHanded ? 169 : 9
    }
  },
  
}
</script>
