<template>
  <v-card :loading="saving">
    <v-card-title>
      <v-container class="pa-0" fluid>
        <v-row dense>
          <v-col sm="12" md="3">Contracts</v-col>
          <v-col sm="12" md="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4" class="text-right">
            <v-btn
              :to="`/clients/${client.id}/add-contract`"
              color="primary"
              small
            >
              Add Contract
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
      :items="client.contracts"
      :items-per-page="5"
      :headers="headers"
      no-data-text="No Contracts"
      :search="search"
    >
      <template v-slot:item.isActive="{ item }">
        <v-icon
          v-if="item.isActive"
          color="success"
          @click="setActiveUser(item)"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else @click="setActiveUser(item)">
          mdi-checkbox-blank-outline
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mr-2" small color="primary" @click="goToContract(item)">
          View
        </v-btn>
        <v-btn small color="error" @click="remove(item)">
          Remove
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { call } from "vuex-pathify";
export default {
  props: {
    client: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      search: "",
      saving: false,
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Documents",
          value: "noDocuments"
        },
        {
          text: "",
          value: "actions",
          width: 210,
          align: "end",
          sortable: false
        }
      ]
    };
  },
  methods: {
    removeContract: call("clients/removeContract"),
    goToContract(contract) {
      const id = this.client.id;

      this.$router.push(`/clients/${id}/view-contract/${contract.id}`);
    },
    async remove(contract) {
      const ans = confirm("Are you sure you want to remove this Contract?");
      if (!ans) return;

      return this.removeContract(contract);
    }
  }
};
</script>
