<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <EditForm ref="form" @on:save="save" @on:delete="remove" />
    <div v-if="loading">
      <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
    </div>
    <v-card v-else>
      <v-card-title>
        Surveyors
        <v-btn class="ml-4" small color="primary" @click="add" data-cy="add">
          Add Surveyor
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!surveyors.length">
        No Surveyors
      </v-card-text>
      <v-simple-table v-else data-name="surveyor" class="click-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="surveyor in surveyors" :key="surveyor.id">
              <td>{{ surveyor.name }}</td>
              <td>{{ surveyor.email }}</td>
              <td>
                <v-btn small class="primary" @click="open(surveyor)"
                  >View</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import EditForm from "@/components/forms/surveyors/edit-form.vue";

export default {
  components: {
    EditForm
  },
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Surveyors",
          to: "/surveyors",
          exact: true
        }
      ]
    };
  },
  computed: {
    surveyors: get("surveyors/surveyors")
  },
  async created() {
    try {
      await this.loadSurveyors();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadSurveyors: call("surveyors/loadSurveyors"),
    removeSurveyor: call("surveyors/removeSurveyor"),
    saveSurveyor: call("surveyors/saveSurveyor"),
    add() {
      this.$refs.form.open();
    },
    open(sub) {
      this.$refs.form.open(sub);
    },
    async save(data) {
      this.$refs.form.setLoading(true);
      try {
        await this.saveSurveyor(data);
      } finally {
        this.$refs.form.setLoading(false);
        this.$refs.form.close();
      }
    },
    async remove(sub) {
      await this.removeSurveyor(sub);
      this.$refs.form.close();
    }
  }
};
</script>
