<template>
  <div class="text-center pt-10">
    <h2>You must login to view this page</h2>
    <v-divider class="my-6"></v-divider>
    <v-btn color="primary" @click="login">Login to Grady's Admin</v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      this.$auth.login();
    }
  }
};
</script>
