<template>
  <div class="panel" :class="{'joined-left': joinedLeft, 'joined-right': joinedRight }" :style="panelStyle">
    <div class="stile-top"></div>
    <div class="stile-left"></div>
    <div class="stile-right"></div>
    <div class="stile-bottom"></div>
    <div class="panel-elem">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    joinedLeft: {
      type: Boolean,
      default: false
    },
    joinedRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    panelStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: grid;
  grid-template-areas:
    "stile-top stile-top stile-top"
    "stile-left panel-elem stile-right"
    "stile-bottom stile-bottom stile-bottom";

  grid-template-columns: 5px auto 5px;
  grid-template-rows: 5px auto 5px;

  .stile-top {
    grid-area: stile-top;
  }
  .stile-left {
    grid-area: stile-left;
  }
  .stile-right {
    grid-area: stile-right;
  }
  .stile-bottom {
    grid-area: stile-bottom;
  }

  .panel-elem {
    grid-area: panel-elem;
    display: grid;
  }

  .stile-top,
  .stile-left,
  .stile-right,
  .stile-bottom {
    border: solid 1px #000;
  }

  .stile-left,
  .stile-right {
    border-top: none;
    border-bottom: none;
  }

}

.joined-left {
  .stile-top,
  .stile-bottom,
  .stile-left {
    border-left: none;    
  }
}

.joined-right {
  .stile-top,
  .stile-bottom,
  .stile-right {
    border-right: none;    

  }
}
</style>
