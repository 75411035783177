<template>
  <div>
    <v-select
      label="External Colour"
      :value="selectValue"
      :items="leafColours"
      @change="selectChanged"
    ></v-select>
    <v-text-field
      v-if="offStandard"
      :value="externalColour"
      label="Off Standard Colour"
      @change="textChanged"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    leafColours: {
      type: Array,
      default: () => []
    },
    externalColour: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      offStandard: false
    };
  },
  computed: {
    selectValue() {
      if (this.offStandard) return "Off Standard";

      return this.externalColour;
    }
  },
  watch: {
    externalColour(newVal) {
      this.determineOffStandard(newVal);
    }
  },
  mounted() {
    this.determineOffStandard(this.externalColour);
  },
  methods: {
    selectChanged(e) {
      this.$emit("on:colour-changed", e);
    },
    textChanged(e) {
      this.$emit("on:colour-changed", e);
    },
    determineOffStandard(externalValue) {
      if (externalValue === "Off Standard") {
        this.offStandard = true;
      } else {
        const matched = this.leafColours.find(c => c.value === externalValue);

        this.offStandard = !matched;
      }
    }
  }
};
</script>
