<template>
  <v-container class="pa-0">
    <div style="height: 5px">
      <v-progress-linear :active="saving" indeterminate></v-progress-linear>
    </div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="2"><strong>Top</strong></v-col>
      <v-col cols="2"><strong>Left</strong></v-col>
      <v-col cols="2"><strong>Right</strong></v-col>
      <v-col cols="2"><strong>Threshold</strong></v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">
        <div>
          Packer 1
        </div>
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topPacker1"
          :items="packers"
          :hide-error-template="true"
        />
        <ButtonSwitch v-model="packer1Applied" label="Apply to All" />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftPacker1"
          :disabled="packer1Applied"
          :items="packers"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightPacker1"
          :disabled="packer1Applied"
          :items="packers"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="thresholdPacker1"
          :disabled="packer1Applied"
          :items="thresholdPackers"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Packer 2</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topPacker2"
          :hide-error-template="true"
          :items="packers"
        />
        <ButtonSwitch v-model="packer2Applied" label="Apply to All" />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftPacker2"
          :disabled="packer2Applied"
          :items="packers"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightPacker2"
          :disabled="packer2Applied"
          :items="packers"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="thresholdPacker2"
          :disabled="packer2Applied"
          :items="thresholdPackers"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Liner Internal</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topLiningInternal"
          :hide-error-template="true"
          :items="liners"
        />
        <ButtonSwitch v-model="liningInternalApplied" label="Apply to All" />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftLiningInternal"
          :disabled="liningInternalApplied"
          :items="liners"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightLiningInternal"
          :disabled="liningInternalApplied"
          :items="liners"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Architrave 1 Internal</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topArchitraveInternal1"
          :hide-error-template="true"
          :items="architraves"
        />
        <ButtonSwitch
          v-model="architraveInternal1Applied"
          label="Apply to All"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftArchitraveInternal1"
          :disabled="architraveInternal1Applied"
          :items="architraves"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightArchitraveInternal1"
          :disabled="architraveInternal1Applied"
          :items="architraves"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Architrave 2 Internal</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topArchitraveInternal2"
          :hide-error-template="true"
          :items="architraves"
        />
        <ButtonSwitch
          v-model="architraveInternal2Applied"
          label="Apply to All"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftArchitraveInternal2"
          :disabled="architraveInternal2Applied"
          :items="architraves"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightArchitraveInternal2"
          :disabled="architraveInternal2Applied"
          :items="architraves"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Liner External</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topLiningExternal"
          :hide-error-template="true"
          :items="liners"
        />
        <ButtonSwitch v-model="liningExternalApplied" label="Apply to All" />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftLiningExternal"
          :disabled="liningExternalApplied"
          :items="liners"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightLiningExternal"
          :disabled="liningExternalApplied"
          :items="liners"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Architrave 1 External</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topArchitraveExternal1"
          :hide-error-template="true"
          :items="architraves"
        />
        <ButtonSwitch
          v-model="architraveExternal1Applied"
          label="Apply to All"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftArchitraveExternal1"
          :disabled="architraveExternal1Applied"
          :items="architraves"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightArchitraveExternal1"
          :disabled="architraveExternal1Applied"
          :items="architraves"
        />
      </v-col>
    </v-row>
    <v-row align="baseline" dense>
      <v-col cols="3">Architrave 2 External</v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="topArchitraveExternal2"
          :hide-error-template="true"
          :items="architraves"
        />
        <ButtonSwitch
          v-model="architraveExternal2Applied"
          label="Apply to All"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="leftArchitraveExternal2"
          :disabled="architraveExternal2Applied"
          :items="architraves"
        />
      </v-col>
      <v-col cols="2">
        <BaseSelect
          v-model="rightArchitraveExternal2"
          :disabled="architraveExternal2Applied"
          :items="architraves"
        />
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="6">
        <BaseSelect
          v-model="methodOfSealingOpening"
          label="Method of Sealing to Structural Opening"
          :items="methodsOfSealing"
        />
      </v-col>
    </v-row>
    <div style="height: 5px">
      <v-progress-linear :active="saving" indeterminate></v-progress-linear>
    </div>
  </v-container>
</template>

<script>
import { get, call } from "vuex-pathify";
import ButtonSwitch from "@gradys/vue-base/components/controls/button-switch";

function saveProp(prop) {
  return {
    [prop]: {
      get() {
        return this.door[prop];
      },
      async set(value) {
        this.updateDoor({ prop, value });
        this.packerChanged();
        try {
          this.saving = true;
          await this.saveDoor();
        } finally {
          this.saving = false;
        }
      }
    }
  };
}

export default {
  components: {
    ButtonSwitch
  },
  data: function() {
    return {
      saving: false
    };
  },
  computed: {
    door: get("doors/door"),
    ...saveProp("topPacker1"),
    ...saveProp("packer1Applied"),
    ...saveProp("leftPacker1"),
    ...saveProp("rightPacker1"),
    ...saveProp("thresholdPacker1"),
    ...saveProp("topPacker2"),
    ...saveProp("packer2Applied"),
    ...saveProp("leftPacker2"),
    ...saveProp("rightPacker2"),
    ...saveProp("thresholdPacker2"),
    ...saveProp("topLiningInternal"),
    ...saveProp("liningInternalApplied"),
    ...saveProp("leftLiningInternal"),
    ...saveProp("rightLiningInternal"),
    ...saveProp("topArchitraveInternal1"),
    ...saveProp("architraveInternal1Applied"),
    ...saveProp("leftArchitraveInternal1"),
    ...saveProp("rightArchitraveInternal1"),
    ...saveProp("topArchitraveInternal2"),
    ...saveProp("architraveInternal2Applied"),
    ...saveProp("leftArchitraveInternal2"),
    ...saveProp("rightArchitraveInternal2"),
    ...saveProp("topLiningExternal"),
    ...saveProp("liningExternalApplied"),
    ...saveProp("leftLiningExternal"),
    ...saveProp("rightLiningExternal"),
    ...saveProp("topArchitraveExternal1"),
    ...saveProp("architraveExternal1Applied"),
    ...saveProp("leftArchitraveExternal1"),
    ...saveProp("rightArchitraveExternal1"),
    ...saveProp("topArchitraveExternal2"),
    ...saveProp("architraveExternal2Applied"),
    ...saveProp("leftArchitraveExternal2"),
    ...saveProp("rightArchitraveExternal2"),
    ...saveProp("methodOfSealingOpening"),
    packers: get("lookups/packers"),
    architraves: get("lookups/architraves"),
    liners: get("lookups/liners"),
    methodsOfSealing: get("lookups/methodsOfSealing"),
    thresholdPackers: get("lookups/thresholdPackers")
  },
  methods: {
    updateDoor: call("doors/updateDoor"),
    saveDoor: call("doors/save"),
    packerChanged: call("doors/reEvaluatePackers")
  }
};
</script>
