<template>
  <div>
    <div v-if="surveySheet" style="max-heigth: 700px">
      <div v-if="isImage(file)">
        <v-img :src="file.fileUrl" max-heigth="650px" max-width="650px"></v-img>
      </div>
      <div v-else-if="isPdf(file)">
        <embed :src="file.fileUrl" width="95%" height="700px" />
      </div>
      <div v-else-if="file.fileUrl === ''" style="text-align: center;">
        <p>No survey sheet uploaded</p>
      </div>
    </div>
  </div>
</template>
<script>
import { isImage, isPdf } from "@gradys/vue-base/components/files/file-helpers";
import { storage } from "@/plugins/firebase";

export default {
  data() {
    return {
      debounce: null,
      file: {
        fileUrl: "",
        fileName: "",
      },
    };
  },
  props: {
    door: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    surveySheet() {
      if (!this.door || !this.door.files) return;

      return this.door.files.find(f => f.type === "Survey Sheet");
    }
  },
  watch: {
    surveySheet: {
      handler(file) {
        if (!file) return;

        clearTimeout(this.debounce);

        this.debounce = setTimeout(async () => {
          await this.downloadSurveySheet();
        }, 500);
      },
      immediate: true
    }
  },
  methods: {
    isImage,
    isPdf,
    async downloadSurveySheet() {
      if (!this.surveySheet || !this.surveySheet.fileUrl) return;
      
      const ref = storage.ref(this.surveySheet.fileUrl);
      const fileData = await ref.getMetadata();
      const extension = fileData.contentType.split("/")[1];
      const url = await ref.getDownloadURL();

      this.file.fileName = this.surveySheet.fileUrl + "." + extension;
      this.file.fileUrl = url;      
    },
  },
};
</script>
