<template>
  <v-app>
    <template v-if="layout === 'regular'">
      <Navbar
        :login-name="$auth.name"
        :is-authenticated="$auth.isAuthenticated"
        @logout="onLogoutClick"
        @login="onLoginClick"
      />
      <v-content class="grey lighten-4">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-content>
      <v-footer
        dark
        app
        color="blue darken-4"
        class="footer--amend"
        style="z-index: 5000"
      >
        <div class="flex-grow-1"></div>
        <div>&copy; {{ new Date().getFullYear() }}</div>
      </v-footer>
    </template>
    <template v-else-if="layout === 'full-page'">
      <Navbar
        :login-name="$auth.name"
        :show-nav="false"
        :is-authenticated="$auth.isAuthenticated"
        @login="onLoginClick"
      />
      <v-content class="grey lighten-4">
        <v-container>
          <router-view></router-view>
        </v-container>
      </v-content>
    </template>
    <PrintLayout v-else-if="layout === 'print-view'" />
    <template v-else-if="layout === 'blank'">
      <router-view></router-view>
    </template>
  </v-app>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import PrintLayout from "@/layouts/print-layout";
import rg4js from "raygun4js";

export default {
  data() {
    return {
      showRegularLayout: true,
      layout: ""
    };
  },
  components: {
    Navbar,
    PrintLayout
  },
  mounted() {
    if (!this.$auth) return;

    this.$auth.$watch("loading", loading => {
      if (loading === false && this.$auth.isAuthenticated) {
        const { email, sub } = this.$auth.getProfile();

        try {
          rg4js("setUser", {
            identifier: email,
            isAnonymous: false,
            email: email,
            uuid: sub
          });
        } catch {
          // do nothing
        }
      }
    });
  },
  methods: {
    onLoginClick() {
      this.$auth.login();
    },
    onLogoutClick() {
      this.$auth.logout();
    }
  },
  watch: {
    $route: {
      handler(route) {
        const layouts = route.matched
          .map(a => a.meta.layout)
          .filter(a => a !== undefined);

        const layout = layouts[layouts.length - 1];
        this.layout = layout;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
