<template>
  <v-navigation-drawer
    :value="drawer"
    app
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <v-list dense>
      <v-list-item name="link-dashboard" link to="/">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-dashboard" link to="/planned-production">
        <v-list-item-action>
          <v-icon>mdi-hammer-wrench</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Planned Production</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-group prepend-icon="mdi-fit-to-page-outline" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Installers</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item name="link-installers" link to="/fitters/installers">
          <v-list-item-title>Installers</v-list-item-title>
        </v-list-item>
        <v-list-item
          name="link-sub-contractors"
          link
          to="/fitters/sub-contractors"
          exact
        >
          <v-list-item-title>Sub Contractors</v-list-item-title>
        </v-list-item>
        <v-list-item
          name="link-bm-trada-companies"
          link
          to="/fitters/bm-trada-companies"
          exact
        >
          <v-list-item-title>BM Trada Companies</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item name="link-client" link to="/clients">
        <v-list-item-action>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Clients</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/surveyors">
        <v-list-item-action>
          <v-icon>mdi-head-check-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Surveyors</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/quotes">
        <v-list-item-action>
          <v-icon>mdi-account-cash</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Quotes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item name="link-client" link to="/batches">
        <v-list-item-action>
          <v-icon>mdi-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Batches</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/delivery-dockets">
        <v-list-item-action>
          <v-icon>mdi-clipboard-edit-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dockets</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/consignment">
        <v-list-item-action>
          <v-icon>mdi-truck</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Consignments</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/boxes">
        <v-list-item-action>
          <v-icon>mdi-package-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Boxes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item name="link-client" link to="/master-stock">
        <v-list-item-action>
          <v-icon>mdi-warehouse</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Master Stock</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/stock-configuration">
        <v-list-item-action>
          <v-icon>mdi-progress-wrench</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Stock Configuration</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item name="link-client" link to="/price-list">
        <v-list-item-action>
          <v-icon>mdi-currency-eur</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Price List</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["drawer"]
};
</script>
