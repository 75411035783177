<template>
  <div>
    <v-card class="d-print-none">
      <v-card-actions>
        <v-switch label="Use Overrides" v-model="useOverrides"></v-switch>
        <v-btn class="ml-2" color="primary" @click="print"
          >Print</v-btn
        ></v-card-actions
      >
    </v-card>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card class="section-to-print mt-2" v-else>
      <v-card-title class="justify-center">
        INVOICE FOR CUSTOMS PURPOSES ONLY
      </v-card-title>
      <v-card-title>{{ consignment.reference }}</v-card-title>
      <v-row>
        <v-col>
          <v-text-field
            style="max-width: 200px"
            class="ml-5"
            v-model="consignment.dispatchDate.split('T')[0]"
            label="Dispatch Date"
            type="date"
          />
        </v-col>
        <v-col> </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="4">
          <strong> Invoice to: </strong>
          <p>Grady Joinery UK Ltd</p>
          <p>Unit 6 NCR Business Centre</p>
          <p>Great Central Way</p>
          <p>London</p>
          <p>NW10 0AB</p>

          <p>VAT Number: GB 272071913</p>
          <p>EORI Number GB 272071913000</p>
        </v-col>

        <v-col cols="4">
          <strong>Exporter:</strong>
          <p>Grady Window Manufacturers Ltd</p>
          <p>Brackloonagh</p>
          <p>Charlestown</p>
          <p>Co Mayo</p>
          <p>F12 V5Y8</p>

          <p>VAT Number: IE9835298O</p>
          <p>EORI Number: IE9835298O</p>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                Number of Packages
              </th>
              <th>
                Description of Goods
              </th>
              <th>
                Intrastat Code
              </th>
              <th>
                Net Weight (Kgs)
              </th>
              <th>
                Price Per Unit
              </th>
              <th>
                Net Price
              </th>
              <th>
                Vat Rate
              </th>
            </tr>
          </thead>
          <tbody v-if="useOverrides">
            <tr v-if="manualFd30.quantity">
              <td>{{ manualFd30.quantity }}</td>
              <td>{{ manualFd30.type }} Fire Doors</td>
              <td>{{ manualFd30.intrastat }}</td>
              <td>{{ consignment.manualFD30TotalWeight }}</td>
              <td>
                £
                {{
                  (consignment.manualFD30TransferPrice / manualFd30.quantity)
                    | number("0,0.00")
                }}
              </td>
              <td>
                £{{ consignment.manualFD30TransferPrice | number("0,0.00") }}
              </td>
              <td>0%</td>
            </tr>
            <tr v-if="manualFd60.quantity">
              <td>{{ manualFd60.quantity }}</td>
              <td>{{ manualFd60.type }} Fire Doors</td>
              <td>{{ manualFd60.intrastat }}</td>
              <td>{{ consignment.manualFD60TotalWeight }}</td>
              <td>
                £{{
                  (consignment.manualFD60TransferPrice / manualFd60.quantity)
                    | number("0,0.00")
                }}
              </td>
              <td>
                £{{ consignment.manualFD60TransferPrice | number("0,0.00") }}
              </td>
              <td>0%</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2"><b>Net Total</b></td>
              <td colspan="2">£{{ netManual | number("0,0.00") }}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2"><b>Vat</b></td>
              <td colspan="2">£0</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2"><b>Gross</b></td>
              <td colspan="2">£{{ netManual | number("0,0.00") }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="summaryItem in costSummary.summaries"
              :key="summaryItem.id"
            >
              <td>{{ summaryItem.quantity }}</td>
              <td>{{ summaryItem.type }} Fire Doors</td>
              <td v-if="summaryItem.type == 'FD30'">{{ fd30Intrastat }}</td>
              <td v-else>{{ fd60Intrastat }}</td>
              <td>{{ summaryItem.weight }}</td>
              <td>
                £{{
                  (summaryItem.gjuk / summaryItem.quantity) | number("0,0.00")
                }}
              </td>
              <td>£{{ summaryItem.gjuk | number("0,0.00") }}</td>
              <td>0%</td>
            </tr>
            <tr></tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2"><b>Net Total</b></td>
              <td colspan="2">
                £{{ costSummary.doorPriceTotal | number("0,0.00") }}
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2"><b>Vat</b></td>
              <td colspan="2">£0</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2"><b>Gross</b></td>
              <td colspan="2">
                £{{ costSummary.doorPriceTotal | number("0,0.00") }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row>
        <v-col cols="6">
          <v-simple-table>
            <tbody>
              <tr>
                <th>Shipping Route</th>
                <td>{{ consignment.proposedShippingRoute }}</td>
              </tr>
              <tr>
                <th>Container</th>
                <td>{{ consignment.container }}</td>
              </tr>
              <tr>
                <th>Lorry Registration</th>
                <td>{{ consignment.lorryRegistration }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-card-title class="justify-center">
        INVOICE FOR CUSTOMS PURPOSES ONLY
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
import { get, call } from "vuex-pathify";
export default {
  computed: {
    consignment: get("consignments/consignment"),
    costSummary: get("consignments/costSummary"),
    manualFd30() {
      if (!this.costSummary) return {};
      const doorSummary = this.costSummary.summaries.find(
        x => x.type == "FD30"
      );
      if (!doorSummary) return {};
      doorSummary.intrastat = this.fd30Intrastat;
      doorSummary.weight = this.consignment.manualFd30TotalWeight;
      doorSummary.price = this.consignment.manualFd30TransferPrice;

      return doorSummary;
    },
    manualFd60() {
      if (!this.costSummary) return {};
      const doorSummary = this.costSummary.summaries.find(
        x => x.type == "FD60"
      );
      if (!doorSummary) return {};
      doorSummary.intrastat = this.fd60Intrastat;
      doorSummary.weight = this.consignment.manualFd60TotalWeight;
      doorSummary.price = this.consignment.manualFd60TransferPrice;

      return doorSummary;
    },
    netTotal() {
      if (!this.costSummary) return 0;
      return this.costSummary.reduce((a, b) => a + b.clientPrice, 0);
    },
    netManual() {
      if (!this.consignment) return 0;
      return (
        this.consignment.manualFD60TransferPrice +
        this.consignment.manualFD30TransferPrice
      );
    }
  },
  data() {
    return {
      loading: true,
      useOverrides: true,
      fd30Intrastat: "44182010",
      fd60Intrastat: "44182010",
      manualOverrideSummarys: []
    };
  },
  async created() {
    await this.loadConsignments(this.$route.params.id);
    await this.loadCostSummary(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    loadConsignments: call("consignments/loadConsignment"),
    loadCostSummary: call("consignments/loadCostSummary"),
    print() {
      window.print();
    }
  }
};
</script>
