var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Consignment")]),_c('v-card-text',[_c('ConsignmentForm',{attrs:{"consignment":_vm.consignment}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.saving},on:{"click":_vm.saveConsignment}},[_vm._v("Save")]),_c('v-btn',{on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")])],1)],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Consignments "),_c('v-btn',{staticClass:"ml-12",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.showDialog = true}}},[_vm._v("Add New Consignment")])],1),_c('v-data-table',{staticClass:"click-table",attrs:{"items":_vm.store.consignments,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.weight,"0,0.0"))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.price,"0,0.00"))+" ")]}},{key:"item.dispatchDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.dispatchDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openConsignment(item)}}},[_vm._v(" View ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }