<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="contractor.name"
            label="Name"
            :rules="nameRules"
            data-cy="name"
            required
          />
          <v-text-field
            v-model="contractor.email"
            label="Email"
            data-cy="email"
            :rules="emailRules"
          />
          <BaseText v-model="contractor.phone" label="Phone" data-cy="phone" />
          <v-textarea
            v-model="contractor.address"
            label="Address"
            data-cy="address"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :loading="loading"
          @click="save"
          data-cy="saveContractor"
        >
          Save
        </v-btn>
        <v-btn text color="red" @click="close" data-cy="cancelSave">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-show="canDelete"
          color="error"
          class="text-right"
          @click="remove"
          data-cy="deleteContractor"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      contractor: {},
      nameRules: [v => !!v || "Name is required"],
      emailRules: [v => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
      dialog: false,
      loading: false
    };
  },
  computed: {
    canDelete() {
      return this.contractor.id;
    },
    formTitle() {
      return this.contractor.id ? "Edit Sub Contractor" : "Add Sub Contractor";
    }
  },
  methods: {
    remove() {
      const ans = confirm(
        "Are you sure you want to delete this Sub Contractor"
      );
      if (ans) {
        this.$emit("on:delete", this.contractor);
      }
    },
    open(contractor) {
      this.dialog = true;
      this.contractor = { ...contractor };

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    save() {
      if (this.$refs.form.validate()) this.$emit("on:save", this.contractor);
    }
  }
};
</script>
