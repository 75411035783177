<template>
  <div>
    <v-container class="py-0">
      <div><strong>Survey Files</strong></div>
      <v-row>
        <v-col>
          <BaseText v-model="numerals" label="Numerals" />
        </v-col>
        <v-col>
          <BaseText v-model="postCode" label="Post Code" />
        </v-col>
        <v-col>
          <BaseText v-model="block" label="Block" />
        </v-col>
        <v-col>
          <BaseText v-model="location" label="Location" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <BaseText v-model="address" label="Address" />
        </v-col>
      </v-row>
      <v-row
        ><v-col cols="12" sm="4"
          ><v-checkbox
            class="ma-0 pa-0"
            v-model="surveySubmitted"
            label="Survey Completed"
          ></v-checkbox></v-col
      ></v-row>

      <v-text-field
        class="ma-0 pa-0"
        :loading="fileLoading"
        height="0"
        dense
        disabled
      ></v-text-field>
      <FileUploadsByType
        :files="door.files"
        @on:file="fileUploaded"
        @on:file-removed="removeFile"
      />
      <v-text-field
        class="mb-12 pa-0"
        :loading="fileLoading"
        height="0"
        dense
        disabled
      ></v-text-field>
      <v-row>
        <v-col cols="4">
          <div class="mb-4">
            <strong> Existing Door (Front)</strong>
          </div>
          <CurrentImage class="mb-2" :image-url="door.frontImageUrl" />
          <FileUploadButton
            upload-text="Upload Front Image"
            upload-location="images/picture"
            accept="image/*"
            @on:file="file => imageUploaded(file, 'front')"
          />
        </v-col>
        <v-col></v-col>
        <v-col cols="4">
          <div class="mb-4">
            <strong> Existing Door (Back)</strong>
          </div>
          <CurrentImage class="mb-2" :image-url="door.backImageUrl" />
          <FileUploadButton
            upload-text="Upload Back Image"
            upload-location="images/picture"
            accept="image/*"
            @on:file="file => imageUploaded(file, 'back')"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";

import CurrentImage from "@/components/images/current-image";
import FileUploadsByType from "@/components/files/file-uploads-by-type";
import FileUploadButton from "@/components/files/file-upload-button";
import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";

export default {
  data() {
    return {
      fileLoading: false
    };
  },
  mixins: [mixin],
  components: {
    CurrentImage,
    FileUploadsByType,
    FileUploadButton
  },
  computed: {
    door: get("doors/door"),
    ...syncProp("postCode"),
    ...syncProp("address"),
    ...syncProp("block"),
    ...syncProp("location"),
    ...syncProp("numerals"),
    ...syncProp("surveySubmitted")
  },
  methods: {
    updateDoorAsync: call("doors/updateDoorAsync"),
    addDoorFile: call("doors/addDoorFile"),
    removeDoorFile: call("doors/removeDoorFile"),
    async imageUploaded(file, side) {
      await this.updateDoorAsync({
        prop: `${side}ImageUrl`,
        value: file.url
      });
    },
    async fileUploaded(file) {
      try {
        this.fileLoading = true;
        await this.addDoorFile(file);
      } finally {
        this.fileLoading = false;
      }
    },
    async removeFile(file) {
      try {
        this.fileLoading = true;

        await this.removeDoorFile(file);
      } finally {
        this.fileLoading = false;
      }
    }
  }
};
</script>
