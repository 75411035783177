<template>
  <div>
    <DoorBreadcrumbs
      :order="order"
      pageName="Assign Doors"
      :allow-switch="false"
    />
    <v-card class="mt-2">
      <v-card-title>Select Doors</v-card-title>
      <v-card-subtitle>to assign to delivery docket </v-card-subtitle>
      <v-row class="ml-2">
        <v-col cols="3">
          <v-select
            item-value="id"
            item-text="reference"
            v-model="deliveryDocketId"
            clearable
            label="Production Delivery Docket Number"
            :items="deliveryDockets"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.reference }}
            </template></v-select
          >
        </v-col>
        <v-col>
          <v-card-actions>
            <v-btn
              color="success"
              @click="assignDoors"
              :loading="assigning"
              :disabled="validationMet"
            >
              {{ assignButtonText }}
            </v-btn>
            <v-checkbox
              class="ml-4"
              v-model="showDocketedDoors"
              label="Show Docketed Doors"
            ></v-checkbox>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        :items="doors"
        :headers="headers"
        disable-pagination
        show-select
      />
    </v-card>
    <v-dialog v-model="assigning" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Assigning Doors
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { get, call } from "vuex-pathify";
import DoorBreadcrumbs from "@gradys/vue-base/components/door/door-breadcrumbs";

export default {
  components: {
    DoorBreadcrumbs
  },
  data() {
    return {
      deliveryDocketId: null,
      loading: true,
      assigning: false,
      assigned: false,
      assignError: false,
      selected: [],
      showDocketedDoors: false,
      headers: [
        {
          text: "Numeral",
          value: "numerals"
        },
        {
          text: "Address",
          value: "address"
        },
        {
          text: "Delivery Docket",
          value: "deliveryDocketReference"
        },
        {
          text: "Post Code",
          value: "postCode"
        },
        {
          text: "Block",
          value: "block"
        },
        {
          text: "Location",
          value: "location"
        },
        {
          text: "Floor",
          value: "floor"
        },
        {
          text: "Frame Width",
          value: "width"
        },
        {
          text: "Frame Height",
          value: "height"
        },
        {
          text: "Frame Type",
          value: "frameType"
        }
      ]
    };
  },
  computed: {
    order: get("orders/order"),
    filteredDoors: get("orders/doorsWithOutDeliveryDockets"),
    unfilteredDoors: get("orders/doors"),
    deliveryDockets: get("deliveryDockets/unlockedDeliveryDockets"),
    doors() {
      if (this.showDocketedDoors) return this.unfilteredDoors;
      return this.filteredDoors;
    },
    itemsSelected() {
      return this.selected && this.selected.length;
    },
    assignButtonText() {
      if (this.validationMet) return "Assign to Delivery Docket";
      return `Assign Doors to delivery docket `;
    },
    validationMet() {
      return !this.itemsSelected || !this.deliveryDocketId;
    }
  },
  created() {
    return this.load();
  },
  methods: {
    fetchOrder: call("orders/fetchOrder"),
    fetchDoors: call("orders/fetchDoors"),
    loadDeliveryDockets: call("deliveryDockets/loadDeliveryDockets"),
    assignMultipleDoorsToDeliveryDocket: call(
      "doors/assignMultipleDoorsToDeliveryDocket"
    ),
    async load() {
      this.loading = true;
      const orderId = this.$route.params.id;
      try {
        await this.fetchOrder(orderId);
        await this.fetchDoors(orderId);
        await this.loadDeliveryDockets();
      } finally {
        this.loading = false;
      }
    },
    async assignDoors() {
      this.assigning = true;
      this.assignError = false;
      const ids = this.selected.map(door => door.id);
      try {
        await this.assignMultipleDoorsToDeliveryDocket({
          foreignId: this.deliveryDocketId,
          ids: ids
        });
        await this.load();
        this.assigned = true;

        setTimeout(() => {
          this.assigning = false;
        }, 3000);
      } catch {
        this.assignError = true;
      } finally {
        this.assigning = false;
        this.selected = [];
      }
    }
  }
};
</script>
