<template>
  <div class="sheet fire-cert padding-10mm">
    <div class="cert--header">
      <div class="grady-logo">
        <img src="@/assets/fire-cert-images/new-grady-logo.png" />
      </div>
      <div class="bm-trada-logo text-right">
        <img src="@/assets/fire-cert-images/q-mark-certification.jpg" />
      </div>
      <div class="ribbon-logo text-right">
        <img style="width: 150px;" src="@/assets/fire-cert-images/ribbon.jpg" />
      </div>
      <div class="cert--header-details">
        <div class="headline">
          Certificate of Q Mark Compliance
        </div>
        <div class="title green lighten-3 pa-2 mt-2">
          Installed by Grady Joinery (UK) Ltd
        </div>
        <table class="header-table-details">
          <tr>
            <th>Approved Installer Name</th>
            <td>{{ fireCert.installerName }}</td>
          </tr>
          <tr>
            <th>Approved Installer Number</th>
            <td>{{ fireCert.installerCertNo }}</td>
          </tr>
          <tr>
            <th>Installation Ref</th>
            <td>{{ fireCert.fireCertInstallationReference }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="subtitle-2">
      Site Address
    </div>
    <div class="subtitle-1">
      {{ fireCert.address }}, {{ fireCert.postCode }}
    </div>
    <table class="details--table mt-2">
      <thead>
        <tr>
          <th>Location</th>
          <th>Floor</th>
          <th>Block</th>
          <th>Intended Fire Resistance Period</th>
          <th>Installed By</th>
          <th>Installed Date</th>
          <th>Silver Plug Fitted</th>
          <th>Installer Guide Used</th>
          <th>Gold Installer Plug Fitted</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ fireCert.location }}</td>
          <td>{{ fireCert.floor }}</td>
          <td>{{ fireCert.block }}</td>
          <td>{{ fireCert.intendedFireRating }}(s)</td>
          <td>{{ fitterInitials }}</td>
          <td>{{ fireCert.actualFittedDate | euDate }}</td>
          <td>{{ fireCert.certifiedDoorSet }}</td>
          <td>{{ fireCert.installerGuideUsed }}</td>
          <td>{{ fireCert.installerPlugFitted }}</td>
        </tr>
      </tbody>
    </table>
    <div class="subtitle-1 mt-2">
      BM Trada Installer Notes
    </div>
    <div class="caption boxed">
      {{ fireCert.installerComment }}
    </div>
    <div class="subtitle-1 mt-2">
      Declaration
    </div>
    <div class="caption boxed">
      I declare that the work undertaken fully complies with the requirements of
      the Q-Mark Fire Door Installation Scheme and that the work undertaken
      complies with Regulation 4 & 7 of the building Regulations for England &
      Wales or in accordance with the local regulations in force at the job site
      location.
    </div>
    <div class="boxed signature">
      <div class="signature-label">Signed</div>
      <div class="signature-space pa-1">
        <div class="signature-image" v-if="fireCert.installerSignatureUrl">
          <img :src="fireCert.installerSignatureUrl" />
          <div class="pa-2 caption date-stamp">
            <!-- {{ fitter.generated.date | euDate }} -->
          </div>
        </div>
      </div>
    </div>
    <div class="caption boxed">
      This Certificate is valid at the time of generation. Should there be any
      additions made, removal of any parts or materials, penetrations through
      either the leaf or frame, or any maintenance as part of scheduled
      inspections, THIS CERTIFICATE BECOMES VOID.
    </div>
    <div class="grady-footer mt-4 text-center">
      <img src="@/assets/fire-cert-images/grady-footer.png" />
    </div>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";

export default {
  layout: "blank",
  data() {
    return {
      loading: true
    };
  },
  computed: {
    fireCert: get("doors/fireCertDetails"),
    fitterInitials() {
      if (!this.fireCert.fitterName) return " ";
      return this.fireCert.fitterName
        .split(" ")
        .map(n => n[0])
        .join(".");
    }
  },
  async created() {
    try {
      await this.fetchFireCertDetails(this.$route.params.doorId);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    fetchDoor: call("doors/fetchDoor"),
    fetchFireCertDetails: call("doors/fetchFireCertDetails"),
    print() {
      window.print();
    }
  }
};
</script>

<style scoped>
.fire-cert {
  font-family: Arial, Helvetica, sans-serif;
}
.cert--header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "grady-logo company-logo ribbon"
    "header-details header-details ribbon";
}
.grady-logo {
  grid-area: grady-logo;
  padding-right: 10px;
}
.bm-trada-logo {
  grid-area: company-logo;
}
.bm-trada-logo img {
  height: 90px;
}
.grady-logo img {
  max-width: 240px;
}
.ribbon-logo {
  grid-area: ribbon;
  padding-left: 10px;
}
.cert--header-details {
  grid-area: header-details;
}
.header-table-details {
  font-size: 0.8em;
  width: 100%;
  border: solid 1px #ccc;
  border-bottom: none;
}
.header-table-details th {
  text-align: left;
  border-right: solid 1px #ccc;
  padding-right: 8px;
}
.header-table-details th,
.header-table-details td {
  border-bottom: solid 1px #ccc;
  padding: 4px;
}
.details--table {
  font-size: 0.8em;
  width: 100%;
  border: solid 1px #ccc;
  border-right: none;
}
.details--table th {
  border-bottom: solid 1px #ccc;
}
.details--table th,
.details--table td {
  text-align: left;
  border-right: solid 1px #ccc;
  padding: 4px;
  vertical-align: top;
}
.boxed {
  padding: 4px;
  border: solid 1px rgb(49, 49, 49);
  min-height: 2rem;
}
.signature {
  border-top: none;
  display: flex;
  padding: 0;
}
.signature-label {
  padding: 10px;
  border-right: solid 1px rgb(49, 49, 49);
  padding-right: 10px;
}
.signature-space {
  flex: 1;
}
.signature-image {
  display: flex;
  width: 100%;
}
.signature-image .date-stamp {
  text-align: right;
  font-weight: bold;
  flex: 1;
}
.signature-image img {
  max-width: 600px;
  max-height: 100px;
}
.grady-footer img {
  max-width: 100%;
}
</style>
