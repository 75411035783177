<template>
  <div class="mt-4 sheet section-to-print">
    <v-dialog v-model="dialog" max-width="600">
      <v-card v-if="item">
        <v-card-title>Add Extra Item</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field label="Winsys Code" v-model="item.winSysStockCode" />
            <v-text-field label="Description" v-model="item.stockDescription" />
            <NarrowRow>
              <v-col cols="6">
                <v-text-field label="Length" v-model.number="item.length" />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Item Price"
                  v-model.number="item.winSysPrice"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field label="Units" v-model.number="item.units" />
              </v-col>
              <v-col cols="6">
                <v-text-field label="Category" v-model="item.categoryName" />
              </v-col>
              <v-col cols="12">
                <v-textarea label="Notes" v-model="item.notes"> </v-textarea>
              </v-col>
            </NarrowRow>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveItem" :loading="saving">
            Save
          </v-btn>
          <v-btn outlined color="error" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <div>Measured Parts List</div>
    </v-card-title>
    <v-card-actions>
      <BomSwitch :door="door"></BomSwitch>
      <v-btn
        :disabled="bomAutoUpdate"
        small
        color="success"
        class="ml-2"
        @click="addItem"
      >
        Add Extra Item
      </v-btn>
    </v-card-actions>
    <v-card-subtitle class="pt-2 pb-0 caption"> </v-card-subtitle>
    <v-simple-table class="narrow-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Winsys Code</th>
            <th>Description</th>
            <th>Length</th>
            <th>Units</th>
            <th>Winsys Cost</th>
            <th>Weight</th>
            <th v-if="billOfMaterials.savedList.length">State</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="list in groupedBillOfMaterialItems" :key="list.id">
          <tr>
            <td colspan="8" class="caption grey lighten-3">
              {{ list[0].categoryName }}
            </td>
          </tr>
          <template v-if="list">
            <tr
              v-for="(item, index) in list"
              :key="`${item.id}_${item.winSysCost}_${index}`"
              :class="rowClass(item)"
            >
              <td>{{ item.winSysStockCode }}</td>
              <td>
                {{ item.stockDescription }}
                <p v-if="item.notes" style="color:blue;" class="px-0">
                  {{ item.notes }}
                </p>
              </td>
              <td>{{ item.length || "-" }}</td>
              <td>{{ item.units }}</td>
              <td>{{ item.winSysCost }}</td>
              <td>{{ item.calculatedWeight | number("0,0.00") }}</td>
              <td v-if="billOfMaterials.savedList.length">{{ item.state }}</td>
              <td v-if="billOfMaterials.savedList.length">
                <v-btn
                  :disabled="bomAutoUpdate"
                  @click="editItem(item)"
                  icon
                  color="warning darken-1"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  :disabled="bomAutoUpdate"
                  icon
                  color="red"
                  @click="removeItem(item.id)"
                >
                  <v-icon>mdi-close-box-outline</v-icon>
                </v-btn>
              </td>
              <td v-else>
                <v-btn icon color="warning darken-2" :disabled="true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="red" :disabled="true">
                  <v-icon>mdi-close-box-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { call, get } from "vuex-pathify";
import BomSwitch from "@/components/review/bom/bom-toggle-auto-update.vue";

export default {
  components: {
    BomSwitch
  },
  props: {
    door: {
      type: Object,
      default() {
        return {};
      }
    },
    billOfMaterials: {
      type: Object,
      default() {
        return {};
      }
    },
    bomAutoUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: null,
      dialog: false,
      saving: false
    };
  },
  computed: {
    groupedBillOfMaterialItems: get(
      "billOfMaterials/measuredBillOfMaterialItems"
    )
  },
  methods: {
    save: call("billOfMaterials/saveItem"),
    remove: call("billOfMaterials/removeItem"),
    loadBillOfMaterials: call("billOfMaterials/loadBillOfMaterials"),
    disableBOMUpdates: call("doors/disableBOMUpdates"),
    enableBOMUpdates: call("doors/enableBOMUpdates"),
    updateDoorAsync: call("doors/updateDoorAsync"),

    rowClass(item) {
      if (item.state === "New") return "green lighten-4";
      if (item.state === "Edited") return "orange lighten-4";

      return "";
    },

    editItem(item) {
      this.item = cloneDeep(item);
      this.dialog = true;
    },
    removeItem(id) {
      const ans = confirm("Are you sure you want to remove this item?");

      if (ans) return this.remove(id);
    },
    async saveItem() {
      try {
        this.saving = true;
        await this.save(this.item);
        this.dialog = false;
      } finally {
        this.saving = false;
      }
    },
    addItem() {
      this.item = {
        billOfMaterialId: this.billOfMaterials.id,
        winSysStockCode: "",
        stockDescription: "",
        winSysPrice: 0,
        units: 1,
        length: 0,
        state: "",
        factoryFitted: true
      };

      this.dialog = true;
    }
  }
};
</script>
