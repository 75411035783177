<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <AddClientForm ref="form" @on:save="newClient" />
    <v-card :loading="loading">
      <v-card-title>
        Clients
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="add" data-cy="add">
          Add Client
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="clients"
        :items-per-page="-1"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn class="mr-2" small color="primary" @click="itemClicked(item)">
            View
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import AddClientForm from "@/components/forms/clients/add-client-form";

export default {
  components: {
    AddClientForm
  },
  data() {
    return {
      loading: true,
      search: "",
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Contracts",
          value: "noContracts"
        },
        {
          text: "Delivery Addresses",
          value: "noAddresses"
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false
        }
      ],
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Clients"
        }
      ]
    };
  },
  computed: {
    clients: get("clients/clients")
  },
  async created() {
    try {
      await this.loadClients();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadClients: call("clients/loadClients"),
    saveNewClient: call("clients/newClient"),
    itemClicked(client) {
      this.$router.push(`/clients/${client.id}`);
    },
    add() {
      this.$refs.form.open();
    },
    async newClient(data) {
      this.$refs.form.setLoading(true);

      try {
        const result = await this.saveNewClient(data);
        this.$router.push(`/clients/${result.id}`);
      } finally {
        this.$refs.form.setLoading(false);
        this.$refs.form.close();
      }
    }
  }
};
</script>
