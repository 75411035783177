<template>
  <div v-if="order && door">
    <ul class="v-breadcrumbs pa-0 theme--light">
      <li>
        <router-link to="/" class="v-breadcrumbs__item">Dashboard</router-link>
      </li>
      <li class="v-breadcrumbs__divider">></li>
      <li>
        <router-link :to="`/order/${order.id}`" class="v-breadcrumbs__item">
          Order {{ order.reference || "" }}
        </router-link>
      </li>
      <li class="v-breadcrumbs__divider">></li>
      <li>
        <v-select
          v-if="allowSwitch"
          :items="doorOptions"
          :label="pageName"
          :value="door.id"
          @change="navigate"
        />
        <span v-else>{{ pageName }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { getDoorName } from './door-naming';

export default {
  props: {
    door: {
      type: Object,
      default() {
        return {};
      },
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    pageName: {
      type: String,
      default: "",
    },
    allowSwitch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    doors: get("orders/doors"),
    doorOptions() {
      if (!this.doors || !this.doors.length) return [];

      return this.doors.map((d) => {
        return {
          text: getDoorName(d),
          value: d.id,
        };
      });
    },
  },
  watch: {
    order: {
      immediate: true,
      handler: "loadDoors",
    },
  },
  methods: {
    loadDoors() {
      if (!this.order) return;

      this.$store.dispatch("orders/fetchDoors", this.order.id);
    },
    async navigate(id) {
      const newUrl = this.$route.path.replace(this.door.id, id);

      try {
        await this.$router.push(newUrl);
        // this.$router.go();
      } catch (e) {
        console.error("ERROR NAVIGATION");
        console.error(e);
      }
    },
  },
};
</script>
