<template>
  <div>
    <div v-if="showSelect" class="jamb__select">
      <v-select v-model="jambThickness1" label="Jamb Thickness" :items="useableJambs" no-data-text="No Jambs Available for Door Width" />
    </div>
    <div>
      <div v-if="imageSrc === ''" class="no_image">
        Select Jamb Thickness
      </div>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 700 370"
        width="100%"
      >
        <image :xlink:href="imageSrc" class="blue" width="700px" height="370px" />
        <text font-size="8" :transform="transform1">
          {{ jambThickness1 }}
        </text>
        <text font-size="8" :transform="transform2">
          {{ jambThickness1 }}
        </text>
        <text font-size="8" :transform="transform3">
          {{ jambThickness1 }}
        </text>
        <text font-size="8" :transform="transform4">
          {{ jambThickness1 }}
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";
import {get } from "vuex-pathify"

const transforms = {
  "left__in": {
    "1": "translate(50 322)",
    "2": "translate(290 320)",
    "3": "translate(415 322)",
    "4": "translate(655 324)"
  },
  "left__out": {
    "1": "translate(35 42)",
    "2": "translate(285 42)",
    "3": "translate(415 42)",
    "4": "translate(655 44)"
  },
  "right__in": {
    "1": "translate(45 325)",
    "2": "translate(290 324)",
    "3": "translate(380 323)",
    "4": "translate(628 316)"
  },
  "right__out": {
    "1": "translate(45 50)",
    "2": "translate(292 50)",
    "3": "translate(382 51)",
    "4": "translate(632 51)"
  },
};

export default {
  mixins: [mixin],
  props: {
    showSelect: {
      type: Boolean,
      default: true,
    },
    door: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...syncProp("jambThickness1"),
    useableJambs:get("doors/useableJambs"),
    img() {
      if (!this.door) return "";
      const { doorHanding, opens } = this.door;
      if (!doorHanding || !opens) return "";

      return `${doorHanding.toLowerCase()}__${opens.toLowerCase()}`;
    },
    imageSrc() {
      const images = require.context(
        "@/assets/jamb-options/new-images",
        false,
        /\.png$/
      );

      try {
        return images(`./${this.img}.png`);
      } catch {
        return "";
      }
    },
    transform1() {
      return transforms[this.img]["1"];
    },
    transform2() {
      return transforms[this.img]["2"];
    },
    transform3() {
      return transforms[this.img]["3"];
    },
    transform4() {
      return transforms[this.img]["4"];
    }
  },
};
</script>

<style scoped>
.jamb__select {
  max-width: 400px;
}
</style>
