<template>
  <NarrowRow>
    <v-col cols="6" md="3">
      <v-select
        label="Frame Finish"
        :value="selectValue"
        :items="frameFinishes"
        @change="selectChanged"
      ></v-select>
    </v-col>
    <v-col cols="6" md="3">
      <v-text-field
        v-if="offStandard"
        :value="frameFinish"
        label="Off Standard Colour"
        @change="textChanged"
      >
      </v-text-field>
    </v-col>
  </NarrowRow>
</template>

<script>
export default {
  props: {
    frameFinishes: {
      type: Array,
      default: () => [],
    },
    frameFinish: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      offStandard: false,
    };
  },
  computed: {
    selectValue() {
      if (this.offStandard) return "Off Standard";

      return this.frameFinish;
    },
  },
  watch: {
    frameFinish(newVal) {
      this.determineOffStandard(newVal);
    },
  },
  mounted() {
    this.determineOffStandard(this.frameFinish);
  },
  methods: {
    selectChanged(e) {
      this.$emit("on:colour-changed", e);
    },
    textChanged(e) {
      this.$emit("on:colour-changed", e);
    },
    determineOffStandard(frameFinish) {
      if (frameFinish === "Off Standard") {
        this.offStandard = true;
      } else {
        const matched = this.frameFinishes.find((c) => c.value === frameFinish);

        this.offStandard = !matched;
      }
    },
  },
};
</script>
