var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.store.batches,"no-data-text":"No batches, dockets or consignments found"},scopedSlots:_vm._u([{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.dueDate))+" ")]}},{key:"item.plannedDespatchDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.plannedDespatchDate))+" ")]}},{key:"item.requestedFittingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.requestedFittingDate))+" ")]}},{key:"item.requestedDeliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.requestedDeliveryDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":("/batches/" + (item.id))}},[_vm._v(" Go to Batch")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }