<template>
  <div>
    <v-container class="pa-0">
      <v-row dense>
        <v-col cols="12" sm="4">
          <BaseText
            v-model="itemNumber"
            :loading="loading.itemNumber"
            label="Item Number"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <BaseText
            v-model="productionBatchNo"
            :loading="loading.productionBatchNo"
            label="Production Batch No"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <BaseText
            v-model="glassBatchNo"
            :loading="loading.glassBatchNo"
            label="Glass Batch No"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="door.batchId"
            :items="filteredBatches"
            item-value="id"
            item-text="productionBatchNumber"
            clearable
            label="Production Batch Number"
            @change="assignToBatch"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.productionBatchNumber }} -
              {{ data.item.description }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="deliveryDocketId"
            :items="deliveryDockets"
            item-value="id"
            item-text="reference"
            clearable
            label="Delivery Docket"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <div v-show="door.itemNumber">
      <div class="subtitle-1">NFC Tag Link</div>
      <a :href="nfcLink" target="_blank">{{ nfcLink }}</a>
      <div class="mt-4">
        <v-btn color="primary" @click="copyToClipboard">
          Copy to Clipboard
        </v-btn>
        <span v-show="copied" class="ml-4">
          <v-icon color="success">mdi-check</v-icon>
          <span>Copied</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import { syncProp } from "@gradys/vue-base/components/forms/mixins";

export default {
  data() {
    return {
      loading: {
        itemNumber: false,
        productionBatchNo: false,
        glassBatchNo: false
      },
      copied: false
    };
  },
  computed: {
    batches: get("batches/batches"),
    deliveryDockets: get("deliveryDockets/deliveryDockets"),
    door: get("doors/door"),
    ...syncProp("itemNumber"),
    ...syncProp("productionBatchNo"),
    ...syncProp("glassBatchNo"),
    ...syncProp("deliveryDocketId"),
    nfcLink() {
      return `${process.env.VUE_APP_NFC_FILES_URL}?itemNumber=${this.door.itemNumber}`;
    },
    filteredBatches() {
      return this.batches.filter(
        x => x.lockedStatus === "Unlocked" || x.id === this.batchId
      );
    }
  },
  async created() {
    await this.loadBatches();
    await this.loadDeliveryDockets();
  },
  methods: {
    updateDoorAsync: call("doors/updateDoorAsync"),
    loadBatches: call("batches/loadBatches"),
    loadDeliveryDockets: call("deliveryDockets/loadDeliveryDockets"),
    assignDoorToBatch: call("doors/assignDoorToBatch"),
    assignToBatch() {
      this.assignDoorToBatch(this.door);
    },
    async updateDoor(prop, value) {
      try {
        this.loading[prop] = true;
        await this.updateDoorAsync({
          prop,
          value
        });
      } finally {
        this.loading[prop] = false;
      }
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.nfcLink);
        this.copied = true;

        setTimeout(() => {
          this.copied = false;
        }, 3000);
      } catch (e) {
        alert(
          "Unable to copy text to clipboard. Please use latest version of Chrome"
        );
      }
    }
  }
};
</script>
