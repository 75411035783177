<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="192"
    height="417"
    :x="x"
    :y="y"    
    @click="doorClicked"
  >
    <rect 
      class="door-sash" 
      :class="doorClass"
      x="0" y="0" width="192" height="417"
    />
    <Blarney5p  v-if="door.doorStyle === 'blarney-5p'" />
    <Bodiamf1gc  v-if="door.doorStyle === 'bodiam-f1gc'" />
    <Bunratty  v-if="door.doorStyle === 'bunratty'" />
    <Carisbrokesb  v-if="door.doorStyle === 'carisbroke-sb'" :detailColour="detailColour" />
    <Carisbrooke  v-if="door.doorStyle === 'carisbrooke'" />
    <Dover-1p1g  v-if="door.doorStyle === 'dover-1p1g'" />
    <Dover-1p1gab  v-if="door.doorStyle === 'dover-1p1gab'" />
    <Dover-2p  v-if="door.doorStyle === 'dover-2p'" />
    <Dover-2p1g  v-if="door.doorStyle === 'dover-2p1g'" />
    <Dover-2pgab  v-if="door.doorStyle === 'dover-2pgab'" />
    <Dromoland  v-if="door.doorStyle === 'dromoland'" />
    <Edinburgh-4p  v-if="door.doorStyle === 'edinburgh-4p'" />
    <Edinburgh-4p2g  v-if="door.doorStyle === 'edinburgh-4p2g'" />
    <Hammersmith-3p  v-if="door.doorStyle === 'hammersmith-3p'" />
    <Hammersmithf1sgab  v-if="door.doorStyle === 'hammersmith-f1sgab'" />
    <Leeds12g  v-if="door.doorStyle === 'leeds-1_2g'" />
    <Leeds22g  v-if="door.doorStyle === 'leeds-2_2g'" />
    <Leeds-1glr  v-if="door.doorStyle === 'leeds-1glr'" />
    <Leeds-1lgc  v-if="door.doorStyle === 'leeds-1lgc'" />
    <Leeds-1lgl  v-if="door.doorStyle === 'leeds-1lgl'" />
    <Leeds-2g  v-if="door.doorStyle === 'leeds-2g'" />
    <Leeds-2sgc  v-if="door.doorStyle === 'leeds-2sgc'" />
    <Leedsf2lg  v-if="door.doorStyle === 'leeds-f2lg'" />
    <Leedsf2rg  v-if="door.doorStyle === 'leeds-f2rg'" />
    <Leedsflg  v-if="door.doorStyle === 'leeds-flg'" />
    <LeedsflgRight v-if="door.doorStyle === 'leeds-flg-right'" />
    <Leedsflush  v-if="door.doorStyle === 'leeds-flush'" />
    <Leedsisg  v-if="door.doorStyle === 'leeds-isg'" />
    <Lismore  v-if="door.doorStyle === 'lismore'" />
    <London-2p1g  v-if="door.doorStyle === 'london-2p1g'" />
    <Mallahide-4p1g  v-if="door.doorStyle === 'mallahide-4p1g'" />
    <Warwick-6p  v-if="door.doorStyle === 'warwick-6p'" />
    <Warwick-6p2g  v-if="door.doorStyle === 'warwick-6p2g'" />
    <Warwick-6p4g  v-if="door.doorStyle === 'warwick-6p4g'" />
    <Windsor5p  v-if="door.doorStyle === 'windsor5p'" />
    <DoorComponents :door="door" />
  </svg>
</template>

<script>
import DoorComponents from "@gradys/vue-base/components/doors/door-components";
import Blarney5p  from "@gradys/vue-base/components/doors/leafs/blarney-5p";
import Bodiamf1gc  from "@gradys/vue-base/components/doors/leafs/bodiam-f1gc";
import Bunratty  from "@gradys/vue-base/components/doors/leafs/bunratty";
import Carisbrokesb  from "@gradys/vue-base/components/doors/leafs/carisbroke-sb";
import Carisbrooke  from "@gradys/vue-base/components/doors/leafs/carisbrooke";
import Dover1p1g  from "@gradys/vue-base/components/doors/leafs/dover-1p1g";
import Dover1p1gab  from "@gradys/vue-base/components/doors/leafs/dover-1p1gab";
import Dover2p  from "@gradys/vue-base/components/doors/leafs/dover-2p";
import Dover2p1g  from "@gradys/vue-base/components/doors/leafs/dover-2p1g";
import Dover2pgab  from "@gradys/vue-base/components/doors/leafs/dover-2pgab";
import Dromoland  from "@gradys/vue-base/components/doors/leafs/dromoland";
import Edinburgh4p  from "@gradys/vue-base/components/doors/leafs/edinburgh-4p";
import Edinburgh4p2g  from "@gradys/vue-base/components/doors/leafs/edinburgh-4p2g";
import Hammersmith3p  from "@gradys/vue-base/components/doors/leafs/hammersmith-3p";
import Hammersmithf1sgab  from "@gradys/vue-base/components/doors/leafs/hammersmith-f1sgab";
import Leeds12g  from "@gradys/vue-base/components/doors/leafs/leeds-1_2g";
import Leeds22g  from "@gradys/vue-base/components/doors/leafs/leeds-2_2g";
import Leeds1glr  from "@gradys/vue-base/components/doors/leafs/leeds-1glr";
import Leeds1lgc  from "@gradys/vue-base/components/doors/leafs/leeds-1lgc";
import Leeds1lgl  from "@gradys/vue-base/components/doors/leafs/leeds-1lgl";
import Leeds2g  from "@gradys/vue-base/components/doors/leafs/leeds-2g";
import Leeds2sgc  from "@gradys/vue-base/components/doors/leafs/leeds-2sgc";
import Leedsf2lg  from "@gradys/vue-base/components/doors/leafs/leeds-f2lg";
import Leedsf2rg  from "@gradys/vue-base/components/doors/leafs/leeds-f2rg";
import Leedsflg  from "@gradys/vue-base/components/doors/leafs/leeds-flg";
import LeedsflgRight  from "@gradys/vue-base/components/doors/leafs/leeds-flg-right";
import Leedsflush  from "@gradys/vue-base/components/doors/leafs/leeds-flush";
import Leedsisg  from "@gradys/vue-base/components/doors/leafs/leeds-isg";
import Lismore  from "@gradys/vue-base/components/doors/leafs/lismore";
import London2p1g  from "@gradys/vue-base/components/doors/leafs/london-2p1g";
import Mallahide4p1g  from "@gradys/vue-base/components/doors/leafs/mallahide-4p1g";
import Warwick6p  from "@gradys/vue-base/components/doors/leafs/warwick-6p";
import Warwick6p2g  from "@gradys/vue-base/components/doors/leafs/warwick-6p2g";
import Warwick6p4g  from "@gradys/vue-base/components/doors/leafs/warwick-6p4g";
import Windsor5p  from "@gradys/vue-base/components/doors/leafs/windsor5p";

export default {
  components: {
    DoorComponents,
    Blarney5p,
    Bodiamf1gc,
    Bunratty,
    Carisbrokesb,
    Carisbrooke,
    Dover1p1g,
    Dover1p1gab,
    Dover2p,
    Dover2p1g,
    Dover2pgab,
    Dromoland,
    Edinburgh4p,
    Edinburgh4p2g,
    Hammersmith3p,
    Hammersmithf1sgab,
    Leeds12g,
    Leeds22g,
    Leeds1glr,
    Leeds1lgc,
    Leeds1lgl,
    Leeds2g,
    Leeds2sgc,
    Leedsf2lg,
    Leedsf2rg,
    Leedsflg,
    LeedsflgRight,
    Leedsflush,
    Leedsisg,
    Lismore,
    London2p1g,
    Mallahide4p1g,
    Warwick6p,
    Warwick6p2g,
    Warwick6p4g,
    Windsor5p
  },
  props: {
    door: {
      type: Object,
      default() {
        return {}
      }
    },
    wrappedInFrame: {
      type: Boolean, 
      default: true
    }
  },
  computed: {
    x() {
      return this.wrappedInFrame ? "108.5" : "0";
    },
    y() {
      return this.wrappedInFrame ? "121.5" : "0";
    },
    doorClass() {
      return `door__${this.door.externalColour}`;
    },
    // NOTE: This is to pass around sash colour
    detailColour() {      
      switch(this.door.externalColour) {
        case "Red": return "#781f19"
        case "Blue": return "#0e294b"
        case "Green": return "#31372b"
        case "Black": return "#1d1d1d"
        case "White": return "#eee"
        default: return "#ccc"
      }
    }
  },
  methods: {
    doorClicked() {
      this.$emit("on:door-clicked");
    }
  },
};
</script>

<style lang="scss" scoped>
.door-sash {
  fill: #ccc;
}
.door__Red {
  fill: #781f19;
}
.door__Blue {
  fill: #0e294b;
}
.door__Green {
  fill: #31372b;
}
.door__Black {
  fill: #1d1d1d;
}
.door__White {
  fill: #eee;
}
</style>
