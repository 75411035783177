import Vue from "vue";
import Auth from "./Auth";
import { router } from '@/router';

let instance;

export const getInstance = () => instance;

export const useAuth0 = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        name: null,
        isAuthenticated: false
      };
    },
    methods: {
      login() {
        Auth.login();
      },
      logout() {
        Auth.logout();
      },
      getAccessToken() {
        return Auth.getAccessToken();
      },
      getProfile() {
        return Auth.getProfile();
      },
      getRoles() {
        const profile = this.getProfile();
        const roleClaim = "https://gradyjoinery.co.uk/roles";
        if (profile && profile[roleClaim]) {
          return profile[roleClaim];
        }

        return [];
      }
    },
    async created() {
      try {
        if (!window.location.hash) {
          await Auth.renewTokens();
          this.isAuthenticated = Auth.isAuthenticated();
          this.name = this.getProfile().name;
          this.loading = false;
          return;
        }

        const hash = window.location.hash.substring(1);
        const params = {};
        hash.split("&").map(hk => {
          const temp = hk.split("=");
          params[temp[0]] = temp[1];
        });

        if (params.access_token && params.state) {
          await Auth.handleAuthentication();
          this.isAuthenticated = true;
          this.name = this.getProfile().name;
          this.loading = false;

          router.push("/");
        }
      } catch (e) {
        // TODO : Log Error
      } finally {
        this.loading = false;
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const AuthPlugin = {
  install(Vue) {
    Vue.prototype.$auth = useAuth0();
  }
};
