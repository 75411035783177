<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>
            Type
          </th>
          <th>
            Weight
          </th>
          <th>
            Cost
          </th>
          <th>
            GJUK
          </th>

          <th>
            Client Price
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(summaryItem, i) in costSummary.summaries"
          :key="summaryItem + i"
        >
          <td>{{ summaryItem.type }}</td>
          <td>{{ summaryItem.weight | number("0,0.00") }}</td>
          <td>
            £{{ (summaryItem.cost / summaryItem.quantity) | number("0,0.00") }}
          </td>
          <td>
            £{{ (summaryItem.gjuk / summaryItem.quantity) | number("0,0.00") }}
          </td>
          <td>
            £{{
              (summaryItem.clientPrice / summaryItem.quantity)
                | number("0,0.00")
            }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    costSummary: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>
