<template>
  <div>
    <div class="mb-4">
      <v-btn
        color="primary"
        class="mr-2"
        :to="`/orders/${order.id}/doors/${door.id}/bom`"
      >
        Bill of Materials
      </v-btn>
      <v-btn :to="`/orders/${order.id}/doors/${door.id}`" color="primary">
        View Door Set
      </v-btn>
    </div>
    <v-divider class="my-4"></v-divider>
    <DoorSet
      :door="door"
      :show-as-print="false"
      :threshold-image-name="thresholdImageName"
      :new-clear-opening="newClearOpening"
      :order-ref="order.reference"
      :closers="closers"
      :frontImage="frontImage"
      :thresholdImage="thresholdImage"
      :jambImage="jambImage"
      :headImage="headImage"
    />
    <div class="pa-4">
      <v-divider class="mb-4"></v-divider>
      <v-textarea
        :value="notes"
        label="Notes"
        placeholder="Add Notes to Job"
        @change="e => (notes = e)"
      ></v-textarea>
    </div>
    <SubmitSurveyButtons
      ref="buttons"
      :door="door"
      :openingErrors="openingErrors"
      :openingWarnings="openingWarnings"
      :doorDesignErrors="doorDesignErrors"
      @on:upload="submitSurvey"
    />
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import { mixin, syncProp } from "@gradys/vue-base/components/forms/mixins";

import SubmitSurveyButtons from "@gradys/vue-base/components/forms/submit-survey-buttons";
import DoorSet from "@gradys/vue-base/components/review/door-set";

export default {
  components: {
    SubmitSurveyButtons,
    DoorSet
  },
  mixins: [mixin],
  computed: {
    door: get("doors/door"),
    order: get("orders/order"),
    ...syncProp("notes"),
    closers: get("lookups/closers"),
    thresholdImageName: get("doors/thresholdImageName"),
    newClearOpening: get("doors/newClearOpening"),
    openingErrors: get("doors/openingErrors"),
    doorDesignErrors: get("doors/doorDesignErrors"),
    openingWarnings: get("doors/openingWarnings"),
    frontImage: get("doors/frontImage"),
    headImage: get("doors/headImage"),
    jambImage: get("doors/jambImage"),
    thresholdImage: get("doors/thresholdImage")
  },
  methods: {
    submit: call("doors/submit"),
    fetchDoor: call("doors/fetchDoor"),
    async submitSurvey() {
      this.$refs.buttons.setLoading(true);
      try {
        await this.submit();
        await this.fetchDoor(this.door.id);
      } finally {
        this.$refs.buttons.setLoading(false);
      }
    }
  }
};
</script>
