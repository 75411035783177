<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="pt-2 pb-4 px-0">
    </v-breadcrumbs>
    <AddForm ref="form" :companies="companies" @on:save="save" />
    <v-card :loading="loading">
      <v-card-title>
        Installers
        <v-btn color="primary" class="ml-4" small @click="newInstaller">
          Add Installer
        </v-btn>
      </v-card-title>
      <v-card-text v-if="noInstallers">
        No Installers
      </v-card-text>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>BMTrada Company</th>
              <th>BMTrada Certificate No.</th>
              <th>Active Fitters</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="installer in installers" :key="installer.id">
              <td>{{ installer.name }}</td>
              <td>{{ installer.company }}</td>
              <td>{{ installer.certNo }}</td>
              <td>{{ installer.activeFitters }}</td>
              <td>
                <v-btn
                  small
                  color="primary"
                  :to="`/fitters/installers/${installer.id}`"
                >
                  View Details
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";

import AddForm from "@/components/forms/installers/add-form";

export default {
  components: {
    AddForm
  },
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Installers"
        }
      ]
    };
  },
  computed: {
    companies: get("bmTradaCompanies/companies"),
    installers: get("installers/installers"),
    noInstallers() {
      if (!this.installers) return true;
      if (!this.installers.length) return true;

      return false;
    }
  },
  async created() {
    try {
      await this.loadInstallers();
      await this.loadBmTradaCompanies();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadBmTradaCompanies: call("bmTradaCompanies/loadBmTradaCompanies"),
    loadInstallers: call("installers/loadInstallers"),
    saveInstaller: call("installers/saveInstaller"),
    newInstaller() {
      // prompt for name, then redirect to details view
      this.$refs.form.open();
    },
    async save(data) {
      this.$refs.form.setLoading(true);
      try {
        await this.saveInstaller(data);
        // TODO : get ID
        // TODO : redirect
      } finally {
        this.$refs.form.setLoading(false);
        this.$refs.form.close();
      }
    }
  }
};
</script>
