<template>
  <div class="px-4">
    <BaseSelect v-model="closer" label="Closer" :items="closers" />
    <div>
      <img
        v-if="closer === 'ITS11204'"
        class="closer__image"
        src="@/assets/closer-options/ITS11204.png"
      />
      <img
        v-else-if="closer === 'Responder-24'"
        class="closer__image"
        src="@/assets/closer-options/Responder-24.png"
      />
      <img
        v-else-if="closer === 'TS9205'"
        class="closer__image"
        src="@/assets/closer-options/TS9205.png"
      />
      <img
        v-else-if="closer === 'TS11204'"
        class="closer__image"
        src="@/assets/closer-options/TS11204.png"
      />
       <img
        v-else-if="closer === 'TS11205'"
        class="closer__image"
        src="@/assets/closer-options/TS11205.png"
      />
      <div v-else-if="closer === ''" class="no_image">
        Select Closer
      </div>
      <div v-else class="no_image">
        No image available
      </div>
    </div>
  </div>
</template>

<script>
import { mixin, syncProp } from "@gradys/vue-base/components/forms/mixins";

export default {
  mixins: [mixin],
  props: {
    door: {
      type: Object,
      required: true
    },
    closers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...syncProp("closer")
  }
};
</script>

<style scoped>
.closer__image,
.no_image {
  max-width: 450px;
  height: 250px;
}
.no_image {
  display: flex;
  background: #eee;
  justify-content: center;
  align-items: center;
  border: solid 1px #000;
}
</style>
