<template>
  <div>
    <div v-if="staticImage">
      <v-card-title v-if="showTitle">{{ image.type }}</v-card-title>
      <div class="border">
        <img :id="wrapperId" class="static-image" :src="imageSRC" />
      </div>
      <div v-if="showButton">
        Uploaded on {{ image.dateUploaded | euDate }}
        <v-btn x-small class="ml-1" color="red" text @click="disableImage">
          Remove</v-btn
        >
      </div>
    </div>

    <div v-else>
      <slot />
      <FileUploadButton
        class="pt-3"
        v-if="showButton"
        :upload-text="uploadText"
        :upload-location="`${image.type}/${door.id}`"
        @on:file="uploadImage"
      />
    </div>
  </div>
</template>
<script>
import FileUploadButton from "@/components/files/file-upload-button";
import { storage } from "@/plugins/firebase";
import { call } from "vuex-pathify";
export default {
  components: {
    FileUploadButton
  },
  data() {
    return {
      imageSRC: "",
      staticImage: true
    };
  },
  computed: {
    uploadText() {
      if (!this.image) return "";
      return `Upload ${this.image.type} Image`;
    },
    wrapperId() {
      return this.image.type === "Front" ? "full-frame" : "";
    }
  },
  props: {
    door: {
      type: Object,
      default() {
        return {};
      }
    },
    image: {
      type: Object,
      default() {
        return {};
      }
    },
    showButton: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.generateDownloadURL();
  },
  methods: {
    saveDoorImage: call("doors/uploadImageToDoor"),
    removeImage: call("doors/disableImage"),
    async uploadImage(file) {
      const ref = storage.ref(file.url);
      this.newImage = {
        imageUrl: ref.fullPath,
        doorId: this.door.id,
        type: this.image.type
      };
      await this.saveDoorImage(this.newImage);
      this.generateDownloadURL();
    },
    generateDownloadURL() {
      if (!this.image.imageURL) {
        this.imageSRC = null;
        this.staticImage = false;
        return;
      }
      const ref = storage.ref(this.image.imageURL);
      ref.getDownloadURL().then(url => {
        this.imageSRC = `${url}.jpg`;
        this.staticImage = true;
      });
    },
    async disableImage() {
      try {
        await this.removeImage(this.image);
      } finally {
        this.imageURL = null;
        this.staticImage = false;
      }
    }
  }
};
</script>
<style scoped>
.border {
  border: 1px solid orange;
}
.static-image {
  max-width: 250px;
  margin: 4px 4px 4px 4px;
}
</style>
