<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="company.name"
            label="Name"
            :rules="nameRules"
            required
          />
          <v-text-field
            v-model="company.certNo"
            label="BMTrada Company Certificate No."
          />
          <v-textarea v-model="company.address" label="Address"></v-textarea>
          <FileUploadButton
            upload-text="Upload Logo"
            upload-location="images/company-logos"
            accept="image/*"
            @on:file="logoUploaded"
          />
          <div class="py-4">{{ fileName }}</div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">Save</v-btn>
        <v-btn text color="red" @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-show="canDelete"
          color="error"
          class="text-right"
          @click="remove"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUploadButton from "@/components/files/file-upload-button";
import { storage } from "@/plugins/firebase";

export default {
  components: {
    FileUploadButton
  },
  data() {
    return {
      fileName: "",
      company: {},
      nameRules: [v => !!v || "Name is required"],
      dialog: false,
      loading: false
    };
  },
  computed: {
    canDelete() {
      return this.company.id;
    },
    formTitle() {
      return this.company.id ? "Edit Company" : "Add Company";
    }
  },
  methods: {
    remove() {
      const ans = confirm("Are you sure you want to delete this Company");
      if (ans) {
        this.$emit("on:delete", this.company);
      }
    },
    open(company) {
      this.dialog = true;
      this.company = { ...company };
      this.fileName = "";

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    logoUploaded(file) {
      this.fileName = file.file.name;
      const ref = storage.ref(file.url);
      ref.getDownloadURL().then(url => {
        this.company.logoUrl = url;
      });
    },
    save() {
      if (this.$refs.form.validate()) this.$emit("on:save", this.company);
    }
  }
};
</script>
