import { storage } from "@/plugins/firebase";

export const defaultTypes = [
  "Survey Sheet",
  "Survey Video",
  "Door Choice",
  "Assembly List (Production)",
  "Inspection Sheets",
  "Installation Video - Rock Wool Only",
  "Installation Video - Rock Wool and Fire Mastic Point",
  "Installation Video - Completed Door",
  "Resident Hand Over Sheet",
  "BM Trada Inspection Video",
  "BM Trada Fire Door Set Installation Certificate"
];

function getMissingFiles(currentList, originalList) {
  return originalList.filter(file => {
    if (!currentList.find(f => f.fileUrl === file.fileUrl)) return true;
    else return false;
  });
}

export function generateFileMap(files) {
  // get the default file list based off default type list
  const defaultList = defaultTypes.map(type => {
    const match = files.find(t => t.type === type) || {};

    return {
      fileUrl: match.fileUrl || "",
      fileName: match.fileName || "",
      id: match.id,
      type
    };
  });

  // get files, either extra or their type isn't on the default type list
  const missingFiles = getMissingFiles(defaultList, files);

  return [...defaultList, ...missingFiles];
}

export function downloadFile(file) {
  const ref = storage.ref(file.fileUrl);
  return ref
    .getDownloadURL()
    .then(url => {
      return fetch(url);
    })
    .then(r => r.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
}
