<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title>Add Quote</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="reference"
            label="Reference"
            :rules="refRules"
            required
          />
          <v-select
            label="Client Name"
            v-model="client"
            clearable
            item-value="id"
            item-text="name"
            :items="clients"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">
          Add Quote
        </v-btn>
        <v-btn text color="red" @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get, call } from "vuex-pathify";
export default {
  data() {
    return {
      reference: "",
      client: "",
      refRules: [v => !!v || "Quote Reference is required"],
      dialog: false,
      loading: false
    };
  },
  computed: {
    clients: get("clients/clients")
  },
  created() {
    return this.loadClients();
  },
  methods: {
    loadClients: call("clients/loadClients"),
    open() {
      this.dialog = true;
      this.reference = "";
      this.client = "";

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
          this.$refs.form.reset();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$emit("on:save", {
        reference: this.reference,
        clientId: this.client
      });
    }
  }
};
</script>
