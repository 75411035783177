<template>
  <v-card :loading="loading">
    <v-card-title>
      Orders
      <v-btn color="success" dark class="ml-12" small to="/orders/new">
        <v-icon left>mdi-plus-circle-outline</v-icon>
        Create New Order
      </v-btn>
      <v-btn color="success" class="ml-4" small to="/orders/newExcel">
        <v-icon left>mdi-file-excel-outline</v-icon>
        Upload Excel ORder
      </v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        small
        v-if="filter.assignedToMe"
        @click="filter.assignedToMe = false"
      >
        <v-icon left>mdi-checkbox-marked-outline</v-icon>
        Show Assigned To Me
        <v-icon right>mdi-account-edit-outline</v-icon>
      </v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        small
        outlined
        v-else
        @click="filter.assignedToMe = true"
      >
        <v-icon left>mdi-checkbox-blank-outline</v-icon>
        Show Assigned To Me
        <v-icon right>mdi-account-edit-outline</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        small
        class="ml-2"
        outlined
        @click="clearFilters"
      >
        <v-icon left>mdi-close</v-icon>
        Clear Filters
      </v-btn>
    </v-card-title>
    <v-skeleton-loader v-if="loading" type="table-tbody"></v-skeleton-loader>
    <v-data-table
      v-else
      class="click-table dashboard-table"
      :headers="headers"
      :hide-default-header="false"
      :items="filteredOrders"
      :items-per-page="100"
      :height="tableHeight"
      no-data-text="No Orders"
      @click:row="showOrder"
      fixed-header
    >
      <template v-slot:body.prepend>
        <tr class="no-click">
          <td></td>
          <td>
            <v-autocomplete
              dense
              clearable
              auto-select-first
              :items="orders"
              item-text="reference"
              multiple
              item-value="id"
              v-model="filter.orderId"
              small-chips
            />
          </td>
          <td>
            <v-autocomplete
              dense
              clearable
              auto-select-first
              :items="clients"
              item-text="name"
              item-value="id"
              small-chips
              v-model="filter.clientId"
              multiple
            />
          </td>
          <td>
            <v-autocomplete
              dense
              clearable
              auto-select-first
              :items="contracts"
              item-text="name"
              item-value="id"
              multiple
              v-model="filter.contractId"
              small-chips
            />
          </td>
          <td></td>
        </tr>
      </template>
      <template v-slot:item.currentEditor="{ value }">
        <v-icon color="orange" v-if="$auth.name === value"
          >mdi-account-edit-outline</v-icon
        >
      </template>
      <template v-slot:item.reference="{ value }">
        <span class="dont-wrap">
          {{ value }}
        </span>
      </template>
      <template v-slot:item.planedDespatchDate="{ value }">
        {{ value | euDate }}
      </template>
      <template v-slot:item.created="{ value }">
        {{ value | euDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { jobStatuses } from "@gradys/vue-base/store/lookups/lookup-values";
import { get, call, sync } from "vuex-pathify";
import { vueWindowSizeMixin } from "vue-window-size";

export default {
  mixins: [vueWindowSizeMixin],
  data() {
    return {
      jobStatuses,
      loading: true,
      loadingLookups: true,
      headers: [
        {
          text: "",
          value: "currentEditor"
        },
        {
          text: "Order Ref",
          value: "reference"
        },
        {
          text: "Client",
          value: "clientName"
        },
        {
          text: "Contract",
          value: "contract"
        },
        {
          text: "Date Created",
          value: "created"
        }
      ]
    };
  },
  computed: {
    orders: get("dashboard/orders"),
    filteredOrders: get("dashboard/filteredOrders"),
    orderReferences: get("dashboard/orderReferences"),
    clients: get("clients/clients"),
    contracts: get("dashboard/contracts"),
    filter: sync("dashboard/filter"),
    tableHeight() {
      return this.windowHeight - 262;
    }
  },
  async created() {
    try {
      await this.load();
    } finally {
      this.loading = false;
    }

    try {
      await Promise.all([this.loadClients(), this.fetchContracts()]);
    } finally {
      this.loadingLookups = false;
    }
  },
  methods: {
    load: call("dashboard/load"),
    saveFilter: call("dashboard/saveFilter"),
    fetchContracts: call("dashboard/fetchContracts"),
    loadClients: call("clients/loadClients"),
    clearFilters: call("dashboard/clearFilters"),
    showOrder(item) {
      this.$router.push(`order/${item.id}`);
    }
  },
  watch: {
    "$store.state.dashboard.filter": {
      handler() {
        return this.saveFilter();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.dashboard-table {
  .v-select__slot {
    font-size: 0.8em;
  }
}
</style>
