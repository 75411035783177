<template>
  <span class="ml-4">
    <span v-if="status === 'SurveySubmitted'">
      <v-icon large color="green">mdi-check</v-icon>
      Survey Submitted
    </span>
    <span v-else-if="status === 'pending'">
      <v-icon large color="warning">mdi-progress-alert</v-icon>
      Pending
    </span>
    <span v-else-if="showNotComplete">
      Not Complete
    </span>
  </span>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ""
    },
    showNotComplete: {
      type: Boolean,
      default: true
    }
  }
};
</script>
