<template>
  <div class="A4">
    <div class="text-center mt-4">
      <div>
        <v-btn color="primary" small @click="print">Print</v-btn>
      </div>
    </div>
    <div class="section-to-print page-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    print() {
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
}
@media print {
  .page-wrapper {
    margin-top: -4rem;
  }
}
</style>
