<template>
  <div>
    <v-skeleton-loader v-if="loading" type="image"> </v-skeleton-loader>
    <div v-else>
      <v-breadcrumbs
        :items="breadcrumbs"
        divider=">"
        class="pt-1 pb-1 px-0"
        data-cy="breadcrumb"
      />
      <FileViewer :file="file" />
    </div>
  </div>
</template>

<script>
import FileViewer from "@gradys/vue-base/components/files/file-viewer";
import { get, call } from "vuex-pathify";

export default {
  components: {
    FileViewer
  },
  data() {
    return {
      loading: true,
      file: {}
    };
  },
  methods: {
    loadContractDocuments: call("clients/loadContractDocuments"),
    loadClient: call("clients/loadClient")
  },
  computed: {
    client: get("clients/selectedClient"),
    documents: get("clients/contractDocuments"),

    breadcrumbs() {
      if (!this.client || !this.client.id) return [];

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Clients",
          to: "/Clients",
          exact: true
        },
        {
          text: this.client.name,
          to: `/Clients/${this.client.id}`,
          exact: true
        },
        {
          text: "Edit Contract",
          to: `/Clients/${this.client.id}/view-contract/${this.$route.params.contractId}`,
          exact: true
        },
        {
          text: "View Contract Document"
        }
      ];
    }
  },
  async created() {
    const { id, contractId, documentId } = this.$route.params;

    try {
      await this.loadClient(id);
      await this.loadContractDocuments(contractId);
      this.file = this.documents[documentId];
    } finally {
      this.loading = false;
    }
  }
};
</script>
