<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title>Create Consignment</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="consignment.dispatchDate"
              label="Consignment Dispatch Date"
              required
              type="date"
            />
            <v-textarea
              v-model="consignment.notes"
              label="Notes"
              required
              type="text"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            @click="createNewConsignment"
          >
            Create
          </v-btn>
          <v-btn color="red" text @click="dialog = !dialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <v-card :loading="loading">
      <v-card-title>Consignments for Customs Invoicing</v-card-title>
      <v-card-actions>
        <v-btn class="success" small @click="dialog = !dialog"
          >Create new Consignment</v-btn
        >
      </v-card-actions>
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="consignments"
        class="click-table"
        @click:row="navigateToConsignment"
      >
        <template v-slot:item.dispatchDate="{ value }">
          {{ value | euDate }}
        </template>
        <template v-slot:item.creationDate="{ value }">
          {{ value | euDate }}
        </template>
      </v-data-table>
      <v-skeleton-loader
        v-if="loading"
        :loading="loading"
        type="table-tbody"
      ></v-skeleton-loader>
    </v-card>
  </div>
</template>
<script>
import { get, call } from "vuex-pathify";

export default {
  data() {
    return {
      loading: true,
      dialog: false,
      consignment: {
        notes: "",
        dispatchDate: new Date().toISOString().substr(0, 10),
        creationDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Reference", value: "reference" },
        { text: "Dispatch Date", value: "dispatchDate" },
        { text: "Creation Date", value: "creationDate" },
        { text: "Weight", value: "totalWeight" }
      ],
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Consignments"
        }
      ]
    };
  },
  computed: {
    consignments: get("consignments/consignments")
  },
  async created() {
    await this.loadConsignments();
    this.loading = false;
  },
  methods: {
    loadConsignments: call("consignments/loadConsignments"),
    saveConsignment: call("consignments/saveConsignment"),
    async createNewConsignment() {
      this.loading = true;
      const data = await this.saveConsignment(this.consignment);
      this.dialog = false;
      this.loading = false;
      this.$router.push(`consignments/${data.id}`);
    },
    navigateToConsignment(consignment) {
      this.$router.push(`/consignments/${consignment.id}`);
    }
  }
};
</script>
