<template>
  <v-btn
    x-small
    :outlined="outlined"
    tile
    color="primary"
    @click="e => $emit('input', !value)"
  >
    <v-icon v-if="value" left>mdi-checkbox-marked-outline</v-icon>
    <v-icon v-else left>mdi-checkbox-blank-outline</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    outlined() {
      return !this.value;
    }
  }
};
</script>
