<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <div v-if="loading">
      <v-skeleton-loader
        :loading="loading"
        type="card-heading, list-item@5"
        class="mb-4"
      ></v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        type="table-tbody"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card class="mb-2">
        <v-card-title>
          {{ client.name }}
          <v-btn
            class="ml-12"
            small
            :loading="removing"
            color="error"
            @click="remove"
            data-cy="remove"
          >
            Remove Client
          </v-btn>
        </v-card-title>
        <v-card-subtitle>Client Details</v-card-subtitle>
        <v-card-text>
          <BaseText
            :value="client.name"
            :loading="saving.name"
            label="Edit Client Name"
            @input="updateClientName"
          />
        </v-card-text>
      </v-card>
      <ClientContracts :client="client" class="mb-2" />
      <DeliveryAddressForm
        ref="addressForm"
        @on:save="saveAddress"
        :contracts="client.contracts"
      />
      <v-card :loading="loadingAddress">
        <v-card-title>
          <v-container class="pa-0" fluid>
            <v-row dense>
              <v-col sm="12" md="3">Delivery Addresses</v-col>
              <v-col sm="12" md="5">
                <v-text-field
                  v-model="searchAddress"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="4" class="text-right">
                <v-btn color="primary" small @click="addAddress">
                  Add Address
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-data-table
          :headers="addressHeaders"
          :items="deliveryAddresses"
          :items-per-page="5"
          item-key="address"
          :search="searchAddress"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              color="primary"
              @click="editAddress(item)"
              class="mr-2 mb-1"
            >
              Edit
            </v-btn>
            <v-btn
              class="mb-1"
              small
              color="error"
              @click="removeAddress(item)"
            >
              Remove
            </v-btn>
          </template>
          <template v-slot:no-data>
            No Addresses
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import DeliveryAddressForm from "@/components/forms/clients/delivery-address-form";
import ClientContracts from "@/components/forms/clients/client-contracts";

export default {
  components: {
    DeliveryAddressForm,
    ClientContracts
  },
  data() {
    return {
      addressHeaders: [
        {
          text: "Address",
          value: "address"
        },
        {
          text: "Contract",
          value: "contract"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: 250,
          align: "end"
        }
      ],
      searchAddress: "",
      searchUser: "",
      loading: true,
      loadingAddress: false,
      saving: {
        name: false,
        user: false
      },
      editAddressIndex: 0,
      removing: false
    };
  },
  computed: {
    client: get("clients/selectedClient"),
    breadcrumbs() {
      if (!this.client || !this.client.id) return [];

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Clients",
          to: "/Clients",
          exact: true
        },
        {
          text: this.client.name
        }
      ];
    },
    deliveryAddresses() {
      if (!this.client) return [];

      return this.client.deliveryAddresses;
    },
    hasAddresses() {
      if (
        !this.client ||
        !this.client.deliveryAddresses ||
        !this.client.deliveryAddresses.length
      )
        return false;
      return true;
    }
  },
  created() {
    return this.load();
  },
  methods: {
    loadClient: call("clients/loadClient"),
    removeClient: call("clients/removeClient"),
    // Name clash
    saveAddressStore: call("clients/saveAddress"),
    removeAddressStore: call("clients/removeAddress"),
    updateClientName: call("clients/updateClientName"),
    async load() {
      try {
        await this.loadClient(this.$route.params.id);
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      if (!confirm("Are you sure you want to delete this client?")) return;

      try {
        this.removing = true;
        await this.removeClient(this.client);
        this.$router.push("/clients");
      } finally {
        this.removing = false;
      }
    },
    addAddress() {
      this.$refs.addressForm.open();
    },
    editAddress(item) {
      this.editAddressIndex = this.client.deliveryAddresses.indexOf(item);
      this.$refs.addressForm.open(item);
    },
    async saveAddress(address) {
      try {
        this.$refs.addressForm.setLoading(true);
        await this.saveAddressStore(address);
        this.$refs.addressForm.close();
      } finally {
        this.$refs.addressForm.setLoading(false);
      }
    },
    async removeAddress(address) {
      try {
        this.loadingAddress = true;
        await this.removeAddressStore(address);
      } finally {
        this.loadingAddress = false;
      }
    }
  }
};
</script>
