<template><g>
  <title>carisbroke-sb</title>
  
  <rect x="37.3" y="264.37" width="48.67" height="120.53" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
  <rect x="112.57" y="264.37" width="48.67" height="120.53" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
  <rect x="115.16" y="117.24" width="46.07" height="105.94" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
  <rect x="37.3" y="117.24" width="48.67" height="106.54" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
  <path d="M150.82,162.87a42.76,42.76,0,0,1,24.89,8.18h37.21c-5.67-28.3-31.31-49.67-62.1-49.67S94.4,142.75,88.73,171.05h37.21A42.75,42.75,0,0,1,150.82,162.87Z" transform="translate(-51.62 -88.99)" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
  <line x1="99.26" y1="73.88" x2="99.26" y2="32.7" :stroke="detailColour" stroke-miterlimit="10" stroke-width="3"/>
  <line x1="98.94" y1="72.98" x2="65.85" y2="42.09" :stroke="detailColour" stroke-miterlimit="10" stroke-width="3"/>
  <line x1="99.59" y1="72.98" x2="133.33" y2="42.69" :stroke="detailColour" stroke-miterlimit="10" stroke-width="3"/>
</g></template>

<script>
export default {
  props: {
    detailColour: {
      type: String,
      default: "#F00"      
    }
  }
}
</script>


