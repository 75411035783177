<template>
<g v-if="hasAnyPullHandle">
<svg :x="x" y="250" width="20" height="20" viewBox="0 0 83 97" fill="#8D8A85" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_220_318)">
<path d="M82 55.5C82 77.8675 63.8675 96 41.5 96C19.1325 96 1 77.8675 1 55.5C1 33.1325 19.1325 15 41.5 15C63.8675 15 82 33.1325 82 55.5Z" fill="#8D8A85"/>
<path d="M56 15C56 22.732 49.732 29 42 29C34.268 29 28 22.732 28 15C28 7.26801 34.268 1 42 1C49.732 1 56 7.26801 56 15Z" fill="#8D8A85"/>
</g>
<path d="M82.5 55.5C82.5 38.0841 71.6411 23.2044 56.3246 17.2621C56.4401 16.525 56.5 15.7695 56.5 15C56.5 6.99187 50.0081 0.5 42 0.5C33.9919 0.5 27.5 6.99187 27.5 15C27.5 15.6466 27.5423 16.2833 27.6243 16.9075C11.8093 22.5945 0.5 37.7265 0.5 55.5C0.5 78.1437 18.8563 96.5 41.5 96.5C64.1437 96.5 82.5 78.1437 82.5 55.5Z" stroke="black" stroke-opacity="0.5"/>
<circle cx="41.5" cy="56.5" r="27" fill="#86837E" stroke="black"/>
<circle cx="41.5" cy="56.5" r="23" fill="#8D8A85" stroke="black"/>
<circle cx="41.5" cy="11.5" r="3" fill="#C4C4C4" stroke="black"/>
<defs>
<filter id="filter0_i_220_318" x="0" y="0" width="83" height="101" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_220_318"/>
</filter>
</defs>
</svg>
</g>

</template>
<script>

import { get } from 'vuex-pathify';
export default {
  computed: {
    door: get("doors/door"),
    hasAnyPullHandle: get("doors/hasAnyPullHandle"),
    isLeftHanded: get("doors/isLeftHanded"),
    x() {
      return this.isLeftHanded ? 169 : 9
    }
  },
  
}
</script>
