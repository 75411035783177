<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline">
        Add Installer
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <CommonForm
            :installer="installer"
            :companies="companies"
            @on:name-changed="e => updateProp('name', e)"
            @on:company-changed="e => updateProp('bmTradaCompanyId', e)"
            @on:cert-no-changed="e => updateProp('certNo', e)"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">
          Add Installer
        </v-btn>
        <v-btn text color="red" @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonForm from "@/components/forms/installers/common-form-elements";
export default {
  components: {
    CommonForm
  },
  props: {
    companies: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      installer: {},
      dialog: false,
      loading: false
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.name = "";

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
          this.$refs.form.reset();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    updateProp(prop, val) {
      this.installer[prop] = val;
    },
    save() {
      if (this.$refs.form.validate()) this.$emit("on:save", this.installer);
    }
  }
};
</script>
