<template>
  <div v-if="!loading && order && door">
    <DoorBreadcrumbs
      :door="door"
      :order="order"
      :pageName="`Copy Door ${doorName}`"
      :allow-switch="false"
    />
    <v-card class="mt-4">
      <v-card-title>
        <span> Copy Door: {{ doorName }}</span>
        <div class="ml-8" v-if="pasted">
          <span class="subtitle-2 mr-2"> Pasted</span>
          <v-icon color="success">mdi-check</v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <NarrowRow>
          <v-col cols="auto" class="mb-4">
            <FrameWithDimmLines :door="door" />
          </v-col>
          <v-col>
            <table class="details-table">
              <tr>
                <th>Numerals</th>
                <td>{{ door.numerals }}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{{ door.address }}</td>
              </tr>
              <tr>
                <th>Post Code</th>
                <td>{{ door.postCode }}</td>
              </tr>
              <tr>
                <th>Intended Fire Rating</th>
                <td>{{ door.intendedFireRating }}</td>
              </tr>
              <tr>
                <th>Door Style</th>
                <td>{{ door.doorStyle }}</td>
              </tr>
              <tr>
                <th>External Colour</th>
                <td>{{ door.doorStyle }}</td>
              </tr>
              <tr>
                <th>Lock Options</th>
                <td>{{ door.lockOption }}</td>
              </tr>
              <tr>
                <th>Chain</th>
                <td>{{ door.chain }}</td>
              </tr>
              <tr>
                <th>Letter Plate</th>
                <td>{{ door.letterPlate }}</td>
              </tr>
              <tr>
                <th>Knocker</th>
                <td>{{ door.doorKnocker }}</td>
              </tr>
            </table>
          </v-col>
          <v-col>
            <table class="details-table">
              <tr>
                <th>Existing Opening</th>
                <td>{{ door.existingOpening }}</td>
              </tr>
              <tr>
                <th>Frame Type</th>
                <td>{{ door.frameType }}</td>
              </tr>
              <tr>
                <th>Frame Dimms (w x h)</th>
                <td>{{ door.width }} x {{ door.height }}</td>
              </tr>
              <tr>
                <th>Fanlight</th>
                <td>
                  {{ frameDimms.fanlightWidth }} x
                  {{ frameDimms.fanlightHeight }}
                </td>
              </tr>
              <tr>
                <th>Left Side Width</th>
                <td>{{ frameDimms.leftSideWidth }}</td>
              </tr>
              <tr>
                <th>Right Side Width</th>
                <td>{{ frameDimms.rightSideWidth }}</td>
              </tr>
            </table>
          </v-col>
        </NarrowRow>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-title>Select Doors</v-card-title>
      <v-card-subtitle>to paste details to</v-card-subtitle>
      <v-data-table
        v-model="selected"
        :items="doorsToSelect"
        :headers="headers"
        disable-pagination
        show-select
      />
      <v-card-actions>
        <v-btn
          color="success"
          @click="pasteDoor"
          :loading="pasting"
          :disabled="!itemsSelected"
        >
          {{ pasteButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="pasting" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Pasting Doors
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import DoorBreadcrumbs from "@gradys/vue-base/components/door/door-breadcrumbs";
import FrameWithDimmLines from "@gradys/vue-base/components/frame/frame-with-dimm-lines";

export default {
  components: {
    DoorBreadcrumbs,
    FrameWithDimmLines
  },
  data() {
    return {
      loaded: false,
      loading: true,
      pasting: false,
      pasted: false,
      pasteError: false,
      selected: [],
      headers: [
        {
          text: "Numeral",
          value: "numerals"
        },
        {
          text: "Address",
          value: "address"
        },
        {
          text: "Post Code",
          value: "postCode"
        },
        {
          text: "Block",
          value: "block"
        },
        {
          text: "Location",
          value: "location"
        },
        {
          text: "Floor",
          value: "floor"
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Frame Width",
          value: "width"
        },
        {
          text: "Frame Height",
          value: "height"
        },
        {
          text: "Frame Type",
          value: "frameType"
        }
      ]
    };
  },
  computed: {
    door: get("doors/door"),
    order: get("orders/order"),
    doors: get("orders/doors"),
    doorsToSelect() {
      return this.doors.filter(d => d.id !== this.door.id);
    },
    doorName: get("doors/doorName"),
    frameDimms: get("doors/frameDimms"),
    itemsSelected() {
      return this.selected && this.selected.length;
    },
    pasteButtonText() {
      if (!this.itemsSelected) return "Paste to Door";

      return `Paste to ${this.selected.length} Door(s)`;
    }
  },
  created() {
    return this.load();
  },
  methods: {
    fetchDoor: call("doors/fetchDoor"),
    paste: call("doors/paste"),
    fetchOrder: call("orders/fetchOrder"),
    async load() {
      this.loading = true;

      try {
        await this.fetchDoor(this.$route.params.doorId);
        await this.fetchOrder(this.door.orderId);
      } finally {
        this.loading = false;
      }
    },
    async pasteDoor() {
      this.pasting = true;
      this.pasteError = false;

      try {
        await this.paste(this.selected);
        await this.load();

        this.pasted = true;

        setTimeout(() => {
          this.pasted = false;
        }, 3000);
      } catch {
        this.pasteError = true;
      } finally {
        this.pasting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.details-table {
  th {
    text-align-last: left;
    min-width: 160px;
    padding: 4px 10px 4px 0px;
  }
  th,
  td {
    border-bottom: 1px solid #ccc;
  }
}
</style>
