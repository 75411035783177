<template>
  <div>
    <NarrowRow>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="fanlightPackerLeft"
          label="Fanlight Packer Left"
          :disabled="!hasFanlight"
          :items="packers"
        />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="fanlightPackerRight"
          :disabled="!hasFanlight"
          label="Fanlight Packer Right"
          :items="packers"
        />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="intendedFireRating"
          label="Intended Fire Rating"
          :items="fireRatings"
        />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="frameMaterial"
          label="Frame Material"
          :items="frameMaterials"
        />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="doorThickness"
          label="Door Thickness"
          :items="doorThicknesses"
        />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect v-model="rainDeflector" label="Rain Deflector" />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect v-model="opens" label="Door Opens" :items="openOptions" />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="doorHanding"
          label="Door Handing"
          :items="doorHandings"
        />
      </v-col>
    </NarrowRow>
    <FrameFinishSelect
      :frame-finish="frameFinish"
      :frame-finishes="frameFinishes"
      @on:colour-changed="frameFinishColourChanged"
    />
    <v-divider class="my-4"></v-divider>
    <NarrowRow>
      <v-col cols="6" md="3">
        <v-select
          label="Fire Integrity"
          :items="[30, 60, 0]"
          disabled
          v-model="door.fireIntegrity"
        />
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          label="Insulation Rating"
          :items="[0, 30, 60]"
          v-model="insulationRating"
        />
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          label="Smoke Control"
          :items="['SA', 'No Smoke Control']"
          v-model="smokeControl"
        />
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          label="Thermal Performance"
          :items="[1.8, 5]"
          v-model="thermalPerformance"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          label="CE (EN 14351-1: 2006+A2: 2016 & En 16034:2014)"
          :items="[
            'NA',
            '1812-CPR-1733_22-0_GRADY_UK_FD30 44MM',
            '1812-CPR-1734_22-0_GRADY_UK_FD60 54MM'
          ]"
          v-model="doorCE"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          label="BM Trada Certification"
          :items="[
            'NA',
            'BMTrada Dual Fire and Secuity (050/261)',
            'BMTrada Fire door only (006/1214)'
          ]"
          v-model="bmTradaCertification"
        />
      </v-col>
      <v-col cols="6" md="3">
        <v-select label="SBD" :items="['Yes', 'No']" v-model="sbd" />
      </v-col>
      <v-col cols="6" md="3">
        <BaseSelect
          v-model="thresholdLayout"
          label="Threshold Layout"
          :items="thresholdLayouts"
        />
      </v-col>
    </NarrowRow>
  </div>
</template>

<script>
import FrameFinishSelect from "@gradys/vue-base/components/forms/frame-finish-select";
import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";
import { get } from "vuex-pathify";

export default {
  mixins: [mixin],
  components: { FrameFinishSelect },
  data() {
    return {
      dialog: false,
      pane: {
        width: 0,
        height: 0,
        index: 0,
        type: "",
        material: null
      }
    };
  },
  computed: {
    door: get("doors/door"),
    hasFanlight: get("doors/hasFanlight"),
    ...syncProp("opens"),
    ...syncProp("thresholdLayout"),
    ...syncProp("rainDeflector"),
    ...syncProp("frameFinish"),
    ...syncProp("doorThickness"),
    ...syncProp("frameMaterial"),
    ...syncProp("doorHanding"),
    ...syncProp("fanlightPackerLeft"),
    ...syncProp("fanlightPackerRight"),
    ...syncProp("insulationRating"),
    ...syncProp("smokeControl"),
    ...syncProp("thermalPerformance"),
    ...syncProp("sbd"),
    ...syncProp("doorCE"),
    ...syncProp("bmTradaCertification"),
    frameMaterials: get("lookups/frameMaterials"),
    doorThicknesses: get("lookups/doorThicknesses"),
    frameFinishes: get("lookups/frameFinishes"),
    openOptions: get("lookups/openOptions"),
    thresholdLayouts: get("lookups/thresholdsLayouts"),
    doorHandings: get("lookups/doorHandings"),
    fireRatings: get("lookups/fireRatings"),
    packers: get("lookups/packers"),
    intendedFireRating: {
      get() {
        return this.door.intendedFireRating;
      },
      set(value) {
        if (value === "FD30") {
          this.door.fireIntegrity = 30;
        } else if (value === "FD60") {
          this.door.fireIntegrity = 60;
        } else if (value === "NonFireRated") {
          this.door.fireIntegrity = 0;
        }
        this.updateDoor("intendedFireRating", value);
      }
    }
  },
  methods: {
    frameFinishColourChanged(value) {
      this.$emit("on:door-prop-updated", { prop: "frameFinish", value });
    }
  }
};
</script>
