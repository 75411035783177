<template>
  <v-container v-if="door" class="pa-0">
    <div :class="{ sheet: showAsPrint, 'padding-10mm': showAsPrint }">
      <div class="door-set__header">
        <DoorSetInfo label="Door Address" :value="door.address" />
        <DoorSetInfo label="Location" :value="door.location" />
        <DoorSetInfo label="Order Ref" :value="orderRef" />
        <DoorSetInfo label="Post Code" :value="door.postCode" />
        <DoorSetInfo
          label="Intended Fire Rating"
          :value="door.intendedFireRating"
        />
      </div>
      <div class="door-set__image-top">
        <div class="head-threshold pt-6">
          <BomImage :door="door" :image="headImage" :title="true">
            <HeadImage class="head-img" :show-select="false" :door="door" />
          </BomImage>
          <BomImage :door="door" :image="thresholdImage" :title="true">
            <ThresholdSelect
              class="threshold-img"
              :door="door"
              :show-select="false"
              :threshold-image-name="thresholdImageName"
            />
          </BomImage>
        </div>
        <BomImage :door="door" :image="frontImage" :title="true">
          <div class="frame-img" id="frame-img">
            <FrameWithDimmLines :door="door" class="diagram" />
          </div>
        </BomImage>
      </div>
      <div class="door-set__jamb-section">
        <div class="set-info">
          <v-container class="pa-0">
            <v-row dense>                   
              <v-col cols="6">
                <DoorSetInfo
                  label="Frame Material"
                  :value="door.frameMaterial"
                />
              </v-col>
              <v-col cols="6">
                <DoorSetInfo 
                label="Sash Material" 
                :value="door.sashMaterial" />
              </v-col>
              <v-col cols="6">
                <DoorSetInfo
                  label="Door Thickness"
                  :value="door.doorThickness"
                />
              </v-col>
              <v-col cols="6">
                <DoorSetInfo label="Frame Finish" :value="door.frameFinish" />
              </v-col>
              <v-col cols="6">
                <DoorSetInfo
                  label="Rain Deflector"
                  :value="door.rainDeflector"
                />
              </v-col>
              <v-col cols="6">
                <DoorSetInfo label="Door Opens" :value="door.opens" />
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="jamb-img">
          <BomImage :door="door" :image="jambImage" :title="true">
            <JambImage :show-select="false" :door="door" />
          </BomImage>
        </div>
      </div>
      <v-container class="pa-0">
        <v-row dense>
          <v-col cols="2">
            <DoorSetInfo label="Door Handing" :value="door.doorHanding" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Door Style" :value="door.doorStyle" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Chain" :value="door.chain" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Letter Plate" :value="door.letterPlate" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Lock Option" :value="door.lockOption" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Signage" :value="door.signage" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Kick Plate" :value="door.kickPlate" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Numerals" :value="door.numerals" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Door Knocker" :value="door.doorKnocker" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Spyhole @ 1200" :value="door.spyholeAt1200" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Spyhole @ 1500" :value="door.spyholeAt1500" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Closer" :value="closerText" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="External Hardware Suite Colour" :value="door.suiteColour"
            />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="Internal Colour" :value="door.internalColour" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="External Colour" :value="door.externalColour" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo label="New Clear Opening" :value="newClearOpening" />
          </v-col>
          <v-col cols="2">
            <DoorSetInfo
              label="Exisiting Clear Opening"
              :value="door.existingOpening"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- END PAGE 1 -->
    <div :class="{ sheet: showAsPrint, 'padding-10mm': showAsPrint }">
      <v-divider v-if="!showAsPrint" class="my-8"></v-divider>
      <v-row dense>
        <v-col>
          <div class="subtitle-2 mb-2">Installation Details</div>
          <table class="packer--table mt-2 mb-4">
            <tr>
              <th></th>
              <th>Top</th>
              <th>Left</th>
              <th>Right</th>
              <th>Threshold</th>
            </tr>
            <tr>
              <th>Packer 1</th>
              <td>{{ door.topPacker1 }}</td>
              <td>{{ door.leftPacker1 }}</td>
              <td>{{ door.rightPacker1 }}</td>
              <td>{{ door.thresholdPacker1 }}</td>
            </tr>
            <tr>
              <th>Packer 2</th>
              <td>{{ door.topPacker2 }}</td>
              <td>{{ door.leftPacker2 }}</td>
              <td>{{ door.rightPacker2 }}</td>
              <td>{{ door.thresholdPacker2 }}</td>
            </tr>
            <tr>
              <th>Liner Internal</th>
              <td>{{ door.topLiningInternal }}</td>
              <td>{{ door.leftLiningInternal }}</td>
              <td>{{ door.rightLiningInternal }}</td>
            </tr>
            <tr>
              <th>Architrave 1 Internal</th>
              <td>{{ door.topArchitraveInternal1 }}</td>
              <td>{{ door.leftArchitraveInternal1 }}</td>
              <td>{{ door.rightArchitraveInternal1 }}</td>
            </tr>
            <tr>
              <th>Architrave 2 Internal</th>
              <td>{{ door.topArchitraveInternal2 }}</td>
              <td>{{ door.leftArchitraveInternal2 }}</td>
              <td>{{ door.rightArchitraveInternal2 }}</td>
            </tr>
            <tr>
              <th>Liner External</th>
              <td>{{ door.topLiningExternal }}</td>
              <td>{{ door.leftLiningExternal }}</td>
              <td>{{ door.rightLiningExternal }}</td>
            </tr>
            <tr>
              <th>Architrave 1 External</th>
              <td>{{ door.topArchitraveExternal1 }}</td>
              <td>{{ door.leftArchitraveExternal1 }}</td>
              <td>{{ door.rightArchitraveExternal1 }}</td>
            </tr>
            <tr>
              <th>Architrave 2 External</th>
              <td>{{ door.topArchitraveExternal2 }}</td>
              <td>{{ door.leftArchitraveExternal2 }}</td>
              <td>{{ door.rightArchitraveExternal2 }}</td>
            </tr>
            <tr>
              <td colspan="4" class="py-3"></td>
            </tr>
            <tr>
              <th>Fanlight</th>
              <th>Left</th>
              <th>Right</th>
            </tr>
            <tr>
              <th>Packer</th>
              <td>{{ door.fanlightPackerLeft }}</td>
              <td>{{ door.fanlightPackerRight }}</td>
            </tr>
          </table>

          <DoorSetInfo
            label="Method of Sealing to Structural Opening"
            :value="door.methodOfSealingOpening"
          />
        </v-col>
      </v-row>
    </div>
    <!-- END PAGE 2 -->
  </v-container>
</template>

<script>
import HeadImage from "@gradys/vue-base/components/forms/head-image";
import JambImage from "@gradys/vue-base/components/forms/jamb-image";
import ThresholdSelect from "@gradys/vue-base/components/forms/threshold-select";
import DoorSetInfo from "@gradys/vue-base/components/review/door-set-info";
import FrameWithDimmLines from "@gradys/vue-base/components/frame/frame-with-dimm-lines";
import BomImage from "@/components/images/bom-image.vue";
import { get } from "vuex-pathify";

export default {
  components: {
    HeadImage,
    ThresholdSelect,
    JambImage,
    DoorSetInfo,
    FrameWithDimmLines,
    BomImage
  },
  props: {
    door: {
      type: Object,
      required: true
    },
    thresholdImageName: {
      type: String,
      required: true
    },
    orderRef: {
      type: String,
      default: "-"
    },
    newClearOpening: {
      type: [Number, String],
      required: true,
      default: 0
    },
    closers: {
      type: Array,
      default: () => []
    },
    showAsPrint: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    frontImage: get("doors/frontImage"),
    headImage: get("doors/headImage"),
    jambImage: get("doors/jambImage"),
    thresholdImage: get("doors/thresholdImage"),
    closerText() {
      if (!this.door || !this.door.closer) return "";
      if (!this.closers) return "";

      const matchedCloser = this.closers.find(
        c => c.value === this.door.closer
      );

      return matchedCloser ? matchedCloser.text : this.door.closer;
    }
  }
};
</script>

<style lang="scss">
.door-set__header {
  display: flex;

  .door-set-info {
    flex: 1;
  }
}

.door-set__image-top {
  display: flex;  
  justify-content: space-between;
  max-width: 1200px;
  
  .head-threshold {
    padding-right: 1rem;
    min-width: 300px;
    max-width: 450px;
  }

  .frame-img {
    padding-top: 5px;
    max-width: 1600px;
  }
}

.door-set__jamb-section {
  display: flex;
  justify-content: space-between;

  .jamb-img {    
    flex: 1;
    padding-top: 1rem;
    max-width: 650px;
    min-width: 550px;
  }
}

.svg__wrap {
  max-width: 100%;
  height: 450px;
  min-width: 320px;
  padding: 4px;
}
.packer--table {
  width: 100%;
}
.packer--table th {
  text-align: left;
}
.packer--table td,
.packer--table th {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
</style>
