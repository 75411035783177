<template>
  <div class="door-set-info">
    <div class="caption black--text">{{ label }}</div>
    <div class="mb-1 black--text">{{ value || "-" }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String],
      default: ""
    }
  }
};
</script>
