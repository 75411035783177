<template>
  <g>
    <defs>
      <DoorKnockerDef />
    </defs>
    <g v-if="showLetterPlate">
      <image
        v-if="goldSuite"
        :x="letterPlateXY.x"
        :y="letterPlateXY.y"
        height="22"
        href="https://storage.googleapis.com/door-element-images/letter-plates/gold.png"
      /> 
      <image
        v-else
        :x="letterPlateXY.x"
        :y="letterPlateXY.y"
        height="22"
        href="https://storage.googleapis.com/door-element-images/letter-plates/chrome.png"
      />
    </g>    
    <g id="center" v-if="showCenter">
      <ellipse v-if="showSpyhole1500" cx="99" cy="112.35" rx="2.78" ry="2.73" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
      <ellipse v-if="showSpyhole1200" cx="99" cy="183.21" rx="2.78" ry="2.73" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
      <use v-show="showKnocker" transform="translate(74 123.25) scale(0.03 0.03)" xlink:href="#image"/>
    </g>
    <g id="top-right" v-if="showTopRight">
      <ellipse v-if="showSpyhole1500" cx="175" cy="76.61" rx="2.78" ry="2.73" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
      <ellipse v-if="showSpyhole1200" cx="175" cy="147.47" rx="2.78" ry="2.73" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
      <use v-show="showKnocker" transform="translate(150 87.51) scale(0.03 0.03)" xlink:href="#image"/>
    </g>
    <g id="top-right" v-if="showTopLeft">
      <ellipse v-if="showSpyhole1500" cx="17" cy="76.61" rx="2.78" ry="2.73" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
      <ellipse v-if="showSpyhole1200" cx="17" cy="147.47" rx="2.78" ry="2.73" fill="#7fbfff" stroke="#000" stroke-miterlimit="10" stroke-width="0.5"/>
      <use v-show="showKnocker" transform="translate(-7 87.51) scale(0.03 0.03)" xlink:href="#image"/>
    </g>
    <DoorHandle :door="door" />
    <PullHandle />
    <PullHandleLock />
    <FingerPull />
    <g id="numerals">
      <text
        :x="numeralX" :y="numeralY"
        font-size="14"
        fill="#777777"
        text-anchor="middle"
      >
        {{ door.numerals }}
      </text>
    </g>
  </g>
</template>

<script>
import DoorKnockerDef from './pieces/door-knocker-def';
import DoorHandle from './pieces/door-handle';
import PullHandle from './pieces/pull-handle';
import PullHandleLock from './pieces/pull-handle-lock';
import FingerPull from './pieces/finger-pull';
import { doorStylesOptions } from '@gradys/vue-base/store/lookups/lookup-values';

const letterPlateLocations = {
  CenterLow: { x: 65, y: 232 },
  CenterMid: { x: 65, y: 210 },
  CenterHigh: { x: 65, y: 153 },
  OffLeft: { x: 35, y: 235 },
  OffRight: { x: 90, y: 230 },
  Dromoland: { x: 67, y: 237 },
};

export default {
  components: {
    DoorKnockerDef,
    DoorHandle,
    PullHandle,
    PullHandleLock,
    FingerPull
  },
  props: {
    door: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  computed: {
    doorOption() {
      if (!this.door || !this.door.id) return {};

      const match = doorStylesOptions.find(d => d.value === this.door.doorStyle);
      return match ?? {};
    },
    showLetterPlate() {
      if (!this.door.letterPlate || this.door.letterPlate === "No") return false;

      return true;
    },
    goldSuite() {
      return this.door.suiteColour === "Gold";
    },
    letterPlateXY() {
      const location = letterPlateLocations[this.doorOption.letterPlateLocation];

      if (!location) return { x: 65, y: 230 };
      
      return location;
    },   
    showCenter() {
      return this.doorOption.knockerLocation === "Center";
    },
    showTopRight() {
      return this.door.doorHanding === "Left" &&  this.doorOption.knockerLocation === "TopRight";
    },
    showTopLeft() {
      return this.door.doorHanding === "Right" && this.doorOption.knockerLocation === "TopRight";
    },
    showSpyhole1200() {
      return this.door.spyholeAt1200 === "Yes";
    },
    showSpyhole1500() {
      return this.door.spyholeAt1500 === "Yes";
    },
    showKnocker() {
      return this.door.doorKnocker === "Yes";
    },
    showLeftHandle() {
      return this.door.doorHanding === "Right";
    },
    showRightHandle() {
      return this.door.doorHanding === "Left";
    },
    numeralDefault() {
      if (
        !this.door.numeralsPosition ||
        this.door.numeralsPosition === "Default"
      ) {
        return true;
      }

      return false;
    },
    numeralX() {
      if (this.numeralDefault)
        return "98";

      if (this.door.doorHanding === "Left")
        return "35";

      return "150";
    },
    numeralY() {
      if (this.numeralDefault)
        return "20";
      if(this.doorOption.letterPlateLocation === "CenterLow")
        return "255";

      if(this.doorOption.letterPlateLocation === "CenterMid")
        return "225";

      if(this.doorOption.letterPlateLocation === "CenterHigh")
        return "170";

      if (this.doorOption.letterPlateLocation === "OffLeft")
        return "248";

      return "255";
    }
  },
}
</script>
