<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline error white--text">
          Form Errors
        </v-card-title>

        <v-card-text class="pt-4">
          <div class="subtitle-1">
            There are some form errors, please review before submitting
          </div>
          <ul class="mt-4">
            <li v-for="error in allErrors" :key="error.key">
              {{ error.error }}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-btn color="green darken-1" text @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider class="my-8"></v-divider>
    <div
      v-for="warning in openingWarnings"
      :key="warning.key"
      class="warning--text mb-4"
    >
      {{ warning.error }}
    </div>
    <div>
      <v-btn color="primary" @click="submitSurvey" :loading="loading">
        Submit Survey
      </v-btn>
      <SurveyStatus :status="door.status" :show-not-complete="false" />
    </div>
  </div>
</template>

<script>
import SurveyStatus from "./survey-status";

export default {
  props: {
    door: {
      type: Object,
      required: true
    },
    openingErrors: {
      type: Array,
      default: () => []
    },
    openingWarnings: {
      type: Array,
      default: () => []
    },
    doorDesignErrors: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SurveyStatus
  },
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  computed: {
    allErrors() {
      return this.openingErrors.concat(this.doorDesignErrors);
    }
  },
  methods: {
    upload() {
      this.$emit("on:upload");
    },
    setLoading(value) {
      this.loading = value;
    },
    submitSurvey() {
      if (this.allErrors.length) {
        this.dialog = true;
      } else {
        this.upload();
      }
    }
  }
};
</script>
