<template>
  <div v-if="quote && door">
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="pt-2 pb-4 px-0">
    </v-breadcrumbs>
    <v-dialog v-model="paneDialog" max-width="650" persistent>
      <v-card>
        <v-card-title>Set Pane Material : {{ panelSubtitle }}</v-card-title>
        <v-card-text>
          <BaseSelect
            label="Material"
            v-if="pane.type !== 'door'"
            v-model="pane.material"
            :items="materials"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="savePane">Set Material</v-btn>
          <v-btn text color="error" @click="paneDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="mb-2">
      <v-card-actions>
        <v-btn small color="primary" :loading="saving" @click="backToQuote">
          Back to Quote
        </v-btn>
        <span class="caption ml-8">
          <v-icon color="info">mdi-information-outline</v-icon> Prices are
          automatically updated
        </span>
      </v-card-actions>
    </v-card>
    <div class="layout">
      <div class="forms" :style="formsStyle">
        <FrameSelection
          @on:frame-clicked="frameTypeChanged"
          :limit-height="false"
        />
        <v-card>
          <v-card-title>Frame Size</v-card-title>
          <v-card-text>
            <div class="mt-2">
              <strong>Frame Opening</strong>
            </div>
            <div class="side-by-side">
              <v-text-field
                label="Frame Width"
                type="number"
                :value="frameDimms.frameWidth"
                @change="onWidthUpdated"
              />
              <v-text-field
                label="Frame Height"
                type="number"
                :value="frameDimms.frameHeight"
                @change="onHeightUpdated"
              />
            </div>
            <div class="mt-2">
              <strong>Door Dimensions</strong>
            </div>
            <div class="side-by-side">
              <v-text-field
                label="Door Width"
                type="number"
                append-icon="mdi-lock"
                background-color="grey lighten-3"
                readonly
                :value="frameDimms.doorWidth"
              />
              <v-text-field
                label="Door Height"
                type="number"
                readonly
                background-color="grey lighten-3"
                append-icon="mdi-lock"
                :value="frameDimms.doorHeight"
              />
            </div>
            <div class="mt-2">
              <strong>Fanlight Dimension</strong>
            </div>
            <div class="side-by-side">
              <v-text-field
                label="Fanlight Width"
                type="number"
                :disabled="!anyFanlight"
                readonly
                background-color="grey lighten-3"
                append-icon="mdi-lock"
                :value="frameDimms.fanlightWidth"
              />
              <v-text-field
                label="Fanlight Height"
                :disabled="!anyFanlight"
                type="number"
                :value="frameDimms.fanlightHeight"
                @change="onFanlightHeightUpdated"
              />
            </div>
            <div class="mt-2">
              <strong>Sidepanel Widths</strong>
            </div>
            <div class="side-by-side">
              <v-text-field
                label="Right Width"
                :disabled="!showRt"
                type="number"
                :value="frameDimms.rightSideWidth"
                @change="onRightUpdated"
              />
              <v-text-field
                label="Left Width"
                type="number"
                :disabled="!showLt"
                :value="frameDimms.leftSideWidth"
                @change="onLeftWidthUpdated"
              />
            </div>
            <div class="mt-2">
              <strong>Sidepanel Heights</strong>
            </div>
            <div class="side-by-side">
              <v-text-field
                label="Top Sidelight"
                type="number"
                :disabled="!anySidePanel"
                :value="frameDimms.topSidelightHeight"
                @change="onTopHeightUpdated"
              />
              <v-text-field
                label="Bottom Sidelight"
                type="number"
                readonly
                :disabled="!anySidePanel"
                background-color="grey lighten-3"
                append-icon="mdi-lock"
                :value="frameDimms.bottomSidelightHeight"
              />
            </div>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>Frame Design</v-card-title>
          <v-card-text>
            <NarrowRow>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="fanlightPackerLeft"
                  label="Fanlight Packer Left"
                  :disabled="!hasFanlight"
                  :items="packers"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="fanlightPackerRight"
                  :disabled="!hasFanlight"
                  label="Fanlight Packer Right"
                  :items="packers"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="intendedFireRating"
                  label="Intended Fire Rating"
                  :items="fireRatings"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="frameMaterial"
                  label="Frame Material"
                  :items="frameMaterials"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="doorThickness"
                  label="Door Thickness"
                  :items="doorThicknesses"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="frameFinish"
                  label="Frame Finish"
                  :items="frameFinishes"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect v-model="rainDeflector" label="Rain Deflector" />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="opens"
                  label="Door Opens"
                  :items="openOptions"
                />
              </v-col>
              <v-col cols="6" md="3">
                <BaseSelect
                  v-model="doorHanding"
                  label="Door Handing"
                  :items="doorHandings"
                />
              </v-col>
            </NarrowRow>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>Door Design</v-card-title>
          <v-card-text>
            <DoorDesignForm :door="door" @on:door-prop-updated="updateDoor" />
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>Frame Details</v-card-title>
          <v-card-text>
            <NarrowRow>
              <v-col cols="12" md="6">
                <HeadImage :door="door" @on:door-prop-updated="updateDoor" />
                <ThresholdSelect
                  :door="door"
                  :thresholds="thresholds"
                  :threshold-image-name="thresholdImageName"
                  @on:door-prop-updated="updateDoor"
                />
              </v-col>
              <v-col cols="12" md="6">
                <JambImage :door="door" @on:door-prop-updated="updateDoor" />
              </v-col>
            </NarrowRow>
          </v-card-text>
        </v-card>
      </div>
      <div class="image">
        <v-card>
          <v-card-text class="text-center">
            <FrameWithDimmLines :door="door" @on:pane-clicked="paneClicked" />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import DoorDesignForm from "@gradys/vue-base/components/forms/door-design-form";
import FrameSelection from "@gradys/vue-base/components/frame/frame-selection";
import FrameWithDimmLines from "@gradys/vue-base/components/frame/frame-with-dimm-lines";
import HeadImage from "@gradys/vue-base/components/forms/head-image";
import JambImage from "@gradys/vue-base/components/forms/jamb-image";
import ThresholdSelect from "@gradys/vue-base/components/forms/threshold-select";

import { vueWindowSizeMixin } from "vue-window-size";
import frameMixin from "@gradys/vue-base/components/frame/frame-mixin-2";
import { updateType } from "@gradys/vue-base/store/util/frame-sizing";

export function syncProp(prop) {
  return {
    [prop]: {
      get() {
        return this.door[prop];
      },
      set(value) {
        return this.updateDoor({ prop, value });
      }
    }
  };
}

export default {
  mixins: [vueWindowSizeMixin, frameMixin],
  components: {
    DoorDesignForm,
    FrameSelection,
    FrameWithDimmLines,
    HeadImage,
    JambImage,
    ThresholdSelect
  },
  data() {
    return {
      paneDialog: false,
      saving: false,
      pane: {
        width: 0,
        height: 0,
        index: 0,
        type: "",
        material: null
      }
    };
  },
  computed: {
    quote: get("quotes/quote"),
    door: get("doors/door"),
    frameDimms: get("doors/frameDimms"),
    hasFanlight: get("doors/hasFanlight"),
    thresholdImageName: get("doors/thresholdImageName"),
    materials: get("lookups/materials"),
    frameMaterials: get("lookups/frameMaterials"),
    doorThicknesses: get("lookups/doorThicknesses"),
    frameFinishes: get("lookups/frameFinishes"),
    openOptions: get("lookups/openOptions"),
    doorHandings: get("lookups/doorHandings"),
    fireRatings: get("lookups/fireRatings"),
    packers: get("lookups/packers"),
    thresholds: get("lookups/thresholds"),

    ...syncProp("opens"),
    ...syncProp("rainDeflector"),
    ...syncProp("frameFinish"),
    ...syncProp("doorThickness"),
    ...syncProp("frameMaterial"),
    ...syncProp("intendedFireRating"),
    ...syncProp("doorHanding"),
    ...syncProp("fanlightPackerLeft"),
    ...syncProp("fanlightPackerRight"),

    formsStyle() {
      const formsSize = this.windowHeight - 225;
      return {
        "overflow-y": "auto",
        height: `${formsSize}px`
      };
    },
    panelSubtitle() {
      if (!this.pane.type) return "";

      switch (this.pane.type) {
        case "door":
          return "Door";
        case "lb":
          return "Left Bottom";
        case "lt":
          return "Left Top";
        case "rb":
          return "Right Bottom";
        case "rt":
          return "Right Top";
        case "fl":
        case "wfl-l":
          return "Fanlight Left";
        case "fr":
        case "wfl-r":
          return "Fanlight Right";
      }
      return this.pane.type;
    },
    breadcrumbs() {
      if (!this.quote) return [];

      const { reference, id } = this.quote;

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Quotes ",
          to: "/quotes",
          exact: true
        },
        {
          text: reference,
          to: `/quotes/${id}`,
          exact: true
        },
        {
          text: "Configure Door"
        }
      ];
    }
  },
  async created() {
    const { id, doorId } = this.$route.params;
    await Promise.all([this.loadQuote(id), this.fetchDoor(doorId)]);
  },
  methods: {
    loadQuote: call("quotes/loadQuote"),
    fetchDoor: call("doors/fetchDoor"),
    frameSelected: call("doors/frameSelected"),
    updateDimm: call("doors/updateDimm"),
    updatePane: call("doors/updatePane"),
    updateDoorProp: call("doors/updateDoor"),
    saveDoor: call("doors/save"),
    async frameTypeChanged(frameType) {
      this.frameSelected(frameType);
      await this.saveDoor();
    },
    paneClicked(pane) {
      const index = this.door.panes.findIndex(f => f.type === pane);
      this.editPane(index);
    },
    editPane(index) {
      this.pane = {
        ...this.door.panes[index]
      };
      this.pane.index = index;
      this.paneDialog = true;
    },
    async savePane() {
      this.updatePane(this.pane);
      this.paneDialog = false;
      await this.saveDoor();
    },
    updateDoor(change) {
      this.updateDoorProp(change);
      return this.saveDoor();
    },
    onWidthUpdated(value) {
      this.updateDimm({ updateType: updateType.frameWidth, value });
      return this.saveDoor();
    },
    onHeightUpdated(value) {
      this.updateDimm({ updateType: updateType.frameHeight, value });
      return this.saveDoor();
    },
    onFanlightHeightUpdated(value) {
      this.updateDimm({ updateType: updateType.fanlightHeight, value });
      return this.saveDoor();
    },
    onLeftWidthUpdated(value) {
      this.updateDimm({ updateType: updateType.leftSideWidth, value });
      return this.saveDoor();
    },
    onRightUpdated(value) {
      this.updateDimm({ updateType: updateType.rightSideWidth, value });
      return this.saveDoor();
    },
    onTopHeightUpdated(value) {
      this.updateDimm({ updateType: updateType.topSidelightHeight, value });
      return this.saveDoor();
    },
    async backToQuote() {
      this.$router.push(`/quotes/${this.quote.id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  .forms {
    .v-card {
      margin-bottom: 1rem;
    }
  }
}
.side-by-side {
  display: flex;

  .v-input {
    max-width: 150px;
    margin-right: 2rem;
  }
}
</style>
