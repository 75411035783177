<template>
  <div>
    <div v-if="order && order.id">
      <DoorBreadcrumbs
        :door="door"
        :order="order"
        pageName="Bill Of Materials"
      />
      <v-card :loading="loading">
        <v-card-actions>
          <v-btn color="primary" small @click="print">Print</v-btn>
          <v-btn small color="primary" :to="`/doors/${door.id}`">
            Door Builder
          </v-btn>
          <v-btn
            small
            :to="`/orders/${order.id}/doors/${door.id}`"
            color="primary"
          >
            View Door Set
          </v-btn>
          <div class="ml-8 caption" v-if="bom && bom.id">
            Saved on: {{ bom.created | euDateTime }}
          </div>
          <div v-else class="ml-8">
            <v-btn small color="primary" @click="saveBom" :loading="loading">
              Save to SQL
            </v-btn>
            <v-chip small color="warning" class="ml-8">
              Calculated
            </v-chip>
          </div>
        </v-card-actions>
      </v-card>
    </div>
    <BillOfMaterials v-if="loaded" ref="billOfMaterials" />
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import BillOfMaterials from "@/components/review/bill-of-materials.vue";
import DoorBreadcrumbs from "@gradys/vue-base/components/door/door-breadcrumbs";

export default {
  components: {
    BillOfMaterials,
    DoorBreadcrumbs
  },
  data() {
    return {
      tab: 0,
      loaded: false,
      loading: true
    };
  },
  computed: {
    door: get("doors/door"),
    order: get("orders/order"),
    doors: get("orders/doors"),
    bom: get("billOfMaterials/billOfMaterials")
  },
  created() {
    return this.load();
  },
  watch: {
    $route() {
      return this.load();
    }
  },
  methods: {
    print() {
      window.print();
    },
    fetchDoor: call("doors/fetchDoor"),
    fetchOrder: call("orders/fetchOrder"),
    loadBillOfMaterials: call("billOfMaterials/loadBillOfMaterials"),
    saveBillOfMaterials: call("billOfMaterials/saveBillOfMaterials"),
    async load() {
      try {
        this.loading = true;

        const { orderId, doorId } = this.$route.params;
        await Promise.all([this.fetchOrder(orderId), this.fetchDoor(doorId)]);

        const bom = await this.loadBillOfMaterials(doorId);

        if (!bom) {
          await this.calculateBillOfMaterials(this.door);
        }

        this.loaded = true;
      } finally {
        this.loading = false;
      }
    },
    async saveBom() {
      try {
        this.loading = true;
        await this.saveBillOfMaterials(this.door.id);
        await this.loadBillOfMaterials(this.door.id);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
