<template>
  <div>
    <v-divider class="mb-2 mt-4"></v-divider>
    <div ref="gridWrap" class="grid--wrap" :style="wrapperStyle">
      <hot-table :settings="settings"> </hot-table>
    </div>
    <v-dialog v-model="saving" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Updating Doors
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import { HotTable } from "@handsontable/vue";

export default {
  components: {
    HotTable
  },
  data() {
    return {
      saving: false,
      gridHeight: 100,
      wrapperStyle: {
        height: "100px"
      }
    };
  },
  computed: {
    columns() {
      return [
        { header: "Numerals", data: "numerals", type: "text" },
        { header: "Address", data: "address", type: "text", width: 500 },
        { header: "Post Code", data: "postCode", type: "text" },
        { header: "Block", data: "block", type: "text" },
        { header: "Location", data: "location", type: "text", width: 120 },
        { header: "Floor", data: "floor", type: "text" },
        {
          header: "Intended Fire Rating",
          data: "intendedFireRating",
          type: "dropdown",
          source: this.fireRatings,
          width: 180
        },
        {
          header: "Existing Clear Opening",
          data: "existingOpening",
          type: "text",
          width: 170
        }
      ];
    },
    colData() {
      return this.columns.map(c => {
        const {
          data,
          type,
          source,
          strict,
          allowInvalid,
          filter,
          readOnly
        } = c;
        return {
          data,
          type,
          source,
          strict,
          allowInvalid,
          filter,
          readOnly
        };
      });
    },
    colHeaders() {
      return this.columns.map(c => c.header);
    },
    ...mapState({
      fireRatings: state => state.lookups.fireRatings,
      doors: state => cloneDeep(state.orders.doors)
    }),
    settings() {
      return {
        width: "100%",
        height: this.gridHeight,
        licenseKey: "non-commercial-and-evaluation",
        data: this.doors,
        colHeaders: this.colHeaders,
        rowHeaders: true,
        colWidths: index => {
          return this.columns[index].width ?? 150;
        },
        columns: this.colData,
        manualColumnResize: true,
        afterChange: async changes => {
          if (changes) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const update = changes.map(([row, prop, oldValue, newValue]) => {
              return {
                id: this.doors[row].id,
                prop,
                value: newValue
              };
            });

            try {
              this.saving = true;
              await this.$store.dispatch("orders/updateMultipleDoors", update);
            } finally {
              this.saving = false;
            }
          }
        }
      };
    }
  },
  mounted() {
    setTimeout(() => {
      this.setWrapperHeight();
      window.addEventListener("resize", this.setWrapperHeight);
    }, 100);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWrapperHeight);
  },
  methods: {
    setWrapperHeight() {
      if (!process.browser) return;

      const remainingHeight = 200;

      this.gridHeight = window.innerHeight - remainingHeight;
      this.wrapperStyle.height = window.innerHeight - remainingHeight + "px";
    }
  }
};
</script>

<style scoped>
.grid--wrap {
  overflow: hidden;
  width: 100%;
  height: 100px;
  border: solid 1px #ccc;
}
</style>
