var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Purchase Order")]),_c('v-card-text',[_c('PurchaseOrderForm',{attrs:{"purchaseOrder":_vm.purchaseOrder}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.saving},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-btn',{on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")])],1)],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.store.purchaseOrders,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.date))+" ")]}},{key:"item.supplierDueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.supplierDueDate))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.cost,"0,0.00"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.showEditDialog(item)}}},[_vm._v("View")]),_c('v-btn',{staticClass:"ml-8",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deletePo(item)}}},[_vm._v("Delete")])],1)]}}])}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.showAddDialog}},[_vm._v("Add Purchase Order")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }