<template>
    <v-list dense nav>
      <v-list-item-group mandatory :value="selectedItem" color="primary">
        <v-list-item
          v-for="(door, i) in doors"
          :key="i"
          @click="changeDoor(door)"
          link
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ doorName(door) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>
<script>
import { getDoorName } from './door-naming';

export default {
  props: {
    doors: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  computed: {
    selectedItem() {
      return this.doors.findIndex(
        (door) => door.id === parseInt(this.$route.params.id)
      );
    },
  },
  methods: {
    changeDoor(door) {
      if (this.$route.params.id == door.id) return;
      this.$router.push({
        params: {
          id: door.id,
        },
      });
    },
    doorName(door) {
      return getDoorName(door);
    }
  },
};
</script>
