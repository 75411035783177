5<template>
  <v-container class="pa-0">
    <v-row dense>
      <v-col v-if="showSelect" cols="4" class="subtitle-1">
        <v-select
          v-model="headThickness1"
          label="Head Thickness"
          :items="headOptions"
        />
      </v-col>
      <v-col class="text-right">
        <div v-if="imageSrc === ''" class="no_image">
          Select Head Thickness
        </div>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          :viewBox="viewBox"
          class="head__image"
          width="100%"
        >
          <image :xlink:href="imageSrc" width="500px" height="500px" />
          <text :font-size="svgFontSize" :transform="thickness1Transform">
            {{ headThickness1 }}
          </text>
          <text :font-size="svgFontSize" :transform="thickness2Transform">
            {{ headThickness2 }}
          </text>
        </svg>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { headOptions, materialTypes } from "@gradys/vue-base/store/lookups/lookup-values";
import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";
import { PaneTypes } from '@gradys/vue-base/store/util/pane-func';


export default {
  mixins: [mixin],
  data() {
    return {
      headOptions,
    };
  },
  props: {
    showSelect: {
      type: Boolean,
      default: true,
    },
    door: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  computed: {
    ...syncProp("headThickness1"),
    headThickness2() {
      if (!this.headThickness1) return "";

      const thickness2 = {
        "52": "32",
        "56": "36",
        "65": "45",
        "88": "68"
      };

      return thickness2[this.headThickness1] ?? "-";
    },
    imageSrc() {
      if (!this.door || !this.door.headThickness1) return "";

      const images = require.context(
        "@/assets/head-options/new-images",
        false,
        /\.png$/
      );

      try {
        return images(`./${this.img}.png`);
      } catch {
        return "";
      }
    },
    img() {
      if (!this.door) return "";

      return `${this.fanlightCode}__${this.door.opens.toLowerCase()}`;
    },
    fanlightCode() {
      const fanlight = this.door.panes.find(p => p.type === PaneTypes.fanlight);
      if (!fanlight) return "nf";
      if (!fanlight.material) return "sf";

      if (fanlight.material.toLowerCase() === materialTypes.solid) return "sf";

      return "gf";      
    },
    viewBox() {
      return this.fanlightCode === "nf" ? "-250 0 1000 500" : "0 0 500 500";
    },
    svgFontSize() {
      return this.fanlightCode === "nf" ? "40" : "18";      
    },
    thickness1Transform() {
      const transforms = {
        nf__in: "40 200",
        nf__out: "482 190",
        sf__in: "138 365",
        sf__out: "364 350",
        gf__in: "145 370",
        gf__out: "355 370",
      };

      return `translate(${transforms[this.img]}) rotate(-90)`;
    },
    thickness2Transform() {
      const transforms = {
        nf__in: "475 160",
        nf__out: "35 160",
        sf__in: "370 340",
        sf__out: "128 340",
        gf__in: "365 350",
        gf__out: "138 350",
      };

      return `translate(${transforms[this.img]}) rotate(-90)`;
    },
  },
};
</script>

<style scoped>
.head__image {
  max-width: 100%;
  border: solid 1px #cccccc;
}
.no_image {
  max-width: 100%;
  height: 300px;
  display: flex;
  background: #eee;
  justify-content: center;
  align-items: center;
  border: solid 1px #cccccc;
}
</style>
