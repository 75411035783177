<template>
  <v-card class="mb-2">
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-card-text class="pt-0">
          <div class="title">{{ company.name }}</div>
          <div class="subtitle-2 mt-2">BMTrada Company Certificate No.</div>
          <div>{{ company.certNo || "-" }}</div>
          <div class="subtitle-2 mt-2">Address</div>
          <div>{{ company.address || "-" }}</div>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card-text class="pt-0">
          <div class="subtitle-1">Logo</div>
          <v-img
            :src="company.logoUrl"
            max-height="150"
            max-width="250"
            contain
          />
          <div v-if="!company.logoUrl">---</div>
        </v-card-text>
      </v-col>
      <v-divider class="my-4"></v-divider>
    </v-row>
    <v-card-actions>
      <v-btn small color="primary" @click="edit">Edit</v-btn>
      <v-btn small @click="viewInstallers" :loading="loadingInstallers">
        View Installers
      </v-btn>
      <v-btn
        v-show="canDelete"
        small
        color="error"
        class="text-right ml-12"
        @click="remove"
      >
        Delete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loadingInstallers: false
    };
  },
  props: {
    company: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    canDelete() {
      return this.company.id;
    }
  },
  methods: {
    setLoading(val) {
      this.loadingInstallers = val;
    },
    remove() {
      const ans = confirm("Are you sure you want to delete this Company");
      if (ans) {
        this.$emit("on:delete", this.company);
      }
    },
    edit() {
      this.$emit("on:edit", this.company);
    },
    viewInstallers() {
      this.$emit("on:view-installers", this.company);
    }
  }
};
</script>
