import Vue from "vue";

function lockControls(el, binding) {
  const tags = ["button"];
  tags.forEach(tagName => {
    const nodes = el.getElementsByTagName(tagName);
    for (let i = 0; i < nodes.length; i++) {
      if (binding.value) {
        nodes[i].disabled = "disabled";
        nodes[i].classList.add("v-btn--disabled");
      } else {
        nodes[i].removeAttribute("disabled");
        nodes[i].classList.remove("v-btn--disabled");
      }
    }
  });
}

Vue.directive("disable-all", {
  inserted: lockControls,
  componentUpdated: lockControls
});
