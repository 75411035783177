<template>
  <g>
    <image
      v-show="show('right-handlegold')"
      data-name="right-handle-gold"
      x="-30"
      y="180"
      width="75"
      href="https://storage.googleapis.com/door-element-images/temp-handles/Balmoral%20Handle%202%20-%20Gold.PNG"
    />
    <image
      v-show="show('right-handle')"
      data-name="right-handle"
      x="-30"
      y="180"
      width="75"
      href="https://storage.googleapis.com/door-element-images/temp-handles/Balmoral%20Handle%202%20-satin.PNG"
    />
    <image
      v-show="show('left-handlegold')"
      data-name="left-handle-gold"
      x="140"
      y="180"
      width="45"
      href="https://storage.googleapis.com/door-element-images/temp-handles/Balmoral%20Handle%201%20-%20Gold.PNG"
    />
    <image
      v-show="show('left-handle')"
      data-name="left-handle"
      x="140"
      y="180"
      width="45"
      href="https://storage.googleapis.com/door-element-images/temp-handles/Balmoral%20Handle%201%20-%20satin.PNG"
    />
    <image
      v-show="show('left-d-handle')"
      data-name="left-d-handle"
      x="160"
      y="150"
      width="30"
      href="https://storage.googleapis.com/door-element-images/temp-handles/300mm%20Pull%20Handle.PNG"
    />
    <image
      v-show="show('left-d-handlegold')"
      data-name="left-d-handle"
      x="160"
      y="150"
      width="30"
      href="https://storage.googleapis.com/door-element-images/temp-handles/300mm%20Pull%20Handle.PNG"
    />
    <image
      v-show="show('right-d-handle')"
      data-name="right-d-handle"
      x="10"
      y="150"
      width="30"
      href="https://storage.googleapis.com/door-element-images/temp-handles/300mm%20Pull%20Handle.PNG"
    />
    <image
      v-show="show('left-bathroom')"
      x="120"
      y="180"
      width="80"
      href="https://storage.googleapis.com/door-element-images/temp-handles/bathroom-left.png"
    />
    <image
      v-show="show('left-bathroomgold')"
      x="120"
      y="180"
      width="80"
      href="https://storage.googleapis.com/door-element-images/temp-handles/bathroom-left.png"
    />
    <image
      v-show="show('right-bathroom')"
      x="-10"
      y="187"
      width="75"
      href="https://storage.googleapis.com/door-element-images/temp-handles/bathroom-right.PNG"
    />
    <image
      v-show="show('right-bathroomgold')"
      x="-10"
      y="187"
      width="75"
      href="https://storage.googleapis.com/door-element-images/temp-handles/bathroom-right.PNG"
    />
  </g>
</template>

<script>
import { lockOptionsOptions } from '@gradys/vue-base/store/lookups/lookup-values';

export default {
  props: {
    door: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    sideKey() {
      return this.door.doorHanding === "Left" ? "left" : "right";
    },
    suiteKey() {
      if (this.door.suiteColour === "Gold") return "gold";

      return "";
    }
  },
  methods: {
    show(val) {
      if (!this.door) return false;
      if (!this.door.lockOption) return false;
      if (this.door.lockOption.toLowerCase() === "none") return false;

      const lockOption = lockOptionsOptions.find(l => l.value === this.door.lockOption);

      if (!lockOption) return false;

      const key = `${this.sideKey}-${lockOption.note}${this.suiteKey}`;

      return val === key;
    }
  },
};
</script>

