<template>
  <v-card>
    <v-card-title>Create New Order</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container class="pa-0">
          <v-row>
            <v-col sm="12" md="4">
              <v-autocomplete
                label="Client Name"
                clearable
                v-model="clientId"
                item-value="id"
                item-text="name"
                :items="clients"
                @change="clientSelected"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="reference"
                label="Order Reference"
                :rules="referenceRules"
              />
            </v-col>
            <v-col>
              <v-select
                label="Contract"
                clearable
                v-model="contractId"
                item-text="name"
                item-value="id"
                :items="contracts"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col sm="12" md="4">
              <v-text-field
                label="No Doors"
                v-model.number="noDoors"
                placeholder="Enter Initial Number of Doors"
                messages="You can change this value later"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="4">
              <v-select
                label="Price List"
                v-model="priceCategory"
                :items="priceCategories"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" :loading="saving" @click="save">
        Save New Order
      </v-btn>
      <v-btn color="error" to="/">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { get, call } from "vuex-pathify";

export default {
  data() {
    return {
      saving: false,
      clientId: null,
      contractId: null,
      priceCategory: "Default",
      referenceRules: [v => !!v || "Order Reference is required"],
      reference: "",
      noDoors: 10,
      priceCategories: ["Default", "A", "B", "C", "D", "GJUK"]
    };
  },
  computed: {
    clients: get("clients/clients"),
    client: get("clients/selectedClient"),
    contracts() {
      if (!this.client || !this.client.id) return [];

      return this.client.contracts;
    }
  },
  async created() {
    await this.loadClients();
  },
  methods: {
    createOrder: call("orders/createOrder"),
    loadClients: call("clients/loadClients"),
    loadClient: call("clients/loadClient"),
    async clientSelected(id) {
      await this.loadClient(id);

      this.contractId = null;
    },
    async save() {
      if (!this.$refs.form.validate()) return;

      try {
        this.saving = true;

        const {
          reference,
          clientId,
          contractId,
          noDoors,
          priceCategory
        } = this;

        const id = await this.createOrder({
          reference,
          clientId,
          contractId,
          priceCategory,
          noDoorsToAdd: noDoors
        });

        this.$router.push(`/order/${id}`);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
