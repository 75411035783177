// modules
import auth0 from "auth0-js";

// aliased
import config from "./conf";

class Auth {
  constructor(config) {
    this.clientID = config.clientID;

    this.auth0 = new auth0.WebAuth({
      domain: config.domain,
      clientID: config.clientID,
      audience: config.audience,
      redirectUri: config.callbackUrl,
      responseType: config.responseType,
      scope: config.scope
    });

    this.scheduleTokenRenewal();
  }

  // Returns the access token for use in authenticated XHR requests.
  getAccessToken = () => {
    return this.accessToken;
  };

  // Returns the idToken generated by Auth0 for the current user.
  getIdToken = () => {
    return this.idToken;
  };

  // Returns the profile of the authenticated user, if any.
  getProfile = () => {
    return this.profile;
  };

  // Called after the user is redirected from Auth0.  This method reads the hash segment
  // of the URL to fetch the user details and the id token.
  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) return reject(err);
        resolve(authResult);
      });
    }).then(authResult => this.setSession(authResult));
  };

  // => Boolean
  isAuthenticated = () => {
    return new Date().getTime() < this.expiresAt;
  };

  // set auth creds on the instance
  setSession = authResult => {
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.scheduleTokenRenewal();
  };

  tokenRenewalTimeout;

  // Redirects to Auth0 login page
  login = () => {
    this.auth0.authorize();
  };

  // sets profile, id_token, expiresAt to null
  logout = () => {
    clearTimeout(this.tokenRenewalTimeout);
    this.auth0.logout({
      returnTo: window.location.origin,
      clientID: this.clientID
    });
  };

  checkSession = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        resolve(authResult);
      });
    });
  };

  // check session and sets the auth tokens in memory on the Auth instance
  renewTokens = async () => {
    return this.checkSession().then(authResult => this.setSession(authResult));
  };

  scheduleTokenRenewal = () => {
    const delay = this.expiresAt - Date.now();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewTokens();
      }, delay);
    }
  };
}

const auth = new Auth(config.AUTH0);

export default auth;
