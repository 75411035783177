<template>
  <div v-if="door && door.id">
    <BomImage :door="door" :image="frontImage">
      <FrameWithDimmLines :door="door" />
    </BomImage>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import FrameWithDimmLines from "@gradys/vue-base/components/frame/frame-with-dimm-lines";
import BomImage from "@/components/images/bom-image.vue";

export default {
  components: {
    FrameWithDimmLines,
    BomImage
  },
  computed: {
    door: get("doors/door"),
    frontImage: get("doors/frontImage")
  },
  created() {
    return this.fetchDoor(this.$route.params.id);
  },
  methods: {
    fetchDoor: call("doors/fetchDoor")
  }
};
</script>
