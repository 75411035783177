var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{staticClass:"pt-2 pb-4 px-0",attrs:{"items":_vm.breadcrumbs,"divider":">","data-cy":"breadcrumb"}}),_c('v-card',[_c('EditForm',{ref:"editForm",on:{"on:save":_vm.save,"on:delete":_vm.remove}}),_c('InvoiceForm',{ref:"addForm",attrs:{"invoice":_vm.invoice}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.subContractor.name)+" ")]),_c('v-card-text',[_c('div',{staticStyle:{"max-width":"300px"}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_vm._v("Number:")]),_c('v-col',{staticClass:"py-0"},[_vm._v(_vm._s(_vm.subContractor.phone))])],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_vm._v("Email:")]),_c('v-col',{staticClass:"py-0"},[_vm._v(_vm._s(_vm.subContractor.email))])],1)],1)]),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.edit(_vm.subContractor)}}},[_vm._v("Edit Sub-Contractor")]),_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.remove(_vm.subContractor)}}},[_vm._v("Delete Sub-Contractor")])],1)],1),_c('v-card',{staticClass:"mt-10"},[_c('v-card-title',[_vm._v(" Invoices "),_c('v-btn',{staticClass:"ml-4",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("Create New Invoice")])],1),_c('v-data-table',{staticClass:"click-table",attrs:{"headers":_vm.headers,"items":_vm.invoices,"items-per-page":20},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(item.date))+" ")]}},{key:"item.net",fn:function(ref){
var item = ref.item;
return [_vm._v(" £ "+_vm._s(_vm._f("number")(item.net,"0,0.00"))+" ")]}},{key:"item.amountUnallocated",fn:function(ref){
var item = ref.item;
return [_vm._v(" £ "+_vm._s(_vm._f("number")(item.totalUnallocated,"0,0.00"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openInvoice(item)}}},[_vm._v(" View ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }