<template>
  <span>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"> Confirmation </v-card-title>
        <v-card-text>{{ modalText }} </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :loading="loading" @click="toggleBOMUpdates">
            Yes
          </v-btn>
          <v-btn text color="red" @click="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn small :outlined="!bomAutoUpdate" tile color="primary" @click="open">
      <v-icon left>{{ iconValue }}</v-icon>
      Auto Update BOM
    </v-btn>
  </span>
</template>
<script>
import { call, get } from "vuex-pathify";
export default {
  props: {
    door: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  computed: {
    bomAutoUpdate: get("doors/bomAutoUpdate"),
    iconValue() {
      if (this.bomAutoUpdate) {
        return "mdi-checkbox-marked-outline";
      }
      return "mdi-checkbox-blank-outline";
    },
    modalText() {
      return `Are you sure you want to ${
        this.bomAutoUpdate ? "disable" : " enable"
      } Bill Of Materials Auto Updates?`;
    }
  },
  methods: {
    updateDoorAsync: call("doors/updateDoorAsync"),
    open() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    async toggleBOMUpdates() {
      this.loading = true;
      try {
        await this.updateDoorAsync({
          prop: "autoUpdateBOM",
          value: !this.door.autoUpdateBOM
        });
      } finally {
        this.dialog = false;
        this.loading = false;
      }
    }
  }
};
</script>
