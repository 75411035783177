<template>
  <div class="dimm__wrap">
    <div class="left-dimm vertical-dimm">
      <div class="dimm-value">{{ frameDimms.frameHeight }}</div>
    </div>
    <div class="frame-dimm__wrap">
      <div class="top-dimm horizontal-dimm text-center">
        {{ frameDimms.frameWidth }}
      </div>
      <FullFrame id="full-frame" :door="door" class="full-frame" v-on="$listeners" />
      <div class="bottom-dimms horizontal-dimm pt-2">
        <div class="dimm-value text-center" v-if="frameDimms.rightSideWidth" :style="rightStyle">
          {{ frameDimms.rightSideWidth }}
        </div>
        <div class="dimm-value text-center" style="flex: 1">
          {{ frameDimms.doorWidth }}
        </div>
        <div class="dimm-value text-center" v-if="frameDimms.leftSideWidth" :style="leftStyle">
          {{ frameDimms.leftSideWidth }}
        </div>
      </div>
    </div>
    <div class="right-dimm vertical-dimm">
      <div v-if="frameDimms.fanlightHeight" :style="topStyle" class="dimm-wrap">
        <div class="dimm-value">{{ frameDimms.fanlightHeight }}</div>
      </div>
      <div :style="middleStyle" class="dimm-wrap">
        <div class="dimm-value">{{ frameDimms.topSidelightHeight || frameDimms.doorHeight }}</div>
      </div>
      <div v-if="frameDimms.bottomSidelightHeight" :style="bottomStyle" class="dimm-wrap">
        <div class="dimm-value">{{ frameDimms.bottomSidelightHeight }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import FullFrame from "@gradys/vue-base/components/frame/full-frame";

export default {
  components: {
    FullFrame,
  },
  props: {
    door: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    frameDimms: get("doors/frameDimms"),
    pixelHeightPerMm: get("doors/pixelHeightPerMm"),
    pixelWidthPerMm: get("doors/pixelWidthPerMm"),
    ...get("doors", ["top", "middle", "bottom"]),
    doorPane() {
      return this.door.panes.find((p) => p.type === "door");
    },
    topStyle() {
      return {
        height: `${this.top * this.pixelHeightPerMm}px`,
      };
    },
    middleStyle() {
      return {
        height: `${this.middle * this.pixelHeightPerMm}px`,
      };
    },
    bottomStyle() {
      return {
        height: `${this.bottom * this.pixelHeightPerMm}px`,
      };
    },
    leftStyle() {
      return {
        width: `${this.frameDimms.leftSideWidth * this.pixelWidthPerMm}px`,
      };
    },
    rightStyle() {
      return {
        width: `${this.frameDimms.rightSideWidth * this.pixelWidthPerMm}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dimm__wrap {
  display: flex;
  color: #000;
}
.horizontal-dimm {
  height: 25px;
}
.vertical-dimm {
  margin: 25px 0;
}
.left-dimm {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-right: 4px;
}
.right-dimm {
  padding-left: 4px;

  .dimm-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.bottom-dimms {
  display: flex;
  justify-content: space-between;
}
</style>
