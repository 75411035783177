<template>
  <v-card>
    <v-card-title>
      Fitters
      <v-btn class="ml-12" small color="primary" @click="add">Add Fitter</v-btn>
    </v-card-title>
    <EditForm ref="form" :sub-contractors="subContractors" v-on="$listeners" />
    <v-simple-table class="click-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Name</th>
            <th>Employer</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Active</th>
            <th>Issue Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="fitter in fitters" :key="fitter.id">
            <td>{{ fitter.name }}</td>
            <td>
              {{ fitter.subContractor }}
            </td>
            <td>{{ fitter.email }}</td>
            <td>{{ fitter.phone }}</td>
            <td>
              <v-icon v-if="fitter.active" color="success">
                mdi-checkbox-marked-outline
              </v-icon>
              <v-icon v-else left>mdi-checkbox-blank-outline</v-icon>
            </td>
            <td>{{ fitter.issueDate }}</td>
            <td>
              <v-btn small color="primary" @click="edit(fitter)">
                Edit
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-text v-if="!fitters || fitters.length === 0">
      No Fitters
    </v-card-text>
  </v-card>
</template>

<script>
import EditForm from "@/components/forms/fitters/edit-form";

export default {
  components: {
    EditForm
  },
  props: {
    fitters: {
      type: Array,
      default() {
        return [];
      }
    },
    subContractors: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    add() {
      this.$refs.form.open();
    },
    edit(fitter) {
      this.$refs.form.open(fitter);
    },
    closeDialog() {
      this.$refs.form.close();
    },
    setLoading(val) {
      this.$refs.form.setLoading(val);
    }
  }
};
</script>
