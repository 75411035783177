<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>
            Type
          </th>
          <th>
            Quantity
          </th>
          <th>
            Weight
          </th>
          <th>
            Cost
          </th>
          <th>
            GJUK
          </th>
          <th>
            Client Price
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(summaryItem, i) in costSummary.summaries"
          :key="summaryItem + i"
        >
          <td>{{ summaryItem.type }}</td>
          <td>{{ summaryItem.quantity }}</td>
          <td>{{ summaryItem.weight }}</td>
          <td>£{{ summaryItem.cost | number("0,0.00") }}</td>
          <td>£{{ summaryItem.gjuk | number("0,0.00") }}</td>
          <td>£{{ summaryItem.clientPrice | number("0,0.00") }}</td>
        </tr>
        <tr>
          <td>
            <strong>Total</strong>
          </td>
          <td>
            <strong>{{ costSummary.quantityTotal }}</strong>
          </td>
          <td>{{ costSummary.weight | number("0,0.00") }}</td>
          <td>
            <strong>
              £{{ costSummary.doorCostTotal | number("0,0.00") }}</strong
            >
          </td>
          <td>
            <strong>
              £{{ costSummary.doorCostTotal | number("0,0.00") }}</strong
            >
          </td>
          <td>
            <strong>
              £{{ costSummary.doorPriceTotal | number("0,0.00") }}</strong
            >
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    costSummary: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>
