<template>
  <div>
    <div v-show="!uploading" class="file-input--wrapper">
      <button class="btn-file-input primary white--text">
        {{ uploadText }}
      </button>
      <input
        type="file"
        :accept="accept"
        name="file"
        @change="onFileSelected"
      />
    </div>
    <div v-show="uploading" class="caption">
      <v-progress-linear
        v-model="progress"
        color="primary"
        height="25"
        reactive
      >
        <template v-slot="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      {{ bytesUploaded | number("0,0") }} of
      {{ totalBytes | number("0,0") }} bytes uploaded
    </div>
    <div v-show="error">
      An error occurred uploading the file, please try again
    </div>
  </div>
</template>

<script>
import { storage } from "@/plugins/firebase";

export default {
  props: {
    fileType: {
      type: String,
      default: "Other"
    },
    uploadText: {
      type: String,
      default: "Upload File"
    },
    uploadLocation: {
      type: String,
      default: "files/file_"
    },
    accept: {
      type: String,
      default: "*"
    }
  },
  data() {
    return {
      uploading: false,
      bytesUploaded: 0,
      totalBytes: 0,
      error: false
    };
  },
  computed: {
    progress() {
      if (this.totalBytes === 0) return 0;

      return (this.bytesUploaded / this.totalBytes) * 100;
    }
  },
  methods: {
    onFileSelected(event) {
      const fileData = {
        file: event.target.files[0],
        type: this.fileType
      };

      this.uploading = true;

      const uploadTask = storage
        .ref()
        .child(this.uploadLocation + new Date().getTime())
        .put(fileData.file);

      uploadTask.on(
        "state_changed",
        snapshot => {
          this.bytesUploaded = snapshot.bytesTransferred;
          this.totalBytes = snapshot.totalBytes;
        },
        () => {
          this.error = true;
          this.uploading = false;
        },
        () => {
          this.uploading = false;
          this.error = false;
          fileData.url = uploadTask.snapshot.ref.fullPath;
          this.$emit("on:file", fileData);
        }
      );
    }
  }
};
</script>

<style scoped>
.file-input--wrapper {
  font-size: 0.85em;
  width: 160px;
  height: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.file-input--wrapper > input[type="file"] {
  font-size: 160px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.file-input--wrapper > .btn-file-input {
  display: inline-block;
  width: 160px;
  height: 20px;
}
</style>
