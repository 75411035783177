<template>
  <div>
    <v-text-field
      :value="installer.name"
      label="Installer Name"
      :rules="nameRules"
      required
      @change="e => $emit('on:name-changed', e)"
    />
    <v-select
      :value="installer.bmTradaCompanyId"
      label="BMTrada Company"
      :items="companyItems"
      :rules="[v => !!v || 'BMTrada Company Is Required']"
      required
      @change="e => $emit('on:company-changed', e)"
    ></v-select>
    <v-text-field
      :value="installer.certNo"
      label="BMTrada Approved Installer No."
      @change="e => $emit('on:cert-no-changed', e)"
    />
  </div>
</template>

<script>
export default {
  props: {
    installer: {
      type: Object,
      default() {
        return {};
      }
    },
    companies: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      nameRules: [v => !!v || "Name is required"]
    };
  },
  computed: {
    companyItems() {
      return this.companies.map(c => {
        return {
          text: c.name,
          value: c.id
        };
      });
    }
  }
};
</script>
