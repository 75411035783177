<template>
  <v-container fluid>
    <v-card class="mb-2">
      <v-card-title
        ><form>
          <input ref="fileUpload" type="file" @change="uploadExcel" />
        </form>
        <v-btn
          color="primary"
          class="mt-2"
          :disabled="!orderExcel.isValid"
          @click="showDialog = true"
          >Save {{ counted }} Doors as Order</v-btn
        >
        <v-btn @click="clear" color="primary" class="mt-2 ml-2"
          >Clear Excel</v-btn
        ></v-card-title
      >
    </v-card>

    <v-data-table
      :loading="loading"
      :items="orderExcel.uploadedExcelLineData"
      class="full-width design-table"
      no-data-text="No Excel Uploaded"
      hide-default-footer
    >
      <template v-slot:top>
        <thead>
          <tr>
            <th>is Valid</th>
            <th>Numerals</th>
            <th>Address</th>
            <th>Post Code</th>
            <th>Block</th>
            <th>Location</th>
            <th>Overall Width</th>
            <th>Overall Height</th>
            <th>Fanlight Height</th>
            <th>SideLight Width Right</th>
            <th>SideLight Width Left</th>
            <th>Intended Fire Rating</th>
            <th>Frame Material</th>
            <th>Door Thickness</th>
            <th>Frame Finish</th>
            <th>Rain Deflector</th>
            <th>Door Handing</th>
            <th>Hinged side as viewed from outside</th>
            <th>Door Style</th>
            <th>External Colour</th>
            <th>Internal Colour</th>
            <th>Glass</th>
            <th>Chain</th>
            <th>Letter Plate</th>
            <th>Door Knocker</th>
            <th>Cylinder</th>
            <th>Spyhole @1200mm</th>
            <th>Spyhole @1500mm</th>
            <th>External HArdware Finish</th>
            <th>Closer</th>
            <th>Threshold Options</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody
          class=""
          v-for="orders in orderExcel.uploadedExcelLineData"
          :key="orders.id"
        >
          <tr>
            <td>
              <v-icon
                class="success--text"
                v-if="orders.isValid === true"
                small
                left
                >mdi-checkbox-marked-circle</v-icon
              >
              <v-icon class="error--text" v-else small left>mdi-cancel</v-icon>
            </td>
            <td v-for="lines in orders.parsedJson" :key="lines.cell">
              {{ lines.valueEntered }}
              <div v-if="lines.isValid === false" class="error--text">
                {{ lines.errorMessage }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog v-model="showDialog" max-width="600">
      <v-card>
        <v-card-title>
          Save as Order
        </v-card-title>
        <v-card-text class="justify-left">
          <v-form ref="form">
            <v-row>
              <v-col sm="12" md="4">
                <v-autocomplete
                  label="Client Name"
                  clearable
                  v-model="clientId"
                  item-value="id"
                  item-text="name"
                  :items="clients"
                  @change="clientSelected"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="reference"
                  label="Order Reference"
                  :rules="referenceRules"
                />
              </v-col>
              <v-col>
                <v-select
                  label="Contract"
                  clearable
                  v-model="contractId"
                  item-text="name"
                  item-value="id"
                  :items="contracts"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col sm="12" md="4">
                <v-select
                  label="Price List"
                  v-model="priceCategory"
                  :items="priceCategories"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="error--text" sm="12" md="12">
                {{ errorMsg }}
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-left">
          <v-btn color="primary" :loading="saving" @click="save">
            Save New Order
          </v-btn>
          <v-btn @click="showDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { call, get } from "vuex-pathify";
export default {
  data() {
    return {
      loading: false,
      saving: false,
      clientId: null,
      contractId: null,
      counted: null,
      priceCategory: "Default",
      referenceRules: [v => !!v || "Order Reference is required"],
      reference: "",
      errorMsg: "",
      showDialog: false,
      priceCategories: ["Default", "A", "B", "C", "D", "GJUK"]
    };
  },
  computed: {
    orderExcel: get("orders/orderExcel"),
    clients: get("clients/clients"),
    client: get("clients/selectedClient"),
    contracts() {
      if (!this.client || !this.client.id) return [];

      return this.client.contracts;
    }
  },
  async created() {
    await this.loadClients();
  },
  methods: {
    uploadExcelDocument: call("orders/uploadOrderExcel"),
    clearExcel: call("orders/clearExcel"),
    createOrderViaExcel: call("orders/createOrderViaExcel"),
    loadClients: call("clients/loadClients"),
    loadClient: call("clients/loadClient"),
    async clientSelected(id) {
      await this.loadClient(id);

      this.contractId = null;
    },
    async uploadExcel() {
      const formData = new FormData();
      formData.append(
        "file",
        event.target.files[0],
        event.target.files[0].name
      );
      try {
        this.loading = true;
        await this.uploadExcelDocument(formData);
        this.counted = this.orderExcel.uploadedExcelLineData.length;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.clearExcel();
      this.counted = 0;
      this.$refs.fileUpload.value = null;
    },
    async save() {
      if (!this.$refs.form.validate()) return;

      try {
        this.saving = true;

        const { reference, clientId, contractId, priceCategory } = this;

        const id = await this.createOrderViaExcel({
          reference,
          clientId,
          contractId,
          priceCategory
        });
        this.clear();
        this.$router.push(`/order/${id}`);
      } catch (error) {
        this.errorMsg =
          "Something went wrong - Please try again or Contact I.T";
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
<style>
.v-data-table {
  overflow-x: auto;
}
</style>
