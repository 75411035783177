<template>
  <v-container class="pa-0">
    <v-row dense>
      <v-col v-if="showSelect" cols="4">
        <BaseSelect v-model="threshold" label="Threshold" :items="thresholds" />
      </v-col>
      <v-col class="text-right">
        <div v-if="threshold === ''" class="no_image">
          Select Threshold
        </div>
        <img v-else class="threshold__image" :src="imageSrc" alt="" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";

export default {
  mixins: [mixin],
  props: {
    showSelect: {
      type: Boolean,
      default: true
    },
    thresholds: {
      type: Array,
      default: () => []
    },
    thresholdImageName: {
      type: String,
      required: true
    },
    door: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...syncProp("threshold"),
    imageSrc() {
      try {
        const images = require.context(
          "@/assets/threshold-options",
          false,
          /\.png$/
        );

        return images(`./${this.thresholdImageName}.png`);
      } catch (e) {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.threshold__image,
.no_image {
  max-width: 100%;
  border: solid 1px #cccccc;
}
.no_image {
  height: 200px;
  display: flex;
  background: #eee;
  justify-content: center;
  align-items: center;
  border: solid 1px #cccccc;
}
</style>
