<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title v-if="!invoice.id"> Add Invoice </v-card-title>
      <v-card-title v-if="invoice.id"> Edit Invoice </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="invoice.invoiceReference"
            :rules="invoiceReferenceRules"
            label="Invoice Reference"
            required
          />
          <v-text-field
            v-model="invoice.gradyReference"
            :rules="invoiceGradyReferenceRules"
            label="Grady Reference"
          />
          <v-text-field
            type="number"
            v-model.number="invoice.net"
            label="Net Amount"
            :rules="netRules"
          ></v-text-field>
          <v-text-field
            v-model="invoice.date"
            :rules="dateRules"
            label="Issue Date"
            type="date"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          v-if="!invoice.id"
          :loading="loading"
          @click="createInvoice"
        >
          Save
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          v-if="invoice.id"
          @click="updateInvoice"
        >
          Update
        </v-btn>
        <v-btn text color="red" @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { call } from "vuex-pathify";
export default {
  props: {
    invoice: {
      type: Object,
      default: function() {
        return {
          invoiceReference: "",
          gradyReference: "",
          net: 0,
          date: new Date().toISOString().substr(0, 10)
        };
      }
    }
  },
  data() {
    return {
      edit: false,
      dialog: false,
      loading: false,
      invoiceReferenceRules: [v => !!v || "Invoice Reference is required"],
      invoiceGradyReferenceRules: [
        v => !!v || "Grady Invoice Reference is required"
      ],
      dateRules: [v => !!v || "Invoice Date Required"],
      netRules: [v => !!v || "Required"]
    };
  },
  methods: {
    saveSubcontractorInvoice: call("subContractors/saveSubcontractorInvoice"),
    async createInvoice() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        const newInvoice = await this.saveSubcontractorInvoice(this.invoice);
        this.$router.push(
          `${this.invoice.subContractorId}/invoice/${newInvoice.id}`
        );
      }
      this.loading = false;
    },
    async updateInvoice() {
      this.loading = true;
      await this.saveSubcontractorInvoice(this.invoice);
      this.loading = false;
      this.dialog = false;
    },

    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    }
  }
};
</script>
