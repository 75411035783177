<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-card>
      <v-card-title class="headline">
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12" sm="6" class="pr-sm-6">
                <v-text-field
                  v-model="fitter.name"
                  label="Name"
                  :rules="nameRules"
                  required
                />
                <v-text-field
                  v-model="fitter.email"
                  label="Email"
                  :rules="emailRules"
                />
                <BaseText v-model="fitter.phone" label="Phone" />
                <v-text-field
                  v-model="fitter.issueDate"
                  label="Issue Date"
                  type="date"
                />
              </v-col>
              <v-col cols="12" sm="6" class="pl-sm-6">
                <v-textarea
                  v-model="fitter.address"
                  label="Address"
                ></v-textarea>
                <v-select
                  v-model="fitter.subContractorId"
                  item-text="name"
                  item-value="id"
                  :items="subContractors"
                  label="Sub Contractor"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click="save">Save</v-btn>
        <v-btn v-show="showActiveButton" color="success" @click="activate"
          >Mark as Active</v-btn
        >
        <v-btn v-show="showDeactivateButton" color="warning" @click="deactivate"
          >Mark as Inactive</v-btn
        >
        <v-btn text color="red" @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-show="canDelete"
          color="error"
          class="text-right"
          @click="remove"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    subContractors: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      fitter: {},
      nameRules: [v => !!v || "Name is required"],
      emailRules: [v => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
      dialog: false,
      loading: false
    };
  },
  computed: {
    contractorItems() {
      return this.subContractors.map(c => {
        return {
          text: c.name,
          value: c.id
        };
      });
    },
    canDelete() {
      return this.fitter.id;
    },
    showActiveButton() {
      return this.fitter.id && !this.fitter.active;
    },
    showDeactivateButton() {
      return this.fitter.id && this.fitter.active;
    },
    formTitle() {
      return this.fitter.id ? "Edit Fitter" : "Add Fitter";
    }
  },
  methods: {
    remove() {
      const ans = confirm("Are you sure you want to delete this Fitter");
      if (ans) {
        this.$emit("on:delete", this.fitter);
      }
    },
    open(fitter) {
      this.dialog = true;
      this.fitter = { ...fitter };

      // sometimes the form isn't ready for reset
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      }, 100);
    },
    close() {
      this.dialog = false;
    },
    setLoading(val) {
      this.loading = val;
    },
    activate() {
      this.$emit("on:save", {
        ...this.fitter,
        active: true
      });
    },
    deactivate() {
      this.$emit("on:save", {
        ...this.fitter,
        active: false
      });
    },
    save() {
      if (this.$refs.form.validate()) this.$emit("on:save", this.fitter);
    }
  }
};
</script>
