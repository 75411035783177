<template>
  <div class="outer" :class="{ 'solid-bg': isSolid, 'clear-bg': isClear, 'satin-bg': isSatin }">
    <div class="inner" :class="{ 'clickable': clickable }"></div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { materialTypes, materialsOptions } from "@gradys/vue-base/store/lookups/lookup-values";

export default {
  props: {
    pane: {
      type: String,
      default: ""
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    door: get("doors/door"),
    isSolid() {
      return this.materialType === materialTypes.solid;
    },
    isClear() {
      return this.materialType === materialTypes.clear;
    },
    isSatin() {
      return this.materialType === materialTypes.satin;
    },
    materialType() {
      if (!this.innerPane.material) return materialTypes.solid;

      const option = materialsOptions.find(p => p.value === this.innerPane.material);
      if (!option) return materialTypes.solid;

      // note is the material type in the lookup
      return option.note;
    },
    innerPane() {
      if (!this.door || !this.door.panes) return {};

      const found = this.door.panes.find(p => p.type === this.pane);
      return found ?? {};
    }
  },
}
</script>

<style lang="scss" scoped>
.outer {
  display: flex;

  .inner {
    flex: 1;

    &.clickable {
      &:hover {
        cursor: pointer;
        background: rgba(0, 26, 255, 0.075);
      }
    }
  }
}
</style>