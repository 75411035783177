const {
  VUE_APP_DOMAIN: AUTH0_DOMAIN = "",
  VUE_APP_CLIENT_ID: AUTH0_CLIENT_ID = "",
  VUE_APP_AUDIENCE: AUTH0_AUDIENCE = ""
} = process.env;

export default {
  AUTH0: {
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    audience: AUTH0_AUDIENCE,
    callbackUrl: `${window.location.origin}/logged-in`,
    returnTo: `${window.location.origin}`,
    responseType: "token id_token",
    scope: "openid profile email"
  }
};
