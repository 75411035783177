var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Batches ")]),_c('v-data-table',{staticClass:"click-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.store.batches,"options":_vm.options,"server-items-length":_vm.store.batchCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.orderReference",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"dont-wrap"},[_vm._v(" "+_vm._s(item.orderReference)+" / "+_vm._s(item.phaseReference)+" ")])]}},{key:"item.plannedDespatchDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(value))+" ")]}},{key:"item.requestedFittingDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(value))+" ")]}},{key:"item.requestedDeliveryDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("euDate")(value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.batchClicked(item)}}},[_vm._v(" View ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }