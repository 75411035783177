<template>
  <div>
    <v-dialog max-width="600" v-model="deleteDialog">
      <v-card>
        <v-card-title>Confirmation</v-card-title>
        <v-card-text
          >Are you sure you want to delete purchase order:
          {{ purchaseOrder.number }} ?</v-card-text
        >
        <v-card-actions
          ><v-btn color="error" :loading="saving" @click="deletePurchaseOrder"
            >Delete</v-btn
          ><v-btn color="red" text @click="deleteDialog = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <v-skeleton-loader v-if="loading" type="card"> </v-skeleton-loader>
    <v-card class="mt-4" :loading="saving" v-else>
      <v-card-title>
        Purchase Order
        <v-spacer></v-spacer>
        <v-btn color="error" small @click="deleteDialog = true"
          >Delete Purchase Order</v-btn
        >
      </v-card-title>
      <v-card-text>
        <NarrowRow>
          <v-col sm="12" md="4" lg="3">
            <v-text-field
              label="date"
              :value="dateFormat('date')"
              type="date"
              @change="evt => changeDate(evt, 'date')"
            />
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <v-text-field
              label="Purchase Order No."
              v-model="purchaseOrder.number"
              @change="e => update('purchaseOrderNumber', e)"
            />
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <v-select
              label="Purchase Cost Heading"
              :items="costHeadings"
              v-model="purchaseOrder.costHeading"
              @change="e => update('costHeading', e)"
            />
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <div v-if="purchaseOrder.url">
              <div class="subtitle-2 pb-2">
                Purchase Order: {{ purchaseOrder.fileName }}
              </div>
              <v-btn small color="primary" @click="downloadPurchaseOrder">
                Download
              </v-btn>
              <v-btn
                class="ml-2"
                color="error"
                small
                @click="removePurchaseOrderDocument"
              >
                Remove
              </v-btn>
            </div>
            <div v-else>
              <div class="pb-2">Upload Purchase Order</div>
              <FileUploadButton
                :upload-location="`order/_purchase-order`"
                @on:file="onPurchaseOrderUploaded"
              />
            </div>
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <v-text-field
              label="Supplier Order Ack. Reference No."
              v-model="purchaseOrder.supplierAcknowledgementReference"
              @change="e => update('supplierAcknowledgementReference', e)"
            />
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <v-text-field
              label="Supplier Name"
              v-model="purchaseOrder.supplierName"
              @change="e => update('supplierName', e)"
            />
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <v-text-field
              label="Supplier Due Date"
              :value="dateFormat('supplierDueDate')"
              type="date"
              @change="evt => changeDate(evt, 'supplierDueDate')"
            />
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <div v-if="purchaseOrder.supplierAcknowledgementUrl">
              <div class="subtitle-2 pb-2">
                Supplier Acknowledgement:
                {{ purchaseOrder.supplierAcknowledgementFileName }}
              </div>
              <v-btn small color="primary" @click="downloadSupplierAck">
                Download
              </v-btn>
              <v-btn
                class="ml-2"
                color="error"
                small
                @click="removeSupplierAck"
              >
                Remove
              </v-btn>
            </div>
            <div v-else>
              <div class="pb-2">Upload Supplier Acknowledgement</div>
              <FileUploadButton
                :upload-location="
                  `order/${purchaseOrder.reference}_supplier-ack`
                "
                @on:file="onSupplierAckUploaded"
              />
            </div>
          </v-col>
          <v-col sm="12" md="4" lg="3">
            <v-text-field
              label="Cost"
              type="number"
              v-model.number="purchaseOrder.cost"
              @change="e => update('cost', e)"
            />
          </v-col>
        </NarrowRow>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import FileUploadButton from "@/components/files/file-upload-button.vue";
import { get, call } from "vuex-pathify";
import { downloadFile } from "@/components/files/file-upload-util";
export default {
  components: {
    FileUploadButton
  },
  data() {
    return {
      loading: true,
      saving: false,
      purchaseOrderDate: "",
      date: "",
      dueDate: "",
      costHeadings: ["Glass", "Cylinders", "Kick Plates"],
      deleteDialog: false
    };
  },
  async created() {
    try {
      await Promise.all([
        this.loadPurchaseOrders(this.$route.params.purchaseOrderId),
        this.loadPhase(this.$route.params.phaseId),
        this.loadOrder(this.$route.params.orderId)
      ]);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    purchaseOrder: get("phases/purchaseOrder"),
    phase: get("phases/phase"),
    order: get("orders/order"),
    breadcrumbs() {
      const tempOrder = !this.order ? { id: 1, reference: "" } : this.order;
      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: tempOrder.reference,
          to: `/orders/${tempOrder.id}`,
          exact: true
        },
        {
          text: "Purchase Order"
        },
        {
          text: `${this.purchaseOrder.number}`
        }
      ];
    }
  },
  methods: {
    loadPurchaseOrders: call("phases/loadPurchaseOrder"),
    loadPhase: call("phases/loadPhase"),
    loadOrder: call("orders/fetchOrder"),
    savePurchaseOrderProp: call("phases/savePurchaseOrder"),
    removePurchaseOrder: call("phases/removePurchaseOrder"),

    async deletePurchaseOrder() {
      this.saving = true;
      try {
        await this.removePurchaseOrder(this.purchaseOrder);
      } finally {
        this.$router.push(`/orders/${this.order.id}`);
      }
    },
    async onPurchaseOrderUploaded(file) {
      await this.update("url", file.url);
      await this.update("fileName", file.file.name);
    },
    downloadPurchaseOrder() {
      return downloadFile({
        fileUrl: this.purchaseOrder.url,
        fileName: this.purchaseOrder.fileName
      });
    },
    removePurchaseOrderDocument() {
      return this.update("url", "");
    },
    async onSupplierAckUploaded(file) {
      await this.update("supplierAcknowledgementUrl", file.url);
      await this.update("supplierAcknowledgementFileName", file.file.name);
    },
    downloadSupplierAck() {
      return downloadFile({
        fileUrl: this.purchaseOrder.supplierAcknowledgementUrl,
        fileName: this.purchaseOrder.supplierAcknowledgementFileName
      });
    },
    removeSupplierAck() {
      return this.update("supplierAcknowledgementUrl", "");
    },
    async update(prop, value) {
      try {
        this.saving = true;
        await this.savePurchaseOrderProp({ prop, value });
      } finally {
        this.saving = false;
      }
    },
    dateFormat(prop) {
      if (!this.purchaseOrder[prop]) return "";
      return this.purchaseOrder[prop].split("T")[0];
    },
    async changeDate(date, prop) {
      this.purchaseOrder[prop] = date;
      await this.update(prop, date);
    }
  }
};
</script>
