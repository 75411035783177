<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card :loading="saving" v-else>
      <v-card-title>{{ consignment.reference }}</v-card-title>

      <v-card-actions
        class="ml-2                                                                                                                                                                                                                                                                                                                                                               "
        ><v-btn :disabled="!mapsLink.length" @click="openMap" color="primary"
          >View Route on Map</v-btn
        >
        <v-btn color="success" @click="openPrintable">Create Print Out</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteInvoice"> Delete Invoice</v-btn>
      </v-card-actions>
      <v-row class="ml-2">
        <v-col cols="3">
          <v-text-field
            @change="dateChanged"
            v-model="consignment.dispatchDate.split('T')[0]"
            label="Dispatch Date"
            type="date"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Lorry Registration"
            v-model="consignment.lorryRegistration"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="consignment.proposedShippingRoute"
            label="Shipping Route"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="consignment.container"
            label="Container"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
        <v-textarea
          class="ml-2"
          @change="saveChangesToConsignment"
          v-model="consignment.notes"
          label="Notes"
        />
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card-title>Net</v-card-title>
          <NetSummaryTable :costSummary="costSummary" />
        </v-col>

        <v-col cols="6">
          <v-card-title>Average</v-card-title>
          <AverageSummaryTable :costSummary="costSummary" />
        </v-col>
      </v-row>
    </v-card>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card class="mt-2" v-else>
      <v-card-title>
        Manual Override
      </v-card-title>
      <v-row class="ml-2">
        <v-col cols="2">
          <v-text-field
            label="Manual FD30 Total Weight"
            v-model.number="consignment.manualFD30TotalWeight"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model.number="consignment.manualFD30TransferPrice"
            label="Manual FD30 Transfer Price"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model.number="consignment.manualFD60TotalWeight"
            label="Manual FD60 Total Weight"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model.number="consignment.manualFD60TransferPrice"
            label="Manual FD60 Transfer Price"
            @change="updateConsignment"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card class="mt-2" v-else>
      <div class="d-print-none">
        <v-card-title>Dockets</v-card-title>
        <v-data-table
          :headers="headers"
          :items="consignment.deliveryDockets"
          class="click-table"
          @click:row="selectDeliveryDocket"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import { get, call } from "vuex-pathify";
import NetSummaryTable from "@/components/batches/net-summary-table.vue";
import AverageSummaryTable from "@/components/batches/average-summary-table.vue";

export default {
  data() {
    return {
      saving: false,
      loading: true,
      headers: [
        { text: "Docket Number", value: "reference" },
        { text: "Address", value: "address" },
        { text: "Post Code", value: "postCode" },
        { text: "Client", value: "clientName" },
        { text: "Quantity", value: "doorCount" },
        { text: "Weight", value: "weight" },
        { text: "Remove", value: "remove" }
      ]
    };
  },
  components: {
    NetSummaryTable,
    AverageSummaryTable
  },
  computed: {
    consignment: get("consignments/consignment"),
    costSummary: get("consignments/costSummary"),
    mapsLink() {
      if (!this.consignment.deliveryDockets.length) return "";
      const postcodes = [];
      this.consignment.deliveryDockets.forEach(element => {
        if (element.postCode) {
          postcodes.push(element.postCode);
        }
      });
      if (!postcodes.length) return "";

      let linkString = "https://www.google.com/maps/dir/";

      postcodes.forEach(element => {
        if (element) {
          linkString += `${element}/`;
        }
      });
      return linkString;
    },
    breadcrumbs() {
      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Consignments",
          to: "/consignments",
          exact: true
        },
        {
          text: this.consignment.reference
        }
      ];
    }
  },
  async created() {
    await this.loadConsignments(this.$route.params.id);
    await this.loadCostSummary(this.$route.params.id);
    this.loading = false;
  },

  methods: {
    loadConsignments: call("consignments/loadConsignment"),
    saveConsignment: call("consignments/saveConsignment"),
    deleteConsignment: call("consignments/deleteConsignment"),
    loadCostSummary: call("consignments/loadCostSummary"),

    async dateChanged(date) {
      this.consignment.dispatchDate = date;
      await this.saveChangesToConsignment(this.consignment);
    },
    async updateConsignment() {
      try {
        this.saving = true;
        await this.saveChangesToConsignment(this.consignment);
      } finally {
        this.saving = false;
      }
    },
    async deleteInvoice() {
      await this.deleteConsignment();
      this.$router.push(`/consignments`);
    },
    selectDeliveryDocket(row) {
      this.$router.push(`/delivery-dockets/${row.id}`);
    },
    openPrintable() {
      this.$router.push(`/consignments/${this.$route.params.id}/print`);
    },
    async saveChangesToConsignment() {
      try {
        this.saving = true;
        await this.saveConsignment(this.consignment);
      } finally {
        this.saving = false;
      }
    },
    openMap() {
      window.open(this.mapsLink, "_blank");
    }
  }
};
</script>
