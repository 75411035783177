<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <v-card>
      <EditForm ref="editForm" @on:save="save" @on:delete="remove" />
      <InvoiceForm ref="addForm" :invoice="invoice" />
      <v-card-title> {{ subContractor.name }} </v-card-title>

      <v-card-text>
        <div style="max-width: 300px">
          <v-row>
            <v-col class="py-0">Number:</v-col>
            <v-col class="py-0">{{ subContractor.phone }}</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">Email:</v-col>
            <v-col class="py-0">{{ subContractor.email }}</v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn small color="primary" @click="edit(subContractor)"
          >Edit Sub-Contractor</v-btn
        >
        <v-btn small color="error" @click="remove(subContractor)"
          >Delete Sub-Contractor</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card class="mt-10">
      <v-card-title>
        Invoices
        <v-btn small color="primary" class="ml-4" @click="add()"
          >Create New Invoice</v-btn
        ></v-card-title
      >

      <v-data-table
        class="click-table"
        :headers="headers"
        :items="invoices"
        :items-per-page="20"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | euDate }}
        </template>
        <template v-slot:[`item.net`]="{ item }">
          £ {{ item.net | number("0,0.00") }}
        </template>
        <template v-slot:[`item.amountUnallocated`]="{ item }">
          £ {{ item.totalUnallocated | number("0,0.00") }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="mr-2" small color="primary" @click="openInvoice(item)">
            View
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import EditForm from "@/components/forms/sub-contractors/edit-form.vue";
import InvoiceForm from "@/components/forms/sub-contractors/invoice-form.vue";

export default {
  components: { EditForm, InvoiceForm },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Invoice Date", value: "date" },
        { text: "Invoice Reference", value: "invoiceReference" },
        { text: "Grady Reference", value: "gradyReference" },
        { text: "Net", value: "net" },
        { text: "Number of Doors", value: "doorCount" },
        { text: "Amount Unallocated", value: "amountUnallocated" },
        { text: "", value: "actions", align: "end", sortable: false }
      ]
    };
  },
  async created() {
    await this.loadSubcontractorInvoices(this.$route.params.id);
    await this.loadSubcontractors();
  },
  computed: {
    invoices: get("subContractors/subContractorInvoices"),
    subContractors: get("subContractors/subContractors"),
    subContractor() {
      if (!this.subContractors.length)
        return { name: "", phone: "", email: "" };
      return this.subContractors.find(
        x => x.id === parseInt(this.$route.params.id)
      );
    },
    invoice() {
      return {
        subContractorId: this.subContractor.id,
        net: 0,
        date: new Date().toISOString().substr(0, 10)
      };
    },
    breadcrumbs() {
      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Subcontractors",
          to: "/fitters/sub-contractors",
          exact: true
        },
        {
          text: this.subContractor.name,
          to: "/fitters/sub-contractors/" + this.subContractor.id,
          exact: true
        }
      ];
    }
  },
  methods: {
    loadSubcontractorInvoices: call("subContractors/loadSubcontractorInvoices"),
    loadSubcontractors: call("subContractors/loadSubcontractors"),
    saveSubContractor: call("subContractors/saveSubcontractor"),
    removeContractor: call("subContractors/removeSubcontractor"),
    edit(sub) {
      this.$refs.editForm.open(sub);
    },
    add() {
      this.$refs.addForm.open();
    },
    selectSubContractor(subContractor) {
      this.$router.push(`sub-contractors/${subContractor.id}`);
    },
    async save(data) {
      this.$refs.editForm.setLoading(true);
      try {
        await this.saveSubContractor(data);
      } finally {
        this.$refs.editForm.setLoading(false);
        this.$refs.editForm.close();
      }
    },
    async remove(sub) {
      if (!confirm("Are you sure you want to delete this Subcontractor?"))
        return;
      await this.removeContractor(sub);
      this.$router.push(`/fitters/sub-contractors`);
    },
    openInvoice(item) {
      this.$router.push(
        `/fitters/sub-contractors/${this.subContractor.id}/invoice/${item.id}`
      );
    }
  }
};
</script>
