<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider=">"
      class="pt-2 pb-4 px-0"
      data-cy="breadcrumb"
    >
    </v-breadcrumbs>
    <EditForm ref="form" @on:save="save" />
    <v-card :loading="loading">
      <v-card-title>
        Sub Contractors
        <v-btn class="ml-4" small color="primary" @click="add" data-cy="add">
          Add Sub Contractor
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!subContractors.length">
        No Sub Contractors
      </v-card-text>
      <v-simple-table v-else data-name="sub-contractors" class="click-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subContractor in subContractors" :key="subContractor.id">
              <td>{{ subContractor.name }}</td>
              <td>{{ subContractor.email }}</td>
              <td>{{ subContractor.phone }}</td>
              <td>{{ subContractor.address | truncate(50) }}</td>
              <td>
                <v-btn
                  class="primary"
                  small
                  @click="selectSubContractor(subContractor)"
                  >View</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import EditForm from "@/components/forms/sub-contractors/edit-form.vue";

export default {
  components: {
    EditForm
  },
  title: "Sub Contractors",
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Sub Contractors",
          to: "/fitters/sub-contractors",
          exact: true
        }
      ]
    };
  },
  computed: {
    subContractors: get("subContractors/subContractors")
  },
  async created() {
    try {
      await this.loadSubcontractors();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadSubcontractors: call("subContractors/loadSubcontractors"),
    saveSubContractor: call("subContractors/saveSubcontractor"),
    removeContractor: call("subContractors/removeSubcontractor"),
    add() {
      this.$refs.form.open();
    },
    open(sub) {
      this.$refs.form.open(sub);
    },
    selectSubContractor(subContractor) {
      this.$router.push(`sub-contractors/${subContractor.id}`);
    },
    async save(data) {
      this.$refs.form.setLoading(true);
      try {
        await this.saveSubContractor(data);
      } finally {
        this.$refs.form.setLoading(false);
        this.$refs.form.close();
      }
    }
  }
};
</script>
