<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer> </v-spacer>
          <span v-if="charge.id">
            <v-btn
              small
              color="primary"
              v-if="!charge.lockedStatus"
              @click="lockCharge"
            >
              <v-icon small left>mdi-lock</v-icon>Lock</v-btn
            >
            <v-btn small color="primary" v-else @click="lockCharge">
              <v-icon small left>mdi-lock-open-variant</v-icon>Unlock</v-btn
            ></span
          >
        </v-card-title>
        <v-card-text>
          <BaseText
            :disabled="charge.lockedStatus"
            label="Description"
            v-model="charge.description"
          />
          <BaseText
            :disabled="charge.lockedStatus"
            label="Quantity"
            v-model.number="charge.quantity"
          />
          <BaseText
            :disabled="charge.lockedStatus"
            label="Price"
            v-model.number="charge.price"
          />
          <v-select
            label="Phase"
            :items="phases"
            v-model="charge.phaseId"
            item-text="phaseReference"
            item-value="phaseId"
            :disabled="charge.lockedStatus"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :loading="saving" @click="save">
            Save
          </v-btn>
          <v-btn text color="error" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="mb-2">
      <v-card-title>
        Additional Costs
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addCost">Add Cost</v-btn>
      </v-card-title>
    </v-card>
    <v-card :loading="saving">
      <v-card-text>
        <table class="normal-table">
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Item Price</th>
            <th>Phase</th>
            <th>Price</th>
          </tr>
          <tr v-for="charge in charges" :key="charge.id">
            <td>{{ charge.description }}</td>
            <td>{{ charge.quantity }}</td>
            <td>{{ charge.price | number("0,0.00") }}</td>
            <td>{{ charge.phaseReference }}</td>
            <td>{{ charge.chargePrice | number("0,0.00") }}</td>
            <td class="text-right">
              <v-btn icon color="warning darken-1" @click="edit(charge)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon color="red" @click="remove(charge)">
                <v-icon>mdi-close-box-outline</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="7">
              <v-divider class="my-2"></v-divider>
            </td>
          </tr>
          <tr>
            <th colspan="4" class="text-right pr-4">Total</th>
            <td>{{ total | number("0,0.00") }}</td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      charge: {}
    };
  },
  props: ["charges", "total", "saving", "phases"],
  computed: {
    title() {
      if (this.charge.id) return "Edit Cost";
      return "New Cost";
    }
  },
  methods: {
    addCost() {
      this.charge = {
        description: "",
        quantity: 1,
        price: 0
      };
      this.dialog = true;
    },
    edit(charge) {
      this.charge = { ...charge };
      this.dialog = true;
    },
    remove(charge) {
      this.$emit("on:removeCharge", charge.id);
    },
    save() {
      this.$emit("on:saveCharge", this.charge);
      this.dialog = false;
    },
    lockCharge() {
      this.charge.lockedStatus = !this.charge.lockedStatus;
      this.$emit("on:saveCharge", this.charge);
    }
  }
};
</script>
