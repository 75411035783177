<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title>Select Invoice To Assign</v-card-title>
        <v-simple-table class="click-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Invoice Grady Reference</th>
                <th class="text-left">Total Unallocated</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in filteredInvoices"
                :key="invoice.id"
                @click="assignInvoice(invoice)"
              >
                <td>
                  {{ invoice.gradyReference }}
                </td>
                <td>
                  {{ invoice.totalUnallocated }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-btn color="red" text @click="dialog = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-btn
      v-if="fitterLocked"
      :loading="loading.generated"
      color="warning"
      class="mb-4"
      @click="unlockFitter"
    >
      Unlock Fire Cert
    </v-btn>

    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            v-model="installerId"
            item-text="name"
            item-value="id"
            :items="installers"
            :disabled="fitterLocked"
            label="Installer"
          ></v-select>
          <v-select
            v-model="fitterId"
            item-value="id"
            item-text="name"
            :items="installerFitters"
            :disabled="fitterLocked"
            label="Fitter"
            ><template v-slot:item="{ item }"
              >{{ item.name }} - {{ item.subContractor }}</template
            ></v-select
          >
          <v-text-field
            :value="fitterPay"
            @change="e => (fitterPay = parseFloat(e || 0))"
            :disabled="door.invoiceId !== undefined"
            label="Fitter pay"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="px-sm-6">
          <v-text-field
            :value="plannedFittingDate"
            :disabled="fitterLocked"
            label="Planned Fitting Date"
            type="date"
            @change="e => (plannedFittingDate = e)"
          >
          </v-text-field>
          <v-text-field
            :value="actualFittedDate"
            :disabled="fitterLocked"
            label="Actual Fitted Date"
            type="date"
            @change="e => (actualFittedDate = e)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            :value="certifiedDoorSet"
            :loading="loading.certifiedDoorSet"
            :items="['Yes', 'No']"
            :disabled="fitterLocked"
            label="Certified Door Set"
            @change="e => (certifiedDoorSet = e)"
          ></v-select>
          <v-text-field
            :value="installerGuideUsed"
            :loading="loading.installerGuideUsed"
            :disabled="fitterLocked"
            label="Installer Guide Used"
            @change="e => (installerGuideUsed = e)"
          >
          </v-text-field>
          <v-select
            :value="installerPlugFitted"
            :loading="loading.installerPlugFitted"
            :disabled="fitterLocked"
            :items="['Yes', 'No']"
            label="Installer Plug Fitted"
            @change="e => (installerPlugFitted = e)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- vuetify checkbox is fooked -->
          <v-btn
            :disabled="!fitterPay || !this.subContractorId"
            class="mb-4"
            small
            tile
            color="primary"
            @click="loadInvoices"
            v-if="!door.invoiceId"
          >
            Allocate fitter pay to invoice
          </v-btn>
          <v-row
            ><v-col cols="12" sm="4"
              ><v-checkbox
                v-model="fittingCompleted"
                label="Fitting Completed"
              ></v-checkbox></v-col
          ></v-row>
          <v-alert
            v-if="fitterId && !this.subContractorId"
            border="top"
            type="error"
            color="red lighten-2"
            dark
          >
            This fitter has not been assigned a subcontractor. Please assign a
            subcontractor in the installer's page before progressing.
            <v-btn small @click="navigateToFitter"> here</v-btn>
          </v-alert>

          <p v-if="door.invoiceId" class="ma-1" color="grey" plain x-small>
            Door has been assigned to Invoice: {{ invoice.gradyReference }}
          </p>
          <v-btn
            v-if="door.invoiceId"
            small
            color="primary"
            target="_blank"
            :to="
              `/fitters/sub-contractors/${this.subContractorId}/invoice/${this.door.invoiceId}`
            "
          >
            Open Invoice
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-textarea
            :value="installerComment"
            :loading="loading.installerComment"
            :disabled="fitterLocked"
            label="BMTrada Installer Comments"
            @change="e => (installerComment = e)"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            :value="fitterComment"
            :loading="loading.fitterComment"
            :disabled="fitterLocked"
            label="Fitter Comments"
            @change="e => (fitterComment = e)"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            :value="adminComment"
            :loading="loading.adminComment"
            :disabled="fitterLocked"
            label="Admin Comments"
            @change="e => (adminComment = e)"
          ></v-textarea>
        </v-col>
      </v-row>
      <table v-if="fireCertInstallationReference" class="normal-table">
        <thead>
          <tr>
            <th>Installation Reference</th>
            <th>Fire Cert Date</th>
            <th>Generated By</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ fireCertInstallationReference }}</td>
            <td>{{ fireCertGeneratedDate | euDate }}</td>
            <td>{{ fireCertGeneratedBy }}</td>
            <td>
              <v-btn color="primary" small @click="viewFireCert">
                View Cert
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <v-btn
        v-else
        class="mt-6"
        :loading="loading.generated"
        :disabled="!certAllowed"
        color="primary"
        @click="generateFireCert"
      >
        Generate BMTrada Fire Cert
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";
import moment from "moment";

function syncDate(prop) {
  return {
    [prop]: {
      get() {
        if (!this.door[prop]) return null;

        return moment(this.door[prop]).format("YYYY-MM-DD");
      },
      set(value) {
        if (!value) value = null;

        this.updateDoor(prop, value);
      }
    }
  };
}

export default {
  mixins: [mixin],
  data() {
    return {
      dialog: false,
      loading: {
        installationReference: false,
        generated: false
      }
    };
  },
  computed: {
    door: get("doors/door"),
    installers: get("installers/installers"),
    fitters: get("fitters/fitters"),
    invoice: get("subContractors/subContractorInvoice"),
    invoices: get("subContractors/subContractorInvoices"),
    ...syncProp("fitterId"),
    ...syncProp("fitterPay"),
    ...syncProp("fitterOkToPay"),
    ...syncProp("certifiedDoorSet"),
    ...syncProp("installerGuideUsed"),
    ...syncProp("installerPlugFitted"),
    ...syncProp("installationReference"),
    ...syncProp("fitterComment"),
    ...syncProp("installerComment"),
    ...syncProp("adminComment"),
    ...syncProp("fittingCompleted"),
    ...syncProp("fireCertGeneratedBy"),
    ...syncProp("fireCertGeneratedDate"),
    ...syncProp("fireCertInstallationReference"),
    ...syncDate("plannedFittingDate"),
    ...syncDate("actualFittedDate"),
    installerId: {
      get() {
        return this.door.installerId;
      },
      async set(value) {
        this.updateDoor("installerId", value);
        this.updateDoor("fitterId", null);
        await this.loadFitters(value);
      }
    },
    filteredInvoices() {
      if (!this.invoices.length) return [];
      return this.invoices.filter(x => x.totalUnallocated > 0);
    },
    fitter() {
      return this.door.fitter || {};
    },
    installerFitters() {
      if (!this.door.installerId) return [];

      return this.fitters.filter(f => f.active);
    },
    outlined() {
      return !this.fitterOkToPay;
    },
    certAllowed() {
      return (
        !(
          this.fitterId &&
          this.actualFittedDate &&
          this.plannedFittingDate &&
          this.certifiedDoorSet &&
          this.installerGuideUsed &&
          this.installerPlugFitted
        ) === false
      );
    },
    fitterLocked() {
      if (this.fireCertInstallationReference) {
        return true;
      }
      return false;
    },
    subContractorId() {
      const fitter = this.fitters.find(x => x.id === this.door.fitterId);
      if (!fitter) return null;
      return fitter.subContractorId;
    }
  },
  async created() {
    await this.loadInstallers();

    if (this.door.installerId) {
      await this.loadFitters(this.door.installerId);
    }
    if (this.door.invoiceId && this.subContractorId) {
      await this.loadSubcontractorInvoice({
        id: this.door.invoiceId,
        subContractorId: this.subContractorId
      });
    }
  },
  methods: {
    genFireCert: call("doors/generateFireCert"),
    loadInstallers: call("installers/loadInstallers"),
    loadFitters: call("fitters/loadFitters"),
    loadSubcontractorInvoices: call("subContractors/loadSubcontractorInvoices"),
    allocateInvoice: call("doors/allocateInvoice"),
    loadSubcontractorInvoice: call("subContractors/loadSubcontractorInvoice"),

    viewFireCert() {
      const routeData = this.$router.resolve(`/fire-cert/${this.door.id}`);
      window.open(routeData.href, "_blank");
    },
    async generateFireCert() {
      this.loading.generated = true;
      await this.genFireCert(this.$auth.name);
      this.loading.generated = false;

      this.viewFireCert();
    },
    async unlockFitter() {
      this.fireCertInstallationReference = null;
    },
    async loadInvoices() {
      if (this.subContractorId) {
        await this.loadSubcontractorInvoices(this.subContractorId);
        this.dialog = true;
      }
    },
    async assignInvoice(invoice) {
      try {
        this.door.invoiceId = invoice.id;
        this.door.fitterPayApprovedBy = this.$auth.name;

        await this.allocateInvoice(this.door);
        await this.loadSubcontractorInvoice(invoice);
      } finally {
        this.dialog = false;
      }
    },
    navigateToInvoice() {
      this.$router.push(
        `/fitters/sub-contractors/${this.subContractorId}/invoice/${this.door.invoiceId}`
      );
    },
    navigateToFitter() {
      this.$router.push(`/fitters/installers/${this.installerId}`);
    }
  }
};
</script>
