<template>
  <div class="mt-4 sheet section-to-print top-page">
    <NarrowRow>
      <v-col>
        <v-simple-table class="narrow-table" style="max-width: 600px">
          <template v-slot:default>
            <tbody>
              <tr>
                <th>Door Style</th>
                <td>{{ door.doorStyle }}</td>
              </tr>
              <tr>
                <th>Frame Width</th>
                <td>{{ door.width }}</td>
              </tr>
              <tr>
                <th>Frame Height</th>
                <td>{{ door.height }}</td>
              </tr>
              <tr>
                <td class="gap-row" colspan="2"></td>
              </tr>
              <tr>
                <th>Overall Packer Left</th>
                <td>{{ billOfMaterials.packers.packerLeft }}</td>
              </tr>
              <tr>
                <th>Overall Packer Top</th>
                <td>{{ billOfMaterials.packers.packerTop }}</td>
              </tr>
              <tr>
                <th>Overall Packer Right</th>
                <td>{{ billOfMaterials.packers.packerRight }}</td>
              </tr>
              <tr>
                <th>Overall Packer Threshold</th>
                <td>{{ billOfMaterials.packers.packerThreshold }}</td>
              </tr>
              <tr>
                <td colspan="2" class="gap-row"></td>
              </tr>
              <tr>
                <th>Overall Width</th>
                <td>
                  {{ billOfMaterials.overallWidth }}
                </td>
              </tr>
              <tr>
                <th>Overall Height</th>
                <td>{{ billOfMaterials.overallHeight }}</td>
              </tr>
              <tr>
                <td colspan="2" class="gap-row"></td>
              </tr>
              <tr>
                <th>Fanlight Packer Left</th>
                <td>{{ billOfMaterials.packers.fanlightPackerLeft }}</td>
              </tr>
              <tr>
                <th>Fanlight Packer Right</th>
                <td>{{ billOfMaterials.packers.fanlightPackerRight }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="auto" class="pr-2">
        <BomImage :door="door" :image="frontImage" :key="frontImage.imageURL">
          <FrameWithDimmLines :door="door" />
        </BomImage>
      </v-col>
    </NarrowRow>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import FrameWithDimmLines from "@gradys/vue-base/components/frame/frame-with-dimm-lines";
import BomImage from "@/components/images/bom-image.vue";

export default {
  components: {
    FrameWithDimmLines,
    BomImage
  },
  computed: {
    door: get("doors/door"),
    frontImage: get("doors/frontImage"),
    frameDimms: get("doors/frameDimms"),
    billOfMaterials: get("billOfMaterials/billOfMaterials")
  }
};
</script>

<style scoped>
.narrow-table.v-data-table td.gap-row {
  height: 15px;
}
.svg__wrap {
  max-width: 100%;
  height: 550px;
  min-width: 420px;
  border: solid 1px #ccc;
  padding: 4px;
}
</style>
