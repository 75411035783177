<template>
  <NarrowRow>
    <v-dialog v-model="dialog" max-width="650" scrollable>
      <FrameSelection @on:frame-clicked="frameClicked" />
    </v-dialog>
    <v-dialog v-model="paneDialog" max-width="650" persistent>
      <v-card>
        <v-card-title>Set Pane Material {{ pane.index + 1 }}</v-card-title>
        <v-card-subtitle>{{ panelSubtitle }}</v-card-subtitle>
        <v-card-text>
          <BaseSelect
            label="Material"
            v-if="pane.type !== 'door'"
            v-model="pane.material"
            :items="materials"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="savePane">Set Material</v-btn>
          <v-btn text color="error" @click="paneDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col>
      <div style="height: 5px">
        <v-progress-linear :active="saving" indeterminate></v-progress-linear>
      </div>
      <div>
        <strong>Existing Clear Opening</strong>
      </div>
      <div class="side-by-side">
        <BaseText
          label="Existing Clear Opening"
          type="number"
          v-model="existingOpening"
        />
      </div>
      <div class="mt-2">
        <strong>Frame Opening</strong>
      </div>
      <div class="side-by-side">
        <v-text-field
          label="Frame Width"
          type="number"
          :value="frameDimms.frameWidth"
          @change="onWidthUpdated"
        />
        <v-text-field
          label="Frame Height"
          type="number"
          :value="frameDimms.frameHeight"
          @change="onHeightUpdated"
        />
      </div>
      <div class="mt-2">
        <strong>Door Dimensions</strong>
      </div>
      <div class="side-by-side">
        <v-text-field
          label="Door Width"
          type="number"
          append-icon="mdi-lock"
          background-color="grey lighten-3"
          readonly
          :value="frameDimms.doorWidth"
        />
        <v-text-field
          label="Door Height"
          type="number"
          readonly
          background-color="grey lighten-3"
          append-icon="mdi-lock"
          :value="frameDimms.doorHeight"
        />
      </div>
      <div class="mt-2">
        <strong>Fanlight Dimension</strong>
      </div>
      <div class="side-by-side">
        <v-text-field
          label="Fanlight Width"
          type="number"
          :disabled="!anyFanlight"
          readonly
          background-color="grey lighten-3"
          append-icon="mdi-lock"
          :value="frameDimms.fanlightWidth"
        />
        <v-text-field
          label="Fanlight Height"
          :disabled="!anyFanlight"
          type="number"
          :value="frameDimms.fanlightHeight"
          @change="onFanlightHeightUpdated"
        />
      </div>
      <div class="mt-2">
        <strong>Sidepanel Widths</strong>
      </div>
      <div class="side-by-side">
        <v-text-field
          label="Right Width"
          :disabled="!showRt"
          type="number"
          :value="frameDimms.rightSideWidth"
          @change="onRightUpdated"
        />
        <v-text-field
          label="Left Width"
          type="number"
          :disabled="!showLt"
          :value="frameDimms.leftSideWidth"
          @change="onLeftWidthUpdated"
        />
      </div>
      <div class="mt-2">
        <strong>Sidepanel Heights</strong>
      </div>
      <div class="side-by-side">
        <v-text-field
          label="Top Sidelight"
          type="number"
          :disabled="!anySidePanel"
          :value="frameDimms.topSidelightHeight"
          @change="onTopHeightUpdated"
        />
        <v-text-field
          label="Bottom Sidelight"
          type="number"
          readonly
          :disabled="!anySidePanel"
          background-color="grey lighten-3"
          append-icon="mdi-lock"
          :value="frameDimms.bottomSidelightHeight"
        />
      </div>
      <div style="height: 5px">
        <v-progress-linear :active="saving" indeterminate></v-progress-linear>
      </div>
    </v-col>
    <v-col>
      <div class="mb-4">
        <strong class="mr-4">Frame Type: {{ frameTypeName }}</strong>
        <v-btn small color="primary" @click="dialog = true">
          Change Frame Type
        </v-btn>
        <v-divider class="my-2"></v-divider>
      </div>
      <div class="caption">Click on Pane to Set Material</div>
      <BomImage :door="door" :image="frontImage" :showButton="true">
        <FrameWithDimmLines
          :door="door"
          id="frame-diagram"
          @on:pane-clicked="paneClicked"
        />
      </BomImage>
    </v-col>
  </NarrowRow>
</template>

<script>
import { get, call } from "vuex-pathify";

import { syncProp, mixin } from "@gradys/vue-base/components/forms/mixins";
import frameMixin from "@gradys/vue-base/components/frame/frame-mixin-2";
import { frameTypes } from "@gradys/vue-base/store/lookups/lookup-values";
import { updateType } from "@gradys/vue-base/store/util/frame-sizing";
import BomImage from "@/components/images/bom-image.vue";
import FrameSelection from "@gradys/vue-base/components/frame/frame-selection";
import FrameWithDimmLines from "@gradys/vue-base/components/frame/frame-with-dimm-lines";

export default {
  mixins: [mixin, frameMixin],
  components: {
    FrameSelection,
    FrameWithDimmLines,
    BomImage
  },
  data() {
    return {
      dialog: false,
      paneDialog: false,
      saving: false,
      pane: {
        width: 0,
        height: 0,
        index: 0,
        type: "",
        material: null
      }
    };
  },
  computed: {
    ...syncProp("existingOpening"),
    ...syncProp("frameType"),
    materials: get("lookups/materials"),
    door: get("doors/door"),
    order: get("orders/order"),
    frameDimms: get("doors/frameDimms"),
    frontImage: get("doors/frontImage"),
    frameTypeName() {
      if (!this.door) return "";
      if (!this.door.frameType) return "Not Set";

      const match = frameTypes.find(f => f.value === this.door.frameType);

      return match ? match.text : "No Frame";
    },
    panelSubtitle() {
      if (!this.pane.type) return "";

      switch (this.pane.type) {
        case "door":
          return "Door";
        case "lb":
          return "Left Bottom";
        case "lt":
          return "Left Top";
        case "rb":
          return "Right Bottom";
        case "rt":
          return "Right Top";
        case "fl":
        case "wfl-l":
          return "Fanlight Left";
        case "fr":
        case "wfl-r":
          return "Fanlight Right";
      }
      return this.pane.type;
    }
  },
  methods: {
    frameSelected: call("doors/frameSelected"),
    updateDimm: call("doors/updateDimm"),
    updatePane: call("doors/updatePane"),
    save: call("doors/save"),
    async frameClicked(value) {
      this.frameSelected(value);
      this.dialog = false;
      await this.saveDoor();
    },
    paneClicked(pane) {
      const index = this.door.panes.findIndex(f => f.type === pane);
      this.editPane(index);
    },
    editPane(index) {
      this.pane = {
        ...this.door.panes[index]
      };
      this.pane.index = index;
      this.paneDialog = true;
    },
    async savePane() {
      this.updatePane(this.pane);
      this.paneDialog = false;
      await this.saveDoor();
    },
    onWidthUpdated(value) {
      this.updateDimm({ updateType: updateType.frameWidth, value });
      return this.saveDoor();
    },
    onHeightUpdated(value) {
      this.updateDimm({ updateType: updateType.frameHeight, value });
      return this.saveDoor();
    },
    onFanlightHeightUpdated(value) {
      this.updateDimm({ updateType: updateType.fanlightHeight, value });
      return this.saveDoor();
    },
    onLeftWidthUpdated(value) {
      this.updateDimm({ updateType: updateType.leftSideWidth, value });
      return this.saveDoor();
    },
    onRightUpdated(value) {
      this.updateDimm({ updateType: updateType.rightSideWidth, value });
      return this.saveDoor();
    },
    onTopHeightUpdated(value) {
      this.updateDimm({ updateType: updateType.topSidelightHeight, value });
      return this.saveDoor();
    },
    async saveDoor() {
      this.saving = true;
      try {
        this.save();
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.side-by-side {
  display: flex;

  .v-input {
    max-width: 150px;
    margin-right: 2rem;
  }
}
</style>
