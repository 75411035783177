<template>
  <div class="frame__wrap">
    <div class="fanlights">
      <div class="packer packer-right" v-if="hasFanlightPackerRight"></div>
      <Panel
        v-if="showWfll"
        :height="fanlightHeight"
        :width="wideFanlightWidth"
      >
        <PanelInner pane="wfl-l" @click.native="paneClicked('wfl-l')" :clickable="hasClickHandler"  />
      </Panel>
      <Panel
        v-if="showWflr"
        :height="fanlightHeight"
        :width="wideFanlightWidth"
      >
        <PanelInner pane="wfl-r" @click.native="paneClicked('wfl-r')" :clickable="hasClickHandler" />
      </Panel>
      <Panel
        v-if="showFr"
        :height="fanlightHeight"
        :width="rightFanlightWidth"
        :joined-right="true"
      >
        <PanelInner pane="fr" @click.native="paneClicked('fr')" :clickable="hasClickHandler" />
      </Panel>
      <Panel
        v-if="showFanlight"
        pane="fanlight"
        :height="fanlightHeight"
        :width="fanlightWidth"
        :joined-left="showFr"
        :joined-right="showFl"
      >
        <PanelInner pane="fanlight" @click.native="paneClicked('fanlight')" :clickable="hasClickHandler" />
      </Panel>
      <Panel
        v-if="showFl"
        :height="fanlightHeight"
        :width="leftFanlightWidth"
        :joined-left="true"
      >
        <PanelInner pane="fl" @click.native="paneClicked('fl')" :clickable="hasClickHandler" />
      </Panel>
      <div class="packer packer-left" v-if="hasFanlightPackerLeft"></div>
    </div>
    <div class="main">      
      <Panel
        v-if="showRt"
        class="sr"
        :height="427"
        :width="rightWidth"
      >
        <div class="side-panel">
          <PanelInner pane="rt" @click.native="paneClicked('rt')" :clickable="hasClickHandler" class="side-pane" :style="middleStyle" />
          <div class="mullion"></div>
          <PanelInner pane="rb" @click.native="paneClicked('rb')" :clickable="hasClickHandler" class="side-pane" :style="bottomStyle" />
        </div>
      </Panel>
      <Panel class="door__wrap" :height="417" :width="doorWidthPx">
        <Door :door="door" />
      </Panel>      
      <Panel
        v-if="showLt"
        class="sl"
        :height="427"
        :width="leftWidth"
      >
        <div class="side-panel">
          <PanelInner pane="lt" @click.native="paneClicked('lt')" :clickable="hasClickHandler" class="side-pane" :style="middleStyle" />
          <div class="mullion"></div>
          <PanelInner pane="lb" @click.native="paneClicked('lb')" :clickable="hasClickHandler" class="side-pane" :style="bottomStyle" />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
import Door from "@gradys/vue-base/components/doors/door-frame-base";
import Panel from "@gradys/vue-base/components/frame/panel";
import PanelInner from "@gradys/vue-base/components/frame/panel-inner";
import frameMixin from '@gradys/vue-base/components/frame/frame-mixin-2';
import { get } from 'vuex-pathify';

export default {
  mixins: [frameMixin],
  components: {
    Door,
    Panel,
    PanelInner,
  },
  props: {
    door: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      doorWidthPx: 202
    }
  },
  computed: {
    frameDimms: get("doors/frameDimms"),
    pixelHeightPerMm: get("doors/pixelHeightPerMm"),
    pixelWidthPerMm: get("doors/pixelWidthPerMm"),
    hasClickHandler() {
      if(this.$listeners && this.$listeners["on:pane-clicked"]) {
        return true;
      }

      return false;
    },
    fanlightWidth() {
      if (this.showLt && this.showRt)
        return this.doorWidthPx;

      if (!this.showLt && !this.showRt)
        return this.doorWidthPx - this.totalPackersWidth;

      if (this.showLt && this.hasFanlightPackerRight) 
        return this.doorWidthPx - 5;

      if (this.showRt && this.hasFanlightPackerLeft) 
        return this.doorWidthPx - 5;        

      return this.doorWidthPx;
    },
    leftWidth() {
      if (!this.showLt) return 0;
      return this.calcWidth("lt");
    },
    leftFanlightWidth() {
      if (!this.leftWidth) return 0;

      const packerWidth = this.hasFanlightPackerLeft ? 5 : 0;

      return this.leftWidth - packerWidth;
    },
    rightWidth() {
      if (!this.showRt) return 0;
      return this.calcWidth("rt");
    },
    rightFanlightWidth() {
      if (!this.rightWidth) return 0;

      const packerWidth = this.hasFanlightPackerRight ? 5 : 0;

      return this.rightWidth - packerWidth;
    },
    fanlightHeight() {
      if (!this.anyFanlight) return 0;

      if (this.showFanlight) return this.calcHeight("fanlight");
      if (this.showWflr) return this.calcHeight("wfl-r");
      if (this.showWfll) return this.calcHeight("wfl-l");

      return 0;
    },   
    wideFanlightWidth() {
      if (!this.leftWidth) {
        return (this.rightWidth + this.doorWidthPx) - this.totalPackersWidth;
      }

      return (this.leftWidth + this.doorWidthPx) - this.totalPackersWidth;
    },
    middleHeight() {
      return this.frameDimms.topSidelightHeight * this.pixelHeightPerMm;
    },
    bottomHeight() {
      return this.frameDimms.bottomSidelightHeight * this.pixelHeightPerMm;
    },
    middleStyle() {
      return {
        height: `${this.middleHeight - 10}px`,
      };
    },
    bottomStyle() {
      return {
        height: `${this.bottomHeight - 10}px`,
      };
    },
    hasFanlightPackerLeft() {
      return this.hasPacker("Left");
    },
    hasFanlightPackerRight() {
      return this.hasPacker("Right");
    },
    totalPackersWidth() {
      let packers = 0;
      if (this.hasFanlightPackerLeft) packers += 5;
      if (this.hasFanlightPackerRight) packers += 5;

      return packers;
    }
  },
  methods: {
    hasPacker(side) {
      const packer = this.door[`fanlightPacker${side}`];
      if (!packer || packer.toLowerCase() === "none") return false;

      return true;
    },
    calcWidth(paneType) {
      const pane = this.door.panes.find((p) => p.type === paneType);
      if (!pane) return 0;

      return pane.width * this.pixelWidthPerMm;
    },
    calcHeight(paneType) {
      const pane = this.door.panes.find((p) => p.type === paneType);
      if (!pane) return 0;

      return pane.height * this.pixelHeightPerMm;
    },
    paneClicked(pane) {
      this.$emit("on:pane-clicked", pane);
    }
  },
};
</script>

<style lang="scss" scoped>
.frame__wrap {
  display: flex;
  flex-direction: column;

  .fanlights {
    display: flex;
  }

  .main {
    display: flex;
  }  
}
.packer {
  width: 5px;
  border-left: solid 1px #000;
  border-top: solid 1px #000;

  &.packer-left {
    border-left: none;
    border-right: solid 1px #000;
  }
}

.side-panel {
  display: flex;
  flex-direction: column;

  .mullion {
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
    height: 10px;
  }
}
</style>
