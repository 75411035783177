<template>
  <!-- TODO : Loading Skeleton -->
  <div v-if="quote && !loading">
    <v-breadcrumbs :items="breadcrumbs" divider=">" class="pt-2 pb-4 px-0">
    </v-breadcrumbs>
    <v-container fluid class="px-0">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              View Quote: {{ quote.reference }}
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                small
                @click="removeQuote"
                :loading="saving.remove"
              >
                Remove Quote
              </v-btn>
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th style="width: 200px">Reference</th>
                    <td style="width: 300px">
                      <v-text-field
                        v-model="quote.reference"
                        :loading="saving.reference"
                        @change="updateQuoteDetails('reference')"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th>Client</th>
                    <td>
                      <v-select
                        v-model="quote.clientId"
                        :loading="saving.client"
                        :items="clients"
                        item-value="id"
                        label="Client"
                        @change="updateQuoteDetails('client')"
                        item-text="name"
                      ></v-select>
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td><OrderStatus :status="quote.status" /></td>
                  </tr>
                  <tr>
                    <th>No. Of Doors</th>
                    <td>{{ quote.noDoors }}</td>
                  </tr>
                  <tr>
                    <th>Door Costs</th>
                    <td>{{ quote.doorChargeTotal | number("0,0.00") }}</td>
                  </tr>
                  <tr>
                    <th>Additional Costs</th>
                    <td>{{ quote.quoteChargeTotal | number("0,0.00") }}</td>
                  </tr>
                  <tr>
                    <th>Total Cost</th>
                    <td>{{ quote.totalCharge | number("0,0.00") }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-btn
                color="primary"
                small
                :disabled="quoteInOrder"
                :loading="saving.newOrder"
                @click="newOrder"
              >
                Create Order from Quote
              </v-btn>
              <v-btn
                color="primary"
                small
                v-show="quoteInOrder"
                :to="`/order/${quote.orderId}`"
              >
                View Order
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <AdditionalCosts
            :charges="quote.quoteCharges"
            :total="quote.quoteChargeTotal"
            @on:saveCharge="saveQuoteCharge"
            @on:removeCharge="removeQuoteCharge"
            :saving="saving.additionalCharges"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card class="mb-2">
      <v-card-title>
        Doors
        <v-btn small color="primary" @click="addDoor" class="ml-12">
          Add Door
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card
      class="mb-2"
      v-for="(line, i) in quote.lines"
      :key="line.id"
      :loading="saving.door"
    >
      <v-card-title>
        Door #{{ i + 1 }}
        <span class="pl-12">{{ line.total | number("0,0.00") }}</span>
        <v-btn color="error" class="ml-4" small @click="removeDoor(line.id)">
          Remove
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-2">
        <v-container class="pa-0" fluid>
          <v-row dense>
            <v-col cols="5" class="pr-12">
              <DoorCharges
                :line="line"
                @on:saveDoorCharge="saveDoorCharge"
                @on:removeDoorCharge="removeDoorCharge"
                @on:updateQuoteLine="updateQuoteLine"
                :saving="saving.door"
              />
            </v-col>
            <v-col cols="3">
              <img
                v-if="line.frameImageUrl"
                :src="line.frameImageUrl"
                class="pt-4"
                alt=""
                style="height: 400px"
              />
              <span v-else>
                <v-icon class="mr-2" color="blue">
                  mdi-alert-circle-outline
                </v-icon>
                No Frame Image Available
              </span>
            </v-col>
            <v-col cols="4">
              <table class="normal-table">
                <tr>
                  <th style="width: 150px">Frame Type</th>
                  <td>{{ line.frameType || "-" }}</td>
                </tr>
                <tr>
                  <th>Door Style</th>
                  <td>{{ line.doorStyle || "-" }}</td>
                </tr>
                <tr>
                  <th>Intended Fire Rating</th>
                  <td>{{ line.intendedFireRating || "-" }}</td>
                </tr>
                <tr>
                  <th>Frame Width</th>
                  <td>{{ line.width }}</td>
                </tr>
                <tr>
                  <th>Frame Height</th>
                  <td>{{ line.height }}</td>
                </tr>
                <tr>
                  <th>Door Thickness</th>
                  <td>{{ line.doorThickness }}</td>
                </tr>
                <tr>
                  <th>Threshold</th>
                  <td>{{ line.threshold }}</td>
                </tr>
                <tr>
                  <th>Image Last Updated</th>
                  <td>
                    {{ line.imageUpdated | euDateTime }}
                  </td>
                </tr>
              </table>

              <v-btn
                class="mt-4"
                small
                color="primary"
                :to="`/quotes/${quote.id}/door-config/${line.doorId}`"
              >
                Configure in Door Builder
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-actions>
        <v-btn small color="primary" @click="addDoor">Add Door</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get, call } from "vuex-pathify";
import AdditionalCosts from "@/components/order/order-charges";
import DoorCharges from "@/components/quotes/door-charges";
import OrderStatus from "@/components/order/order-status";
export default {
  components: {
    AdditionalCosts,
    DoorCharges,
    OrderStatus
  },
  computed: {
    quote: get("quotes/quote"),
    clients: get("clients/clients"),
    quoteInOrder() {
      if (!this.quote) return false;

      return this.quote.status === "In Order";
    },
    breadcrumbs() {
      if (!this.quote) return [];

      const { reference } = this.quote;

      return [
        {
          text: "Dashboard",
          to: "/"
        },
        {
          text: "Quotes ",
          to: "/quotes",
          exact: true
        },
        {
          text: `View Quote ${reference}`
        }
      ];
    }
  },
  data() {
    return {
      loading: false,
      saving: {
        reference: false,
        client: false,
        door: false,
        remove: false,
        newOrder: false,
        additionalCharges: false
      },
      headers: [
        {
          text: "Frame Type",
          value: "frameType"
        },
        {
          text: "Quantity",
          value: "quantity"
        },
        {
          text: "Price",
          value: "total"
        },
        { text: "", value: "data-table-expand" }
      ]
    };
  },
  async created() {
    this.loading = true;
    try {
      await this.loadQuote(this.$route.params.id);
      await this.loadClients();
    } finally {
      this.loading = false;
    }
  },

  methods: {
    deleteQuote: call("quotes/deleteQuote"),
    loadQuote: call("quotes/loadQuote"),
    addQuoteLine: call("quotes/addQuoteLine"),
    removeQuoteLine: call("quotes/removeQuoteLine"),
    createOrderFromQuote: call("quotes/createOrderFromQuote"),
    saveQuote: call("quotes/saveQuote"),
    loadClients: call("clients/loadClients"),
    //Door Charges
    saveNewDoorCharge: call("quotes/saveDoorCharge"),
    removeNewDoorCharge: call("quotes/removeDoorCharge"),
    //Quote Charges
    updateLine: call("quotes/updateQuoteLine"),
    saveCharge: call("quotes/saveQuoteCharge"),
    removeCharge: call("quotes/removeQuoteCharge"),
    async removeQuote() {
      if (!confirm("Are you sure you want to delete this quote?")) return;

      try {
        this.saving.remove = true;

        await this.deleteQuote(this.quote);
        this.$router.push("/quotes");
      } finally {
        this.saving.remove = false;
      }
    },
    async addDoor() {
      this.saving.door = true;

      try {
        const { doorId } = await this.addQuoteLine();
        this.$router.push(`/quotes/${this.quote.id}/door-config/${doorId}`);
      } finally {
        this.saving.door = false;
      }
    },
    async removeDoor(id) {
      if (!confirm("Are you sure you want to remove this Door?")) return;
      this.saving.door = true;

      try {
        await this.removeQuoteLine(id);
      } finally {
        this.saving.door = false;
      }
    },
    async newOrder() {
      this.saving.newOrder = true;

      try {
        await this.createOrderFromQuote(this.quote.id);
        this.$router.push(`/order/${this.quote.orderId}`);
      } finally {
        this.saving.newOrder = false;
      }
    },
    saveDoorCharge(charge) {
      return this.doorChange(() => this.saveNewDoorCharge(charge));
    },
    removeDoorCharge(charge) {
      return this.doorChange(() => this.removeNewDoorCharge(charge));
    },
    updateQuoteLine(line) {
      return this.doorChange(() => this.updateLine(line));
    },
    removeQuoteCharge(id) {
      return this.quoteChange(() => this.removeCharge(id));
    },
    saveQuoteCharge(charge) {
      return this.quoteChange(() => this.saveCharge(charge));
    },
    async doorChange(fn) {
      try {
        this.saving.door = true;
        await fn();
      } finally {
        this.saving.door = false;
      }
    },
    async quoteChange(fn) {
      try {
        this.saving.additionalCharges = true;
        await fn();
      } finally {
        this.saving.additionalCharges = false;
      }
    },
    async updateQuoteDetails(item) {
      try {
        this.saving[item] = true;
        await this.saveQuote(this.quote);
      } finally {
        this.saving[item] = false;
      }
    }
  }
};
</script>
