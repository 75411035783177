<template>
  <v-card>
    <v-card-title>
      {{ file.fileName }} {{ windowHeight }}
      <v-spacer></v-spacer>
      <v-btn 
        small 
        color="primary" 
        @click="download"
        :loading="downloading">
        Click here to download if the file does not display
      </v-btn>
    </v-card-title>    
    <v-card-text>
      <div v-if="isImage(file)">
        <v-img :src="fileUrl" contain></v-img>
      </div>
      <div v-else-if="isVideo(file)" class="mx-auto" style="width: 500px">
        <video controls width="500" height="500">
          <source :src="fileUrl">
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <div v-else-if="isPdf(file)">
        <embed :src="fileUrl" width="98%" :height="`${pdfHeight}px`" type="application/pdf" />
      </div>
      <div v-else>
        <v-alert type="info" dense>
          File cannot be displayed. Click the download button above to download the files
        </v-alert>
      </div>      
    </v-card-text>
  </v-card>
</template>

<script>
import { isImage, isVideo, isPdf } from './file-helpers';
import { storage } from '@/plugins/firebase';
import { vueWindowSizeMixin } from "vue-window-size";

export default {
  mixins: [vueWindowSizeMixin],
  props: {
    file: {
      type: Object,
      default() {
        return {}
      }
    }   
  },
  data() {
    return {
      downloading: false,
      fileUrl: ""
    }
  },
  computed: {
    pdfHeight() {
      return this.windowHeight - 240;
    }
  },
  async mounted() {
    if (this.file && this.file.url) {
      this.fileUrl = await storage.ref(this.file.url).getDownloadURL();
    } else {
      console.error('NO FILE');
    }
  },
  methods: {
    isImage,
    isVideo,
    isPdf,
    download() {
      this.downloading = true

      fetch(this.fileUrl)
        .then(r => r.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = this.file.fileName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .finally(() => {
          this.downloading = false
        })
    }
  },
}
</script>