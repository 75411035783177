<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="4">
        <BaseSelect
          v-model="doorStyle"
          label="Door Styles"
          :items="doorStyles"
          @input="doorStyleChanged"
        />
      </v-col>
      <v-col cols="4">
        <ExternalColourSelect
          :external-colour="door.externalColour"
          :leaf-colours="leafColours"
          @on:colour-changed="externalColourChanged"
        />
      </v-col>
      <v-col cols="4">
        <BaseSelect
          v-model="internalColour"
          label="Internal Colour"
          :items="internalColours"
        />
      </v-col>
    </v-row>
    <v-row v-show="hasLeafGlazing">
      <v-col>
        <BaseSelect
          v-model="sashMaterial"
          label="Sash Glazing"
          :items="sashGlassOptions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <BaseSelect v-model="chain" label="Chain" />
      </v-col>
      <v-col cols="4">
        <BaseSelect v-model="letterPlate" label="Letter Plate" />
      </v-col>
      <v-col cols="4">
        <BaseSelect v-model="doorKnocker" label="Door Knocker" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BaseSelect
          v-model="door.lockOption"
          label="Lock Options"
          :items="lockOptions"
          @input="lockOptionChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <BaseSelect
          v-model="door.pullHandle"
          label="Pull Handle"
          :items="pullHandles"
          @input="saveDoor"
          :disabled="!hasPullHandle"
        />
      </v-col>
      <v-col cols="6">
        <BaseSelect
          v-model="door.pullHandlePlacement"
          label="Pull Handle Placement"
          :items="materialPlacements"
          @input="saveDoor"
          :disabled="!hasPullHandle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <BaseSelect
          v-model="door.signage"
          label="Signage"
          :items="signageOptions"
          @input="signageChanged"
        />
      </v-col>
      <v-col cols="6">
        <BaseSelect
          v-model="door.signagePlacement"
          label="Signage Placement"
          :items="materialPlacements"
          :disabled="signagePlacementDisabled"
          @input="saveDoor"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <BaseSelect
          v-model="door.kickPlate"
          :items="kickPlates"
          label="Kick Plate"
          @input="kickPlateChanged"
        />
      </v-col>
      <v-col cols="6">
        <BaseSelect
          v-model="door.kickPlatePlacement"
          label="Kick Plate Placement"
          :items="materialPlacements"
          :disabled="kickPlatePlacementDisabled"
          @input="saveDoor"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div><strong>Spyholes</strong></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <BaseSelect v-model="spyholeAt1200" label="@ 1200" />
      </v-col>
      <v-col cols="4">
        <BaseSelect v-model="spyholeAt1500" label="@ 1500" />
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :value="numerals"
          label="Door Numerals"
          @change="numeralsChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <BaseSelect
          v-model="numeralsPosition"
          label="Numerals Position"
          :items="numeralPositions"
        />
      </v-col>
      <v-col cols="5">
        <BaseSelect
          v-model="suiteColour"
          label="External Hardware Suite Colour"
          :items="suiteColours"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-for="error in numeralError" :key="error.key" class="red--text">
          {{ error.error }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <CloserSelect :door="door" :closers="closers" v-on="$listeners" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mixin, syncProp } from "@gradys/vue-base/components/forms/mixins";
// Note: Sash Glass options just goes straight to vuex (only prop - should refactor)
import { get, call } from "vuex-pathify";
import CloserSelect from "@gradys/vue-base/components/forms/closer-select";
import ExternalColourSelect from "@gradys/vue-base/components/forms/external-colour-select";
import {
  numeralsPositions,
  materialPlacements,
  pullHandles,
} from "@gradys/vue-base/store/lookups/lookup-values";

export default {
  components: {
    CloserSelect,
    ExternalColourSelect,
  },
  mixins: [mixin],
  props: {
    door: {
      type: Object,
      default: () => {
        return {};
      },
    },
    doorDesignErrors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    materialPlacements,
    pullHandles,
  }),
  computed: {
    numerals() {
      return this.door.numerals;
    },
    numeralPositions() {
      return numeralsPositions;
    },
    doorStyles: get("lookups/doorStyles"),
    sashGlassOptions: get("lookups/sashGlassOptions"),
    leafColours: get("lookups/leafColours"),
    internalColours: get("lookups/internalColours"),
    openOptions: get("lookups/openOptions"),
    closers: get("lookups/closers"),
    suiteColours: get("lookups/suiteColours"),
    lockOptions: get("lookups/lockOptions"),
    signageOptions: get("lookups/signageOptions"),
    kickPlates: get("lookups/kickPlates"),
    hasLeafGlazing: get("doors/hasLeafGlazing"),
    hasPullHandle: get("doors/hasAnyPullHandle"),
    ...syncProp("doorStyle"),
    ...syncProp("externalColour"),
    ...syncProp("internalColour"),
    ...syncProp("sashMaterial"),
    ...syncProp("chain"),
    ...syncProp("letterPlate"),
    ...syncProp("doorKnocker"),
    ...syncProp("spyholeAt1200"),
    ...syncProp("spyholeAt1500"),
    ...syncProp("suiteColour"),
    ...syncProp("closer"),
    ...syncProp("numeralsPosition"),
    numeralError() {
      return this.doorDesignErrors.filter((e) => e.key === "numeral");
    },
    signagePlacementDisabled() {
      return !this.door.signage || this.door.signage === "None";
    },
    kickPlatePlacementDisabled() {
      return !this.door.kickPlate || this.door.kickPlate === "None";
    }
  },
  methods: {
    saveDoor: call("doors/save"),
    numeralsChanged(e) {
      const value = e.toUpperCase();
      this.$emit("on:door-prop-updated", { prop: "numerals", value });
    },
    externalColourChanged(value) {
      this.$emit("on:door-prop-updated", { prop: "externalColour", value });
    },
    doorStyleChanged() {
      if (!this.hasLeafGlazing) {
        this.sashMaterial = "";
      }
    },
    lockOptionChanged() {
      if (!this.hasPullHandle) {
        if (this.door.pullHandle) this.door.pullHandle = "";

        if (this.door.pullHandlePlacement) this.door.pullHandlePlacement = "";
      }

      return this.saveDoor();
    },
    signageChanged() {
      if (this.signagePlacementDisabled) { // means there's no placement
        this.door.signagePlacement = "";
      }

      return this.saveDoor()
    },
    kickPlateChanged() {
      if (this.kickPlatePlacementDisabled) { // no kick plate
        this.door.kickPlatePlacement = "";
      }

      return this.saveDoor();
    }
  },
};
</script>
