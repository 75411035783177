<template>
  <div>
    <div v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="noImage">
      No Image
    </div>
    <img
      v-else
      style="border: solid 1px #ccc"
      :src="imageSrc"
      class="current--image"
    />
  </div>
</template>

<script>
import { storage } from "@/plugins/firebase";

export default {
  props: {
    imageUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imageSrc: "",
      loading: true
    };
  },
  computed: {
    noImage() {
      return !this.imageUrl;
    }
  },
  watch: {
    imageUrl: {
      immediate: true,
      handler: "getImage"
    }
  },
  methods: {
    getImage() {
      if (this.noImage) {
        this.imageSrc = "";
        this.loading = false;
        return;
      }

      this.loading = true;
      const ref = storage.ref(this.imageUrl);

      ref
        .getDownloadURL()
        .then(url => {
          this.imageSrc = `${url}.jpg`;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.current--image {
  max-width: 100%;
}
</style>
